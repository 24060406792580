import request from "../utils/request";
//上传base64
export const uploadBase64 = (query) => {
  return request({
    url: "/teacher/common/uploadBase64",
    method: "POST",
    data: query,
  });
};
// 获取基础信息
export const getBaseInfo = (query) => {
  return request({
    url: "/teacher/technology_case/getBaseInfo",
    method: "POST",
    data: query,
  });
};
// 添加技术案例
export const addTechnologyCase = (query) => {
  return request({
    url: "/teacher/technology_case/addTechnologyCase",
    method: "POST",
    data: query,
  });
};
// 技术案例列表
export const getTechnologyCaseList = (query) => {
  return request({
    url: "/teacher/technology_case/technologyCaseList",
    method: "POST",
    data: query,
  });
};
// 技术案例详情
export const getTechnologyCaseInfo = (query) => {
  return request({
    url: "/teacher/technology_case/technologyCaseInfo",
    method: "POST",
    data: query,
  });
};
// 技术案例平仓
export const technologyCasePingCang = (query) => {
  return request({
    url: "/teacher/technology_case/technologyCasePingCang",
    method: "POST",
    data: query,
  });
};
// 开播提醒
export const openLive = (query) => {
  return request({
    url: "/teacher/chat/openLive",
    method: "POST",
    data: query,
  });
};
// 添加品种追踪
export const addTrack = (query) => {
  return request({
    url: "/teacher/technology_case/addTrack",
    method: "POST",
    data: query,
  });
};
// 品种追踪列表
export const getTrackList = (query) => {
  return request({
    url: "/teacher/technology_case/trackList",
    method: "POST",
    data: query,
  });
};
