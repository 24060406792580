import { createRouter, createWebHashHistory } from "vue-router";
import Home from "@/common/Home";
import NotFound from "@/views/404";
import Login from "@/views/Login";
import BusinessDynamic from "@/views/BusinessDynamic";
import Chat from "@/views/Chat";
import MarketEditor from "@/views/MarketEditor";
import EnterpriseWechat from "@/views/EnterpriseWechat";
import ArticleTemplate from "@/views/ArticleTemplate";
import Dummy from "@/views/Dummy";
import CircleManage from "@/views/CircleManage";
import Statistics from "@/views/Statistics";
import PushMessage from "@/views/PushMessage";
import RiskPush from "@/views/RiskPush";
import Bbas from "@/views/Bbas";
import TechnicalModel from "@/views/TechnicalModel";
import TechnicalModelModify from "@/views/TechnicalModelModify";
import HeadlineReport from "@/views/HeadlineReport";
import HeadlineReportCategory from "@/views/HeadlineReportCategory";
import ViewingData from "@/views/ViewingData";
import CompanyCircle from "@/views/CompanyCircle";
import StrategyList from "@/views/StrategyList";
import QiyouCommunity from "@/views/QiyouCommunity";
import BandOpportunity from "@/views/BandOpportunity";
import BlackResearchReport from "@/views/BlackResearchReport";
import Qihuoshangpin from "@/views/Qihuoshangpin";
import Nongchanpin from "@/views/Nongchanpin";
import Yousejinshu from "@/views/Yousejinshu";
import VarietyTracking from "@/views/VarietyTracking";
const routes = [
  {
    path: "/",
    redirect:
      localStorage.getItem("is_company") == 1 ? "/companyCircle" : "/chat",
  },
  {
    path: "/",
    component: Home,
    children: [
      {
        path: "/businessDynamic",
        component: BusinessDynamic,
        meta: { title: "交易动态" },
      },
      {
        path: "/articleTemplate",
        component: ArticleTemplate,
        meta: { title: "文章模板" },
      },
      {
        path: "/chat",
        component: Chat,
        meta: { title: "聊天" },
      },
      {
        path: "/marketEditor",
        component: MarketEditor,
        meta: { title: "行情编辑" },
      },
      // {
      //   path: "/enterpriseWechat",
      //   component: EnterpriseWechat,
      //   meta: { title: "企业微信" },
      // },
      {
        path: "/dummy",
        component: Dummy,
        meta: { title: "假人管理" },
      },
      {
        path: "/circleManage",
        component: CircleManage,
        meta: { title: "圈子管理" },
      },
      {
        path: "/statistics",
        component: Statistics,
        meta: { title: "统计数据" },
      },
      {
        path: "/pushMessage",
        component: PushMessage,
        meta: { title: "推送消息" },
      },
      {
        path: "/bbas",
        component: Bbas,
        meta: { title: "多空情报" },
      },
      // {
      //   path: "/riskPush",
      //   component: RiskPush,
      //   meta: { title: "风险推送" },
      // },
      {
        path: "/technicalModel",
        component: TechnicalModel,
        meta: { title: "技术案例模型" },
      },
      {
        path: "/technicalModelModify",
        component: TechnicalModelModify,
        meta: { title: "技术案例模型" },
      },
      {
        path: "/headlineReport",
        component: HeadlineReport,
        meta: { title: "头条报告" },
      },
      {
        path: "/headlineReportCategory",
        component: HeadlineReportCategory,
        meta: { title: "头条报告" },
      },
      {
        path: "/viewingData",
        component: ViewingData,
        meta: { title: "看数据" },
      },
      {
        path: "/companyCircle",
        component: CompanyCircle,
        meta: { title: "圈子" },
      },
      {
        path: "/strategyList",
        component: StrategyList,
        meta: { title: "策略列表" },
      },
      {
        path: "/varietyTracking",
        component: VarietyTracking,
        meta: { title: "品种追踪列表" },
      },
      {
        path: "/qiyouCommunity",
        component: QiyouCommunity,
        meta: { title: "期友社区" },
      },
      {
        path: "/bandOpportunity",
        component: BandOpportunity,
        meta: { title: "波段机会" },
      },
      {
        path: "/blackResearchReport",
        component: BlackResearchReport,
        meta: { title: "黑色系研报" },
      },
      {
        path: "/qihuoshangpin",
        component: Qihuoshangpin,
        meta: { title: "期货商品" },
      },
      {
        path: "/nongchanpin",
        component: Nongchanpin,
        meta: { title: "农产品研报" },
      },
      {
        path: "/yousejinshu",
        component: Yousejinshu,
        meta: { title: "有色金属研报" },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { title: "V策财经研究员管理系统" },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: NotFound,
    meta: { title: "V策财经研究员管理系统" },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
