<template>
  <div class="ratio_body">
    <div>
      <!-- 0黑链板块13 -->
      <div class="ratio_cell">
        <div class="ratio_cell_title">
          {{ list[0].name }}
        </div>
        <div class="ratio_cell_block">
          <div
            :title="list[0].son[0].name"
            class="ratio_cell_cell"
            :class="{
              red: list[0].son[0].zdf > 0,
              green: list[0].son[0].zdf < 0,
              red1: list[0].son[0].zdf > 1,
              green1: list[0].son[0].zdf < -1,
            }"
            style="width: calc(40% - 2px); height: 200px; margin-right: 4px"
            @click="jumpTo(list[0].son[0].id)"
          >
            <span>{{ list[0].son[0].name }}</span>
            <span>{{ list[0].son[0].lastPrice || "--" }}</span>
            <span>{{ list[0].son[0].zdf || 0 }}</span>
          </div>
          <div
            :title="list[0].son[1].name"
            class="ratio_cell_cell"
            :class="{
              red: list[0].son[1].zdf > 0,
              green: list[0].son[1].zdf < 0,
              red1: list[0].son[1].zdf > 1,
              green1: list[0].son[1].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 96px"
            @click="jumpTo(list[0].son[1].id)"
          >
            <span>{{ list[0].son[1].name }}</span>
            <span>{{ list[0].son[1].lastPrice || "--" }}</span>
            <span>{{ list[0].son[1].zdf || 0 }}</span>
          </div>
          <div
            :title="list[0].son[2].name"
            class="ratio_cell_cell"
            :class="{
              red: list[0].son[2].zdf > 0,
              green: list[0].son[2].zdf < 0,
              red1: list[0].son[2].zdf > 1,
              green1: list[0].son[2].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 100px"
            @click="jumpTo(list[0].son[2].id)"
          >
            <span>{{ list[0].son[2].name }}</span>
            <span>{{ list[0].son[2].lastPrice || "--" }}</span>
            <span>{{ list[0].son[2].zdf || 0 }}</span>
          </div>
          <div
            :title="list[0].son[3].name"
            class="ratio_cell_cell"
            :class="{
              red: list[0].son[3].zdf > 0,
              green: list[0].son[3].zdf < 0,
              red1: list[0].son[3].zdf > 1,
              green1: list[0].son[3].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 100px; margin-right: 4px"
            @click="jumpTo(list[0].son[3].id)"
          >
            <span>{{ list[0].son[3].name }}</span>
            <span>{{ list[0].son[3].lastPrice || "--" }}</span>
            <span>{{ list[0].son[3].zdf || 0 }}</span>
          </div>
          <div
            :title="list[0].son[4].name"
            class="ratio_cell_cell"
            :class="{
              red: list[0].son[4].zdf > 0,
              green: list[0].son[4].zdf < 0,
              red1: list[0].son[4].zdf > 1,
              green1: list[0].son[4].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 100px"
            @click="jumpTo(list[0].son[4].id)"
          >
            <span>{{ list[0].son[4].name }}</span>
            <span>{{ list[0].son[4].lastPrice || "--" }}</span>
            <span>{{ list[0].son[4].zdf || 0 }}</span>
          </div>
          <div
            :title="list[0].son[5].name"
            class="ratio_cell_cell"
            :class="{
              red: list[0].son[5].zdf > 0,
              green: list[0].son[5].zdf < 0,
              red1: list[0].son[5].zdf > 1,
              green1: list[0].son[5].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 96px; margin-right: 4px"
            @click="jumpTo(list[0].son[5].id)"
          >
            <span>{{ list[0].son[5].name }}</span>
            <span>{{ list[0].son[5].lastPrice || "--" }}</span>
            <span>{{ list[0].son[5].zdf || 0 }}</span>
          </div>
          <div
            :title="list[0].son[6].name"
            class="ratio_cell_cell"
            :class="{
              red: list[0].son[6].zdf > 0,
              green: list[0].son[6].zdf < 0,
              red1: list[0].son[6].zdf > 1,
              green1: list[0].son[6].zdf < -1,
            }"
            style="width: calc(40% - 2px); height: 200px; float: right"
            @click="jumpTo(list[0].son[6].id)"
          >
            <span>{{ list[0].son[6].name }}</span>
            <span>{{ list[0].son[6].lastPrice || "--" }}</span>
            <span>{{ list[0].son[6].zdf || 0 }}</span>
          </div>
          <div
            :title="list[0].son[7].name"
            class="ratio_cell_cell"
            :class="{
              red: list[0].son[7].zdf > 0,
              green: list[0].son[7].zdf < 0,
              red1: list[0].son[7].zdf > 1,
              green1: list[0].son[7].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 100px"
            @click="jumpTo(list[0].son[7].id)"
          >
            <span>{{ list[0].son[7].name }}</span>
            <span>{{ list[0].son[7].lastPrice || "--" }}</span>
            <span>{{ list[0].son[7].zdf || 0 }}</span>
          </div>
          <div
            :title="list[0].son[8].name"
            class="ratio_cell_cell"
            :class="{
              red: list[0].son[8].zdf > 0,
              green: list[0].son[8].zdf < 0,
              red1: list[0].son[8].zdf > 1,
              green1: list[0].son[8].zdf < -1,
            }"
            style="width: calc(40% - 2px); height: 200px; margin-right: 4px"
            @click="jumpTo(list[0].son[8].id)"
          >
            <span>{{ list[0].son[8].name }}</span>
            <span>{{ list[0].son[8].lastPrice || "--" }}</span>
            <span>{{ list[0].son[8].zdf || 0 }}</span>
          </div>
          <div
            :title="list[0].son[9].name"
            class="ratio_cell_cell"
            :class="{
              red: list[0].son[9].zdf > 0,
              green: list[0].son[9].zdf < 0,
              red1: list[0].son[9].zdf > 1,
              green1: list[0].son[9].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 100px"
            @click="jumpTo(list[0].son[9].id)"
          >
            <span>{{ list[0].son[9].name }}</span>
            <span>{{ list[0].son[9].lastPrice || "--" }}</span>
            <span>{{ list[0].son[9].zdf || 0 }}</span>
          </div>
          <div
            :title="list[0].son[10].name"
            class="ratio_cell_cell"
            :class="{
              red: list[0].son[10].zdf > 0,
              green: list[0].son[10].zdf < 0,
              red1: list[0].son[10].zdf > 1,
              green1: list[0].son[10].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 96px"
            @click="jumpTo(list[0].son[10].id)"
          >
            <span>{{ list[0].son[10].name }}</span>
            <span>{{ list[0].son[10].lastPrice || "--" }}</span>
            <span>{{ list[0].son[10].zdf || 0 }}</span>
          </div>
          <div
            :title="list[0].son[11].name"
            class="ratio_cell_cell"
            :class="{
              red: list[0].son[11].zdf > 0,
              green: list[0].son[11].zdf < 0,
              red1: list[0].son[11].zdf > 1,
              green1: list[0].son[11].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 100px; margin-right: 4px"
            @click="jumpTo(list[0].son[11].id)"
          >
            <span>{{ list[0].son[11].name }}</span>
            <span>{{ list[0].son[11].lastPrice || "--" }}</span>
            <span>{{ list[0].son[11].zdf || 0 }}</span>
          </div>
          <div
            :title="list[0].son[12].name"
            class="ratio_cell_cell"
            :class="{
              red: list[0].son[12].zdf > 0,
              green: list[0].son[12].zdf < 0,
              red1: list[0].son[12].zdf > 1,
              green1: list[0].son[12].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 100px"
            @click="jumpTo(list[0].son[12].id)"
          >
            <span>{{ list[0].son[12].name }}</span>
            <span>{{ list[0].son[12].lastPrice || "--" }}</span>
            <span>{{ list[0].son[12].zdf || 0 }}</span>
          </div>
          <div class="clear" />
        </div>
      </div>
      <!-- 1油脂板块3 -->
      <div class="ratio_cell">
        <div class="ratio_cell_title">
          {{ list[1].name }}
        </div>
        <div class="ratio_cell_block">
          <div
            :title="list[1].son[0].name"
            class="ratio_cell_cell"
            :class="{
              red: list[1].son[0].zdf > 0,
              green: list[1].son[0].zdf < 0,
              red1: list[1].son[0].zdf > 1,
              green1: list[1].son[0].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 96px; margin-right: 4px"
            @click="jumpTo(list[1].son[0].id)"
          >
            <span>{{ list[1].son[0].name }}</span>
            <span>{{ list[1].son[0].lastPrice || "--" }}</span>
            <span>{{ list[1].son[0].zdf || 0 }}</span>
          </div>
          <div
            :title="list[1].son[1].name"
            class="ratio_cell_cell"
            :class="{
              red: list[1].son[1].zdf > 0,
              green: list[1].son[1].zdf < 0,
              red1: list[1].son[1].zdf > 1,
              green1: list[1].son[1].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 200px; float: right"
            @click="jumpTo(list[1].son[1].id)"
          >
            <span>{{ list[1].son[1].name }}</span>
            <span>{{ list[1].son[1].lastPrice || "--" }}</span>
            <span>{{ list[1].son[1].zdf || 0 }}</span>
          </div>
          <div
            :title="list[1].son[2].name"
            class="ratio_cell_cell"
            :class="{
              red: list[1].son[2].zdf > 0,
              green: list[1].son[2].zdf < 0,
              red1: list[1].son[2].zdf > 1,
              green1: list[1].son[2].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 100px; margin-right: 4px"
            @click="jumpTo(list[1].son[2].id)"
          >
            <span>{{ list[1].son[2].name }}</span>
            <span>{{ list[1].son[2].lastPrice || "--" }}</span>
            <span>{{ list[1].son[2].zdf || 0 }}</span>
          </div>
          <div class="clear" />
        </div>
      </div>
      <!-- 3塑化链3 -->
      <div class="ratio_cell">
        <div class="ratio_cell_title">
          {{ list[3].name }}
        </div>
        <div class="ratio_cell_block">
          <div
            :title="list[3].son[0].name"
            class="ratio_cell_cell"
            :class="{
              red: list[3].son[0].zdf > 0,
              green: list[3].son[0].zdf < 0,
              red1: list[3].son[0].zdf > 1,
              green1: list[3].son[0].zdf < -1,
            }"
            style="width: calc(40% - 2px); height: 173px; margin-right: 4px"
            @click="jumpTo(list[3].son[0].id)"
          >
            <span>{{ list[3].son[0].name }}</span>
            <span>{{ list[3].son[0].lastPrice || "--" }}</span>
            <span>{{ list[3].son[0].zdf || 0 }}</span>
          </div>
          <div
            :title="list[3].son[1].name"
            class="ratio_cell_cell"
            :class="{
              red: list[3].son[1].zdf > 0,
              green: list[3].son[1].zdf < 0,
              red1: list[3].son[1].zdf > 1,
              green1: list[3].son[1].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 96px"
            @click="jumpTo(list[3].son[1].id)"
          >
            <span>{{ list[3].son[1].name }}</span>
            <span>{{ list[3].son[1].lastPrice || "--" }}</span>
            <span>{{ list[3].son[1].zdf || 0 }}</span>
          </div>
          <div
            :title="list[3].son[2].name"
            class="ratio_cell_cell"
            :class="{
              red: list[3].son[2].zdf > 0,
              green: list[3].son[2].zdf < 0,
              red1: list[3].son[2].zdf > 1,
              green1: list[3].son[2].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 73px"
            @click="jumpTo(list[3].son[2].id)"
          >
            <span>{{ list[3].son[2].name }}</span>
            <span>{{ list[3].son[2].lastPrice || "--" }}</span>
            <span>{{ list[3].son[2].zdf || 0 }}</span>
          </div>
          <div class="clear" />
        </div>
      </div>
      <!-- 5软商板块3 -->
      <div class="ratio_cell">
        <div class="ratio_cell_title">
          {{ list[5].name }}
        </div>
        <div class="ratio_cell_block">
          <div
            :title="list[5].son[0].name"
            class="ratio_cell_cell"
            :class="{
              red: list[5].son[0].zdf > 0,
              green: list[5].son[0].zdf < 0,
              red1: list[5].son[0].zdf > 1,
              green1: list[5].son[0].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 80px; margin-right: 4px"
            @click="jumpTo(list[5].son[0].id)"
          >
            <span>{{ list[5].son[0].name }}</span>
            <span>{{ list[5].son[0].lastPrice || "--" }}</span>
            <span>{{ list[5].son[0].zdf || 0 }}</span>
          </div>
          <div
            :title="list[5].son[1].name"
            class="ratio_cell_cell"
            :class="{
              red: list[5].son[1].zdf > 0,
              green: list[5].son[1].zdf < 0,
              red1: list[5].son[1].zdf > 1,
              green1: list[5].son[1].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 80px"
            @click="jumpTo(list[5].son[1].id)"
          >
            <span>{{ list[5].son[1].name }}</span>
            <span>{{ list[5].son[1].lastPrice || "--" }}</span>
            <span>{{ list[5].son[1].zdf || 0 }}</span>
          </div>
          <div
            :title="list[5].son[2].name"
            class="ratio_cell_cell"
            :class="{
              red: list[5].son[2].zdf > 0,
              green: list[5].son[2].zdf < 0,
              red1: list[5].son[2].zdf > 1,
              green1: list[5].son[2].zdf < -1,
            }"
            style="width: calc(100%); height: 67px"
            @click="jumpTo(list[5].son[2].id)"
          >
            <span>{{ list[5].son[2].name }}</span>
            <span>{{ list[5].son[2].lastPrice || "--" }}</span>
            <span>{{ list[5].son[2].zdf || 0 }}</span>
          </div>
          <div class="clear" />
        </div>
      </div>
      <!-- 10指数期货1 -->
      <div class="ratio_cell">
        <div class="ratio_cell_title">
          {{ list[10].name }}
        </div>
        <div class="ratio_cell_block">
          <div
            :title="list[10].son[0].name"
            class="ratio_cell_cell"
            :class="{
              red: list[10].son[0].zdf > 0,
              green: list[10].son[0].zdf < 0,
              red1: list[10].son[0].zdf > 1,
              green1: list[10].son[0].zdf < -1,
            }"
            style="width: calc(100%); height: 68px"
            @click="jumpTo(list[10].son[0].id)"
          >
            <span>{{ list[10].son[0].name }}</span>
            <span>{{ list[10].son[0].lastPrice || "--" }}</span>
            <span>{{ list[10].son[0].zdf || 0 }}</span>
          </div>
          <div class="clear" />
        </div>
      </div>
    </div>
    <div>
      <!-- 2化工品8 -->
      <div class="ratio_cell">
        <div class="ratio_cell_title">
          {{ list[2].name }}
        </div>
        <div class="ratio_cell_block">
          <div
            :title="list[2].son[0].name"
            class="ratio_cell_cell"
            :class="{
              red: list[2].son[0].zdf > 0,
              green: list[2].son[0].zdf < 0,
              red1: list[2].son[0].zdf > 1,
              green1: list[2].son[0].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 180px; margin-right: 4px"
            @click="jumpTo(list[2].son[0].id)"
          >
            <span>{{ list[2].son[0].name }}</span>
            <span>{{ list[2].son[0].lastPrice || "--" }}</span>
            <span>{{ list[2].son[0].zdf || 0 }}</span>
          </div>
          <div
            :title="list[2].son[1].name"
            class="ratio_cell_cell"
            :class="{
              red: list[2].son[1].zdf > 0,
              green: list[2].son[1].zdf < 0,
              red1: list[2].son[1].zdf > 1,
              green1: list[2].son[1].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 96px"
            @click="jumpTo(list[2].son[1].id)"
          >
            <span>{{ list[2].son[1].name }}</span>
            <span>{{ list[2].son[1].lastPrice || "--" }}</span>
            <span>{{ list[2].son[1].zdf || 0 }}</span>
          </div>
          <div
            :title="list[2].son[2].name"
            class="ratio_cell_cell"
            :class="{
              red: list[2].son[2].zdf > 0,
              green: list[2].son[2].zdf < 0,
              red1: list[2].son[2].zdf > 1,
              green1: list[2].son[2].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 80px"
            @click="jumpTo(list[2].son[2].id)"
          >
            <span>{{ list[2].son[2].name }}</span>
            <span>{{ list[2].son[2].lastPrice || "--" }}</span>
            <span>{{ list[2].son[2].zdf || 0 }}</span>
          </div>
          <div
            :title="list[2].son[3].name"
            class="ratio_cell_cell"
            :class="{
              red: list[2].son[3].zdf > 0,
              green: list[2].son[3].zdf < 0,
              red1: list[2].son[3].zdf > 1,
              green1: list[2].son[3].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 96px; margin-right: 4px"
            @click="jumpTo(list[2].son[3].id)"
          >
            <span>{{ list[2].son[3].name }}</span>
            <span>{{ list[2].son[3].lastPrice || "--" }}</span>
            <span>{{ list[2].son[3].zdf || 0 }}</span>
          </div>
          <div
            :title="list[2].son[4].name"
            class="ratio_cell_cell"
            :class="{
              red: list[2].son[4].zdf > 0,
              green: list[2].son[4].zdf < 0,
              red1: list[2].son[4].zdf > 1,
              green1: list[2].son[4].zdf < -1,
            }"
            style="width: calc(40% - 2px); height: 200px; float: right"
            @click="jumpTo(list[2].son[4].id)"
          >
            <span>{{ list[2].son[4].name }}</span>
            <span>{{ list[2].son[4].lastPrice || "--" }}</span>
            <span>{{ list[2].son[4].zdf || 0 }}</span>
          </div>
          <div
            :title="list[2].son[5].name"
            class="ratio_cell_cell"
            :class="{
              red: list[2].son[5].zdf > 0,
              green: list[2].son[5].zdf < 0,
              red1: list[2].son[5].zdf > 1,
              green1: list[2].son[5].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 100px; margin-right: 4px"
            @click="jumpTo(list[2].son[5].id)"
          >
            <span>{{ list[2].son[5].name }}</span>
            <span>{{ list[2].son[5].lastPrice || "--" }}</span>
            <span>{{ list[2].son[5].zdf || 0 }}</span>
          </div>
          <div
            :title="list[2].son[6].name"
            class="ratio_cell_cell"
            :class="{
              red: list[2].son[6].zdf > 0,
              green: list[2].son[6].zdf < 0,
              red1: list[2].son[6].zdf > 1,
              green1: list[2].son[6].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 100px; margin-right: 4px"
            @click="jumpTo(list[2].son[6].id)"
          >
            <span>{{ list[2].son[6].name }}</span>
            <span>{{ list[2].son[6].lastPrice || "--" }}</span>
            <span>{{ list[2].son[6].zdf || 0 }}</span>
          </div>
          <div
            :title="list[2].son[7].name"
            class="ratio_cell_cell"
            :class="{
              red: list[2].son[7].zdf > 0,
              green: list[2].son[7].zdf < 0,
              red1: list[2].son[7].zdf > 1,
              green1: list[2].son[7].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 100px"
            @click="jumpTo(list[2].son[7].id)"
          >
            <span>{{ list[2].son[7].name }}</span>
            <span>{{ list[2].son[7].lastPrice || "--" }}</span>
            <span>{{ list[2].son[7].zdf || 0 }}</span>
          </div>
          <!-- <div :title="list[2].son[8].name" class="ratio_cell_cell" :class="{red: list[2].son[8].zdf > 0, green: list[2].son[8].zdf < 0, red1: list[2].son[8].zdf > 1, green1: list[2].son[8].zdf < -1}" style="width: calc(50% - 2px);height: 96px;" @click="jumpTo(list[2].son[8].id)">
            <span>{{ list[2].son[8].name }}</span>
            <span>{{ list[2].son[8].lastPrice || '--' }}</span>
            <span>{{ list[2].son[8].zdf || 0 }}</span>
          </div>
          <div :title="list[2].son[9].name" class="ratio_cell_cell" :class="{red: list[2].son[9].zdf > 0, green: list[2].son[9].zdf < 0, red1: list[2].son[9].zdf > 1, green1: list[2].son[9].zdf < -1}" style="width: calc(100%);height: 60px;" @click="jumpTo(list[2].son[9].id)">
            <span>{{ list[2].son[9].name }}</span>
            <span>{{ list[2].son[9].lastPrice || '--' }}</span>
            <span>{{ list[2].son[9].zdf || 0 }}</span>
          </div> -->
          <div class="clear" />
        </div>
      </div>
      <!-- 6农产品13 -->
      <div class="ratio_cell">
        <div class="ratio_cell_title">
          {{ list[6].name }}
        </div>
        <div class="ratio_cell_block">
          <div
            :title="list[6].son[0].name"
            class="ratio_cell_cell"
            :class="{
              red: list[6].son[0].zdf > 0,
              green: list[6].son[0].zdf < 0,
              red1: list[6].son[0].zdf > 1,
              green1: list[6].son[0].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 100px; margin-right: 4px"
            @click="jumpTo(list[6].son[0].id)"
          >
            <span>{{ list[6].son[0].name }}</span>
            <span>{{ list[6].son[0].lastPrice || "--" }}</span>
            <span>{{ list[6].son[0].zdf || 0 }}</span>
          </div>
          <div
            :title="list[6].son[1].name"
            class="ratio_cell_cell"
            :class="{
              red: list[6].son[1].zdf > 0,
              green: list[6].son[1].zdf < 0,
              red1: list[6].son[1].zdf > 1,
              green1: list[6].son[1].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 100px"
            @click="jumpTo(list[6].son[1].id)"
          >
            <span>{{ list[6].son[1].name }}</span>
            <span>{{ list[6].son[1].lastPrice || "--" }}</span>
            <span>{{ list[6].son[1].zdf || 0 }}</span>
          </div>
          <div
            :title="list[6].son[2].name"
            class="ratio_cell_cell"
            :class="{
              red: list[6].son[2].zdf > 0,
              green: list[6].son[2].zdf < 0,
              red1: list[6].son[2].zdf > 1,
              green1: list[6].son[2].zdf < -1,
            }"
            style="width: calc(40% - 2px); height: 200px; margin-right: 4px"
            @click="jumpTo(list[6].son[2].id)"
          >
            <span>{{ list[6].son[2].name }}</span>
            <span>{{ list[6].son[2].lastPrice || "--" }}</span>
            <span>{{ list[6].son[2].zdf || 0 }}</span>
          </div>
          <div
            :title="list[6].son[3].name"
            class="ratio_cell_cell"
            :class="{
              red: list[6].son[3].zdf > 0,
              green: list[6].son[3].zdf < 0,
              red1: list[6].son[3].zdf > 1,
              green1: list[6].son[3].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 96px"
            @click="jumpTo(list[6].son[3].id)"
          >
            <span>{{ list[6].son[3].name }}</span>
            <span>{{ list[6].son[3].lastPrice || "--" }}</span>
            <span>{{ list[6].son[3].zdf || 0 }}</span>
          </div>
          <div
            :title="list[6].son[4].name"
            class="ratio_cell_cell"
            :class="{
              red: list[6].son[4].zdf > 0,
              green: list[6].son[4].zdf < 0,
              red1: list[6].son[4].zdf > 1,
              green1: list[6].son[4].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 100px"
            @click="jumpTo(list[6].son[4].id)"
          >
            <span>{{ list[6].son[4].name }}</span>
            <span>{{ list[6].son[4].lastPrice || "--" }}</span>
            <span>{{ list[6].son[4].zdf || 0 }}</span>
          </div>
          <div
            :title="list[6].son[5].name"
            class="ratio_cell_cell"
            :class="{
              red: list[6].son[5].zdf > 0,
              green: list[6].son[5].zdf < 0,
              red1: list[6].son[5].zdf > 1,
              green1: list[6].son[5].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 100px; margin-right: 4px"
            @click="jumpTo(list[6].son[5].id)"
          >
            <span>{{ list[6].son[5].name }}</span>
            <span>{{ list[6].son[5].lastPrice || "--" }}</span>
            <span>{{ list[6].son[5].zdf || 0 }}</span>
          </div>
          <div
            :title="list[6].son[6].name"
            class="ratio_cell_cell"
            :class="{
              red: list[6].son[6].zdf > 0,
              green: list[6].son[6].zdf < 0,
              red1: list[6].son[6].zdf > 1,
              green1: list[6].son[6].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 100px"
            @click="jumpTo(list[6].son[6].id)"
          >
            <span>{{ list[6].son[6].name }}</span>
            <span>{{ list[6].son[6].lastPrice || "--" }}</span>
            <span>{{ list[6].son[6].zdf || 0 }}</span>
          </div>
          <div
            :title="list[6].son[7].name"
            class="ratio_cell_cell"
            :class="{
              red: list[6].son[7].zdf > 0,
              green: list[6].son[7].zdf < 0,
              red1: list[6].son[7].zdf > 1,
              green1: list[6].son[7].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 170px; margin-right: 4px"
            @click="jumpTo(list[6].son[7].id)"
          >
            <span>{{ list[6].son[7].name }}</span>
            <span>{{ list[6].son[7].lastPrice || "--" }}</span>
            <span>{{ list[6].son[7].zdf || 0 }}</span>
          </div>
          <div
            :title="list[6].son[8].name"
            class="ratio_cell_cell"
            :class="{
              red: list[6].son[8].zdf > 0,
              green: list[6].son[8].zdf < 0,
              red1: list[6].son[8].zdf > 1,
              green1: list[6].son[8].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 170px"
            @click="jumpTo(list[6].son[8].id)"
          >
            <span>{{ list[6].son[8].name }}</span>
            <span>{{ list[6].son[8].lastPrice || "--" }}</span>
            <span>{{ list[6].son[8].zdf || 0 }}</span>
          </div>
          <div
            :title="list[6].son[9].name"
            class="ratio_cell_cell"
            :class="{
              red: list[6].son[9].zdf > 0,
              green: list[6].son[9].zdf < 0,
              red1: list[6].son[9].zdf > 1,
              green1: list[6].son[9].zdf < -1,
            }"
            style="width: calc(40% - 2px); height: 100px; margin-right: 4px"
            @click="jumpTo(list[6].son[9].id)"
          >
            <span>{{ list[6].son[9].name }}</span>
            <span>{{ list[6].son[9].lastPrice || "--" }}</span>
            <span>{{ list[6].son[9].zdf || 0 }}</span>
          </div>
          <div
            :title="list[6].son[10].name"
            class="ratio_cell_cell"
            :class="{
              red: list[6].son[10].zdf > 0,
              green: list[6].son[10].zdf < 0,
              red1: list[6].son[10].zdf > 1,
              green1: list[6].son[10].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 100px"
            @click="jumpTo(list[6].son[10].id)"
          >
            <span>{{ list[6].son[10].name }}</span>
            <span>{{ list[6].son[10].lastPrice || "--" }}</span>
            <span>{{ list[6].son[10].zdf || 0 }}</span>
          </div>
          <div
            :title="list[6].son[11].name"
            class="ratio_cell_cell"
            :class="{
              red: list[6].son[11].zdf > 0,
              green: list[6].son[11].zdf < 0,
              red1: list[6].son[11].zdf > 1,
              green1: list[6].son[11].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 100px; margin-right: 4px"
            @click="jumpTo(list[6].son[11].id)"
          >
            <span>{{ list[6].son[11].name }}</span>
            <span>{{ list[6].son[11].lastPrice || "--" }}</span>
            <span>{{ list[6].son[11].zdf || 0 }}</span>
          </div>
          <div
            :title="list[6].son[12].name"
            class="ratio_cell_cell"
            :class="{
              red: list[6].son[12].zdf > 0,
              green: list[6].son[12].zdf < 0,
              red1: list[6].son[12].zdf > 1,
              green1: list[6].son[12].zdf < -1,
            }"
            style="width: calc(40% - 2px); height: 100px"
            @click="jumpTo(list[6].son[12].id)"
          >
            <span>{{ list[6].son[12].name }}</span>
            <span>{{ list[6].son[12].lastPrice || "--" }}</span>
            <span>{{ list[6].son[12].zdf || 0 }}</span>
          </div>
          <div class="clear" />
        </div>
      </div>
      <!-- 11金融期货4 -->
      <div class="ratio_cell">
        <div class="ratio_cell_title">
          {{ list[11].name }}
        </div>
        <div class="ratio_cell_block">
          <div
            :title="list[11].son[0].name"
            class="ratio_cell_cell"
            :class="{
              red: list[11].son[0].zdf > 0,
              green: list[11].son[0].zdf < 0,
              red1: list[11].son[0].zdf > 1,
              green1: list[11].son[0].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 100px; margin-right: 4px"
            @click="jumpTo(list[11].son[0].id)"
          >
            <span>{{ list[11].son[0].name }}</span>
            <span>{{ list[11].son[0].lastPrice || "--" }}</span>
            <span>{{ list[11].son[0].zdf || 0 }}</span>
          </div>
          <div
            :title="list[11].son[1].name"
            class="ratio_cell_cell"
            :class="{
              red: list[11].son[1].zdf > 0,
              green: list[11].son[1].zdf < 0,
              red1: list[11].son[1].zdf > 1,
              green1: list[11].son[1].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 100px"
            @click="jumpTo(list[11].son[1].id)"
          >
            <span>{{ list[11].son[1].name }}</span>
            <span>{{ list[11].son[1].lastPrice || "--" }}</span>
            <span>{{ list[11].son[1].zdf || 0 }}</span>
          </div>
          <div
            :title="list[11].son[2].name"
            class="ratio_cell_cell"
            :class="{
              red: list[11].son[2].zdf > 0,
              green: list[11].son[2].zdf < 0,
              red1: list[11].son[2].zdf > 1,
              green1: list[11].son[2].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 100px; margin-right: 4px"
            @click="jumpTo(list[11].son[2].id)"
          >
            <span>{{ list[11].son[2].name }}</span>
            <span>{{ list[11].son[2].lastPrice || "--" }}</span>
            <span>{{ list[11].son[2].zdf || 0 }}</span>
          </div>
          <div
            :title="list[11].son[3].name"
            class="ratio_cell_cell"
            :class="{
              red: list[11].son[3].zdf > 0,
              green: list[11].son[3].zdf < 0,
              red1: list[11].son[3].zdf > 1,
              green1: list[11].son[3].zdf < -1,
            }"
            style="width: calc(40% - 2px); height: 100px"
            @click="jumpTo(list[11].son[3].id)"
          >
            <span>{{ list[11].son[3].name }}</span>
            <span>{{ list[11].son[3].lastPrice || "--" }}</span>
            <span>{{ list[11].son[3].zdf || 0 }}</span>
          </div>
          <div class="clear" />
        </div>
      </div>
    </div>
    <div>
      <!-- 4有色板块11 -->
      <div class="ratio_cell">
        <div class="ratio_cell_title">
          {{ list[4].name }}
        </div>
        <div class="ratio_cell_block">
          <div
            :title="list[4].son[0].name"
            class="ratio_cell_cell"
            :class="{
              red: list[4].son[0].zdf > 0,
              green: list[4].son[0].zdf < 0,
              red1: list[4].son[0].zdf > 1,
              green1: list[4].son[0].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 130px; margin-right: 4px"
            @click="jumpTo(list[4].son[0].id)"
          >
            <span>{{ list[4].son[0].name }}</span>
            <span>{{ list[4].son[0].lastPrice || "--" }}</span>
            <span>{{ list[4].son[0].zdf || 0 }}</span>
          </div>
          <div
            :title="list[4].son[1].name"
            class="ratio_cell_cell"
            :class="{
              red: list[4].son[1].zdf > 0,
              green: list[4].son[1].zdf < 0,
              red1: list[4].son[1].zdf > 1,
              green1: list[4].son[1].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 130px"
            @click="jumpTo(list[4].son[1].id)"
          >
            <span>{{ list[4].son[1].name }}</span>
            <span>{{ list[4].son[1].lastPrice || "--" }}</span>
            <span>{{ list[4].son[1].zdf || 0 }}</span>
          </div>
          <div
            :title="list[4].son[2].name"
            class="ratio_cell_cell"
            :class="{
              red: list[4].son[2].zdf > 0,
              green: list[4].son[2].zdf < 0,
              red1: list[4].son[2].zdf > 1,
              green1: list[4].son[2].zdf < -1,
            }"
            style="width: calc(40% - 2px); height: 180px; margin-right: 4px"
            @click="jumpTo(list[4].son[2].id)"
          >
            <span>{{ list[4].son[2].name }}</span>
            <span>{{ list[4].son[2].lastPrice || "--" }}</span>
            <span>{{ list[4].son[2].zdf || 0 }}</span>
          </div>
          <div
            :title="list[4].son[3].name"
            class="ratio_cell_cell"
            :class="{
              red: list[4].son[3].zdf > 0,
              green: list[4].son[3].zdf < 0,
              red1: list[4].son[3].zdf > 1,
              green1: list[4].son[3].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 96px"
            @click="jumpTo(list[4].son[3].id)"
          >
            <span>{{ list[4].son[3].name }}</span>
            <span>{{ list[4].son[3].lastPrice || "--" }}</span>
            <span>{{ list[4].son[3].zdf || 0 }}</span>
          </div>
          <div
            :title="list[4].son[4].name"
            class="ratio_cell_cell"
            :class="{
              red: list[4].son[4].zdf > 0,
              green: list[4].son[4].zdf < 0,
              red1: list[4].son[4].zdf > 1,
              green1: list[4].son[4].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 80px"
            @click="jumpTo(list[4].son[4].id)"
          >
            <span>{{ list[4].son[4].name }}</span>
            <span>{{ list[4].son[4].lastPrice || "--" }}</span>
            <span>{{ list[4].son[4].zdf || 0 }}</span>
          </div>
          <div
            :title="list[4].son[5].name"
            class="ratio_cell_cell"
            :class="{
              red: list[4].son[5].zdf > 0,
              green: list[4].son[5].zdf < 0,
              red1: list[4].son[5].zdf > 1,
              green1: list[4].son[5].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 96px; margin-right: 4px"
            @click="jumpTo(list[4].son[5].id)"
          >
            <span>{{ list[4].son[5].name }}</span>
            <span>{{ list[4].son[5].lastPrice || "--" }}</span>
            <span>{{ list[4].son[5].zdf || 0 }}</span>
          </div>
          <div
            :title="list[4].son[6].name"
            class="ratio_cell_cell"
            :class="{
              red: list[4].son[6].zdf > 0,
              green: list[4].son[6].zdf < 0,
              red1: list[4].son[6].zdf > 1,
              green1: list[4].son[6].zdf < -1,
            }"
            style="width: calc(40% - 2px); height: 200px; float: right"
            @click="jumpTo(list[4].son[6].id)"
          >
            <span>{{ list[4].son[6].name }}</span>
            <span>{{ list[4].son[6].lastPrice || "--" }}</span>
            <span>{{ list[4].son[6].zdf || 0 }}</span>
          </div>
          <div
            :title="list[4].son[7].name"
            class="ratio_cell_cell"
            :class="{
              red: list[4].son[7].zdf > 0,
              green: list[4].son[7].zdf < 0,
              red1: list[4].son[7].zdf > 1,
              green1: list[4].son[7].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 100px; margin-right: 4px"
            @click="jumpTo(list[4].son[7].id)"
          >
            <span>{{ list[4].son[7].name }}</span>
            <span>{{ list[4].son[7].lastPrice || "--" }}</span>
            <span>{{ list[4].son[7].zdf || 0 }}</span>
          </div>
          <div
            :title="list[4].son[8].name"
            class="ratio_cell_cell"
            :class="{
              red: list[4].son[8].zdf > 0,
              green: list[4].son[8].zdf < 0,
              red1: list[4].son[8].zdf > 1,
              green1: list[4].son[8].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 200px; margin-right: 4px"
            @click="jumpTo(list[4].son[8].id)"
          >
            <span>{{ list[4].son[8].name }}</span>
            <span>{{ list[4].son[8].lastPrice || "--" }}</span>
            <span>{{ list[4].son[8].zdf || 0 }}</span>
          </div>
          <div
            :title="list[4].son[9].name"
            class="ratio_cell_cell"
            :class="{
              red: list[4].son[9].zdf > 0,
              green: list[4].son[9].zdf < 0,
              red1: list[4].son[9].zdf > 1,
              green1: list[4].son[9].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 96px"
            @click="jumpTo(list[4].son[9].id)"
          >
            <span>{{ list[4].son[9].name }}</span>
            <span>{{ list[4].son[9].lastPrice || "--" }}</span>
            <span>{{ list[4].son[9].zdf || 0 }}</span>
          </div>
          <div
            :title="list[4].son[10].name"
            class="ratio_cell_cell"
            :class="{
              red: list[4].son[10].zdf > 0,
              green: list[4].son[10].zdf < 0,
              red1: list[4].son[10].zdf > 1,
              green1: list[4].son[10].zdf < -1,
            }"
            style="width: calc(50% - 2px); height: 100px"
            @click="jumpTo(list[4].son[10].id)"
          >
            <span>{{ list[4].son[10].name }}</span>
            <span>{{ list[4].son[10].lastPrice || "--" }}</span>
            <span>{{ list[4].son[10].zdf || 0 }}</span>
          </div>
          <div class="clear" />
        </div>
      </div>
      <!-- 7石油板块5 -->
      <div class="ratio_cell">
        <div class="ratio_cell_title">
          {{ list[7].name }}
        </div>
        <div class="ratio_cell_block">
          <div
            :title="list[7].son[0].name"
            class="ratio_cell_cell"
            :class="{
              red: list[7].son[0].zdf > 0,
              green: list[7].son[0].zdf < 0,
              red1: list[7].son[0].zdf > 1,
              green1: list[7].son[0].zdf < -1,
            }"
            style="width: calc(65% - 2px); height: 134px; margin-right: 4px"
            @click="jumpTo(list[7].son[0].id)"
          >
            <span>{{ list[7].son[0].name }}</span>
            <span>{{ list[7].son[0].lastPrice || "--" }}</span>
            <span>{{ list[7].son[0].zdf || 0 }}</span>
          </div>
          <div
            :title="list[7].son[1].name"
            class="ratio_cell_cell"
            :class="{
              red: list[7].son[1].zdf > 0,
              green: list[7].son[1].zdf < 0,
              red1: list[7].son[1].zdf > 1,
              green1: list[7].son[1].zdf < -1,
            }"
            style="width: calc(35% - 2px); height: 134px"
            @click="jumpTo(list[7].son[1].id)"
          >
            <span>{{ list[7].son[1].name }}</span>
            <span>{{ list[7].son[1].lastPrice || "--" }}</span>
            <span>{{ list[7].son[1].zdf || 0 }}</span>
          </div>
          <div
            :title="list[7].son[2].name"
            class="ratio_cell_cell"
            :class="{
              red: list[7].son[2].zdf > 0,
              green: list[7].son[2].zdf < 0,
              red1: list[7].son[2].zdf > 1,
              green1: list[7].son[2].zdf < -1,
            }"
            style="width: calc(40% - 2px); height: 200px; margin-right: 4px"
            @click="jumpTo(list[7].son[2].id)"
          >
            <span>{{ list[7].son[2].name }}</span>
            <span>{{ list[7].son[2].lastPrice || "--" }}</span>
            <span>{{ list[7].son[2].zdf || 0 }}</span>
          </div>
          <div
            :title="list[7].son[3].name"
            class="ratio_cell_cell"
            :class="{
              red: list[7].son[3].zdf > 0,
              green: list[7].son[3].zdf < 0,
              red1: list[7].son[3].zdf > 1,
              green1: list[7].son[3].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 96px"
            @click="jumpTo(list[7].son[3].id)"
          >
            <span>{{ list[7].son[3].name }}</span>
            <span>{{ list[7].son[3].lastPrice || "--" }}</span>
            <span>{{ list[7].son[3].zdf || 0 }}</span>
          </div>
          <div
            :title="list[7].son[4].name"
            class="ratio_cell_cell"
            :class="{
              red: list[7].son[4].zdf > 0,
              green: list[7].son[4].zdf < 0,
              red1: list[7].son[4].zdf > 1,
              green1: list[7].son[4].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 100px"
            @click="jumpTo(list[7].son[4].id)"
          >
            <span>{{ list[7].son[4].name }}</span>
            <span>{{ list[7].son[4].lastPrice || "--" }}</span>
            <span>{{ list[7].son[4].zdf || 0 }}</span>
          </div>
          <div class="clear" />
        </div>
      </div>
      <!-- 8煤化工2 -->
      <div class="ratio_cell">
        <div class="ratio_cell_title">
          {{ list[8].name }}
        </div>
        <div class="ratio_cell_block">
          <div
            :title="list[8].son[0].name"
            class="ratio_cell_cell"
            :class="{
              red: list[8].son[0].zdf > 0,
              green: list[8].son[0].zdf < 0,
              red1: list[8].son[0].zdf > 1,
              green1: list[8].son[0].zdf < -1,
            }"
            style="width: calc(100%); height: 67px"
            @click="jumpTo(list[8].son[0].id)"
          >
            <span>{{ list[8].son[0].name }}</span>
            <span>{{ list[8].son[0].lastPrice || "--" }}</span>
            <span>{{ list[8].son[0].zdf || 0 }}</span>
          </div>
          <div
            :title="list[8].son[1].name"
            class="ratio_cell_cell"
            :class="{
              red: list[8].son[1].zdf > 0,
              green: list[8].son[1].zdf < 0,
              red1: list[8].son[1].zdf > 1,
              green1: list[8].son[1].zdf < -1,
            }"
            style="width: calc(100%); height: 67px"
            @click="jumpTo(list[8].son[1].id)"
          >
            <span>{{ list[8].son[1].name }}</span>
            <span>{{ list[8].son[1].lastPrice || "--" }}</span>
            <span>{{ list[8].son[1].zdf || 0 }}</span>
          </div>
          <div class="clear" />
        </div>
      </div>
      <!-- 9聚酯链4 -->
      <div class="ratio_cell">
        <div class="ratio_cell_title">
          {{ list[9].name }}
        </div>
        <div class="ratio_cell_block">
          <div
            :title="list[9].son[0].name"
            class="ratio_cell_cell"
            :class="{
              red: list[9].son[0].zdf > 0,
              green: list[9].son[0].zdf < 0,
              red1: list[9].son[0].zdf > 1,
              green1: list[9].son[0].zdf < -1,
            }"
            style="width: calc(45% - 2px); height: 100px; margin-right: 4px"
            @click="jumpTo(list[9].son[0].id)"
          >
            <span>{{ list[9].son[0].name }}</span>
            <span>{{ list[9].son[0].lastPrice || "--" }}</span>
            <span>{{ list[9].son[0].zdf || 0 }}</span>
          </div>
          <div
            :title="list[9].son[1].name"
            class="ratio_cell_cell"
            :class="{
              red: list[9].son[1].zdf > 0,
              green: list[9].son[1].zdf < 0,
              red1: list[9].son[1].zdf > 1,
              green1: list[9].son[1].zdf < -1,
            }"
            style="width: calc(55% - 2px); height: 100px"
            @click="jumpTo(list[9].son[1].id)"
          >
            <span>{{ list[9].son[1].name }}</span>
            <span>{{ list[9].son[1].lastPrice || "--" }}</span>
            <span>{{ list[9].son[1].zdf || 0 }}</span>
          </div>
          <div
            :title="list[9].son[2].name"
            class="ratio_cell_cell"
            :class="{
              red: list[9].son[2].zdf > 0,
              green: list[9].son[2].zdf < 0,
              red1: list[9].son[2].zdf > 1,
              green1: list[9].son[2].zdf < -1,
            }"
            style="width: calc(60% - 2px); height: 143px; margin-right: 4px"
            @click="jumpTo(list[9].son[2].id)"
          >
            <span>{{ list[9].son[2].name }}</span>
            <span>{{ list[9].son[2].lastPrice || "--" }}</span>
            <span>{{ list[9].son[2].zdf || 0 }}</span>
          </div>
          <div
            :title="list[9].son[3].name"
            class="ratio_cell_cell"
            :class="{
              red: list[9].son[3].zdf > 0,
              green: list[9].son[3].zdf < 0,
              red1: list[9].son[3].zdf > 1,
              green1: list[9].son[3].zdf < -1,
            }"
            style="width: calc(40% - 2px); height: 143px"
            @click="jumpTo(list[9].son[3].id)"
          >
            <span>{{ list[9].son[3].name }}</span>
            <span>{{ list[9].son[3].lastPrice || "--" }}</span>
            <span>{{ list[9].son[3].zdf || 0 }}</span>
          </div>
          <div class="clear" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Service from "@/api/qihuoshangpin";
export default {
  data() {
    return {
      list: [
        {
          id: 9,
          name: "黑链板块",
          son: [
            {
              id: 69,
              name: "原木",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 39,
              name: "螺纹钢",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 36,
              name: "锰硅",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 43,
              name: "不锈钢",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 40,
              name: "焦煤",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 41,
              name: "焦炭",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 42,
              name: "铁矿石",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 61,
              name: "线材",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 38,
              name: "热卷",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 37,
              name: "硅铁",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 55,
              name: "动力煤",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 57,
              name: "工业硅",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 29,
              name: "玻璃",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
          ],
        },
        {
          id: 1,
          name: "油脂板块",
          son: [
            {
              id: 3,
              name: "菜籽油",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 2,
              name: "棕榈油",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 1,
              name: "豆油",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
          ],
        },
        {
          id: 7,
          name: "化工品",
          son: [
            {
              id: 66,
              name: "对二甲苯",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 65,
              name: "烧碱",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 64,
              name: "丁二烯橡胶",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            // {
            //   id: 62,
            //   name: '沪银',
            //   symbol: '',
            //   exchange: '',
            //   lastPrice: 0,
            //   zdf: 0
            // },
            {
              id: 30,
              name: "纸浆",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            // {
            //   id: 29,
            //   name: '玻璃',
            //   symbol: '',
            //   exchange: '',
            //   lastPrice: 0,
            //   zdf: 0
            // },
            {
              id: 28,
              name: "20号胶",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 27,
              name: "橡胶",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 26,
              name: "纯碱",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 25,
              name: "苯乙烯（EB）",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
          ],
        },
        {
          id: 4,
          name: "塑化链",
          son: [
            {
              id: 19,
              name: "塑料",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 18,
              name: "聚氯乙烯（PVC）",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 17,
              name: "聚丙烯（PP）",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
          ],
        },
        {
          id: 10,
          name: "有色板块",
          son: [
            {
              id: 63,
              name: "碳酸锂",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 62,
              name: "沪银",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 60,
              name: "氧化铝",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 52,
              name: "国际铜",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 50,
              name: "沪金",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 49,
              name: "沪镍",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 48,
              name: "沪铝",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 47,
              name: "沪锡",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 46,
              name: "沪锌",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 45,
              name: "沪铅",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 44,
              name: "沪铜",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            // {
            //   id: 51,
            //   name: '白银',
            //   symbol: '',
            //   exchange: '',
            //   lastPrice: 0,
            //   zdf: 0
            // }
          ],
        },
        {
          id: 2,
          name: "软商板块",
          son: [
            {
              id: 56,
              name: "棉纱",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 5,
              name: "白糖",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 4,
              name: "棉花",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
          ],
        },
        {
          id: 3,
          name: "农产品",
          son: [
            {
              id: 58,
              name: "菜籽",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 54,
              name: "强麦",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 16,
              name: "淀粉",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 15,
              name: "玉米",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 14,
              name: "豆二",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 13,
              name: "豆一",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 12,
              name: "花生",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 11,
              name: "生猪",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 10,
              name: "鸡蛋",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 9,
              name: "红枣",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 8,
              name: "豆粕",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 7,
              name: "菜粕",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 6,
              name: "苹果",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
          ],
        },
        {
          id: 8,
          name: "石油板块",
          son: [
            {
              id: 35,
              name: "液化石油气（PG）",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 34,
              name: "沥青",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 33,
              name: "低硫燃料油",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 32,
              name: "燃料油",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 31,
              name: "原油",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
          ],
        },
        {
          id: 6,
          name: "煤化工",
          son: [
            {
              id: 24,
              name: "尿素",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 23,
              name: "甲醇",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
          ],
        },
        {
          id: 5,
          name: "聚酯链",
          son: [
            {
              id: 68,
              name: "瓶片",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 22,
              name: "乙二醇（EG）",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 21,
              name: "短纤",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 20,
              name: "PTA",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
          ],
        },
        {
          id: 11,
          name: "指数期货",
          son: [
            {
              id: 67,
              name: "集运欧线",
              symbol: "",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
          ],
        },
        {
          id: 12,
          name: "金融期货",
          son: [
            {
              id: 73,
              name: "中证1000",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 72,
              name: "中证500",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 71,
              name: "沪深300",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
            {
              id: 70,
              name: "上证50",
              exchange: "",
              lastPrice: 0,
              zdf: 0,
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.getCategoryDataList();
  },
  methods: {
    // 获取各个合约的涨跌幅及最新价
    async getCategoryDataList() {
      const res = await Service.getCategoryDataList({
        isNight: 0,
      });
      this.list = res.data;
    },
  },
};
</script>

<style scoped>
.ratio_body {
  width: 100%;
  background-color: #151b30;
  box-sizing: border-box;
  padding: 7px;
  display: flex;
}

.ratio_body > div {
  flex: 1;
}

.ratio_body > div:not(:last-child) {
  margin-right: 8px;
}

.ratio_body .red {
  background-color: #c55a5a;
}

.ratio_body .red > span:last-child::before {
  content: "+";
}

.ratio_body .red1 {
  background-color: #aa3e3e;
}

.ratio_body .red1 > span:last-child::before {
  content: "+";
}

.ratio_body .green {
  background-color: #34a46c;
}

.ratio_body .green1 {
  background-color: #248052;
}

.ratio_cell {
  width: 100%;
  /* margin-bottom: 4px; */
}

.ratio_cell_title {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 5px 0;
  background-color: #232637;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 4px;
}

.ratio_cell_block {
}

.ratio_cell_cell {
  float: left;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 4px;
  background-color: #3c4053;
  cursor: pointer;
  transition: font-size 0.2s;
}

.ratio_cell_cell > span:last-child {
  position: relative;
}

.ratio_cell_cell > span:last-child::after {
  content: "%";
}

.clear {
  clear: both !important;
}
</style>
