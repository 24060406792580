import request from "../utils/request";
//上传base64
export const uploadBase64 = (query) => {
  return request({
    url: "/teacher/common/uploadBase64",
    method: "POST",
    data: query,
  });
};
// 推送到V策快讯
export const pushViewPoint = (query) => {
  return request({
    url: "/teacher/view_point_teacher/pushViewPoint",
    method: "POST",
    data: query,
  });
};
// 基差
export const jiCha = (query) => {
  return request({
    url: "/teacher/view_point_teacher/jiCha",
    method: "POST",
    data: query,
  });
};
// 投机排行榜
export const touJi = (query) => {
  return request({
    url: "/teacher/view_point_teacher/touJi",
    method: "POST",
    data: query,
  });
};
// 投机排行榜JAVA
export const touJiJava = (query) => {
  return request({
    url: "/javactp/speculativeRankingData",
    method: "get",
    data: query,
  });
};
