<template>
  <div>
    <div class="flex at_c">
      <div class="flex at_c mr15">
        <div class="mr10 fz14">日期</div>
        <el-date-picker
          v-model="date"
          type="date"
          placeholder="选择日期"
          value-format="YYYY-MM-DD"
        />
      </div>
      <div class="flex at_c mr15">
        <div class="mr10 fz14 fs_0">昵称</div>
        <el-input
          clearable
          v-model="search"
          placeholder="请输入昵称"
        ></el-input>
      </div>
      <div class="mr10">
        <el-button icon="Search" type="primary" @click="getStatisticsInfo(1)"
          >搜索</el-button
        >
      </div>
    </div>
    <el-table
      show-summary
      stripe
      :data="list"
      style="width: 100%"
      height="70vh"
    >
      <el-table-column prop="id" label="ID" width="180"></el-table-column>
      <el-table-column label="昵称/手机号" width="180">
        <template v-slot="scope">
          {{ scope.row.nickname }}
          <br />
          {{ scope.row.mobile }}
        </template>
      </el-table-column>
      <el-table-column
        sortable
        prop="qiyou_count"
        label="期友社区动态数量"
        width="180"
        align="center"
      ></el-table-column>
      <el-table-column
        sortable
        prop="accept_count"
        label="点赞数量"
        width="180"
        align="center"
      ></el-table-column>
      <el-table-column
        sortable
        prop="comment_count"
        label="评论数量"
        width="180"
        align="center"
      ></el-table-column>
      <el-table-column
        sortable
        prop="vote_count"
        label="投票数量"
        width="180"
        align="center"
      ></el-table-column>
    </el-table>
    <!-- <div class="mt20">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
import * as Service from "@/api/qiyouCommunity.js";
import { formatTime } from "@/utils/common";
export default {
  data() {
    return {
      page: 1,
      pageSize: 15,
      list: [],
      total: null,
      date: formatTime(Math.round(new Date() / 1000), "Y-M-D"),
      search: "",
    };
  },
  mounted() {},
  methods: {
    async getStatisticsInfo(page) {
      const res = await Service.getStatisticsInfo({
        page,
        date: this.date,
        search: this.search,
      });
      this.page = page;
      this.list = res.data;
    },
    handleCurrentChange(val) {
      this.getVoteList(val);
      console.log(`当前页: ${val}`);
    },
    handleCurrentChange1(val) {
      this.getUserVoteList(val);
      console.log(`当前页: ${val}`);
    },
    getRealTime() {
      var d = new Date();
      var year = d.getFullYear();
      var month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      var days = new Array("日", "一", "二", "三", "四", "五", "六");
      var day = d.getDay();
      var hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      var min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      var sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
      var cori_date = year + "-" + month + "-" + date;
      var cori_time = hour + ":" + min + ":" + sec;
      this.date = cori_date + " " + cori_time;
    },
  },
};
</script>

<style scoped>
.progress_cell {
  width: 190px;
  height: 14px;
  border-radius: 17px;
  /* background-color: rgba(0, 0, 0, 0.3); */
}

.current_progress {
  height: 100%;
  position: relative;
  border-radius: 17px;
  display: flex;
  align-items: center;
}

.current_progress span {
  position: absolute;
  right: -130px;
  font-size: 14px;
  font-weight: bold;
}

.el-table ::v-deep th.el-table__cell,
.el-table ::v-deep td.el-table__cell {
  position: static;
}

.label_zhiding,
.label_new,
.label_hot {
  box-sizing: border-box;
  padding: 4px 6px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 12px;
  border-radius: 2px;
  display: inline-block;
}

.label_zhiding {
  background: #e63434;
}

.label_new {
  background: #4571e7;
}

.label_hot {
  background: #ff6600;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.avatar-uploader_div {
  width: 178px;
  height: 178px;
  display: block;
  position: relative;
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.zhezhao {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zhezhao:hover {
  opacity: 1;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

/deep/.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}

.vote_avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 10px;
  display: block;
}
</style>
