import request from "../utils/request";
//获取基础信息，有研究员列表、品种列表
export const baseInfo = (query) => {
  return request({
    url: "/teacher/policy_notice/getBaseInfo",
    method: "POST",
    data: query,
  });
};
//获取策略列表
export const policyList = (query) => {
  return request({
    url: "/teacher/policy_notice/policyList",
    method: "POST",
    data: query,
  });
};
//删除策略
export const delPolicy = (query) => {
  return request({
    url: "/teacher/policy_notice/delPolicy",
    method: "POST",
    data: query,
  });
};
//添加入场
export const addAdmissionPolicy = (query) => {
  return request({
    url: "/teacher/policy_notice/addAdmissionPolicy",
    method: "POST",
    data: query,
  });
};
//添加离场
export const addDeparturePolicy = (query) => {
  return request({
    url: "/teacher/policy_notice/addDeparturePolicy",
    method: "POST",
    data: query,
  });
};
//添加特别说明
export const addEspeciallyRemarks = (query) => {
  return request({
    url: "/teacher/policy_notice/addEspeciallyRemarks",
    method: "POST",
    data: query,
  });
};
//获取直播间历史消息
export const roomChatList = (query) => {
  return request({
    url: "/api/chat/getRoomChatList",
    method: "POST",
    data: query,
  });
};
//获取直播间信息
export const getRoomInfo = (query) => {
  return request({
    url: "/teacher/chat/getRoomInfo",
    method: "POST",
    data: query,
  });
};
//设置直播间播放状态
export const setLiveStatus = (query) => {
  return request({
    url: "/teacher/chat/setLiveStatus",
    method: "POST",
    data: query,
  });
};
//设置直播间在麦老师
export const setRoomTeacher = (query) => {
  return request({
    url: "/teacher/chat/setRoomTeacher",
    method: "POST",
    data: query,
  });
};
// 获取我的海报
export const getUserPoster = (query) => {
  return request({
    url: "/teacher/user/getUserPoster",
    method: "POST",
    data: query,
  });
};
// 获取socket链接
export const getSocketUrl = (query) => {
  return request({
    url: "/api/common/getSocketUrl",
    method: "POST",
    data: query,
  });
};
// 获取bbas基础信息
export const getBaseInfoBBAS = (query) => {
  return request({
    url: "/api/bbas/getBaseInfo",
    method: "POST",
    data: query,
  });
};
