<template>
  <div>
    <div class="flex at_c">
      <div class="flex at_c mr15">
        <div class="mr10 fz14">品种</div>
        <el-select
          v-model="category_id"
          class="m-2"
          placeholder="请选择品种"
          filterable
          clearable
        >
          <el-option label="全部" :value="0" />
          <el-option
            v-for="(item, index) in categoryList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </div>
      <div class="mr10">
        <el-button icon="Search" type="primary" @click="getVoteList(1)"
          >搜索</el-button
        >
      </div>
      <div class="mr10">
        <el-button type="primary" @click="modifyFormShow = true"
          >添加</el-button
        >
      </div>
    </div>
    <el-table :data="list" style="width: 100%" height="70vh">
      <el-table-column prop="id" label="ID" width="180"></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="title"
        label="名称"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="category.name"
        label="关联品种"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="vote_count"
        label="总票数"
        width="180"
      ></el-table-column>
      <el-table-column label="投票详情" width="420">
        <template v-slot="scope">
          <!-- <div class="flex at_c">
            <div class="fb mr10" style="color: #ff0000">啊啊：</div>
            <div class="progress_cell">
              <div
                class="current_progress"
                style="width: 100%; background-color: #ff0000"
              >
                <span>100.00%</span>
              </div>
            </div>
          </div> -->
          <div
            class="flex at_c"
            v-for="(item, index) in scope.row.option"
            :key="index"
          >
            <div class="fb mr10" :style="{ color: item.color }">
              {{ item.name }}：
            </div>
            <div class="progress_cell">
              <div
                class="current_progress"
                :style="{
                  width: (item.data.rate * 1 > 0 ? item.data.rate : 1) + '%',
                  backgroundColor: item.color,
                }"
              >
                <span>{{ item.data.rate }}%（{{ item.data.count }}次）</span>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="起止时间" width="200">
        <template v-slot="scope">
          <div>起：{{ scope.row.start_time_text }}</div>
          <div>止：{{ scope.row.end_time_text }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="280">
        <template v-slot="scope">
          <el-button
            @click="handleShowList(scope.$index, scope.row)"
            type="primary"
            size="small"
            icon="List"
            >投票详情</el-button
          >
          <el-button
            @click="handleShowEdit(scope.$index, scope.row)"
            type="primary"
            size="small"
            icon="Edit"
            >编辑</el-button
          >
          <el-button
            @click="handleDelete(scope.$index, scope.row)"
            type="danger"
            size="small"
            icon="Delete"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="mt20">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 添加&编辑 -->
    <el-dialog
      width="680px"
      v-model="modifyFormShow"
      @close="closeForm"
      :title="modifyForm.id ? '编辑' : '添加'"
    >
      <el-form :model="modifyForm" ref="modifyForm">
        <div>
          <div class="form_cell">
            <el-form-item
              size="large"
              label="标题"
              :label-width="formLabelWidth1"
            >
              <el-input
                style="width: 460px"
                type="textarea"
                :rows="3"
                v-model="modifyForm.title"
                autocomplete="off"
              />
            </el-form-item>
          </div>
          <div class="form_cell">
            <el-form-item
              label="关联品种"
              :label-width="formLabelWidth1"
              size="large"
            >
              <el-select
                v-model="modifyForm.category_id"
                class="m-2"
                placeholder="请选择关联品种"
                filterable
              >
                <el-option
                  v-for="(item, index) in categoryList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </div>
          <div class="form_cell">
            <el-form-item
              size="large"
              label="开始时间"
              :label-width="formLabelWidth1"
            >
              <el-date-picker
                v-model="modifyForm.start_time"
                type="datetime"
                value-format="x"
                placeholder="请选择开始时间"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="form_cell">
            <el-form-item
              size="large"
              label="结束时间"
              :label-width="formLabelWidth1"
            >
              <el-date-picker
                v-model="modifyForm.end_time"
                type="datetime"
                value-format="x"
                placeholder="请选择结束时间"
              >
              </el-date-picker>
            </el-form-item>
          </div>
          <!-- <div class="form_cell">
            <el-form-item
              size="large"
              label="投票间隔"
              :label-width="formLabelWidth1"
            >
              <div class="flex at_c">
                <el-input
                  v-model="modifyForm.interval_time"
                  autocomplete="off"
                  placeholder="请输入投票间隔"
                />
                <div class="fs_0 ml10">分钟</div>
              </div>
            </el-form-item>
          </div> -->
          <div class="form_cell">
            <el-form-item
              size="large"
              label="启用选项"
              :label-width="formLabelWidth1"
            >
              <div class="flex fd_c">
                <div
                  class="mr20 flex at_c mb10"
                  v-for="(item, index) in modifyForm.option"
                  :key="index"
                >
                  <el-checkbox v-model="item.is_show"></el-checkbox>
                  <el-input
                    class="ml20"
                    placeholder="请填写选项"
                    v-model="item.name"
                  ></el-input>
                  <el-select
                    class="ml20"
                    v-model="item.color"
                    placeholder="请选择颜色"
                  >
                    <el-option
                      v-for="item in colorArr"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </el-form-item>
          </div>
          <div class="form_cell">
            <el-form-item
              size="large"
              label="正确选项"
              :label-width="formLabelWidth1"
            >
              <el-select
                class="ml20"
                v-model="modifyForm.answer"
                placeholder="请选择正确选项"
              >
                <el-option
                  v-for="item in modifyForm.option"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="modifyFormShow = false">取消</el-button>
          <el-button type="primary" @click="submitForm">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 列表 -->
    <el-dialog
      width="680px"
      v-model="userVoteListShow"
      @close="closeForm"
      title="投票详情"
    >
      <el-table :data="userVoteList">
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column prop="id" label="投票人" width="180">
          <template v-slot="scope">
            <div class="flex at_c">
              <img class="vote_avatar" :src="scope.row.user.avatar" alt="" />
              {{ scope.row.user.nickname }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="value"
          label="投票选项"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="createtime_text"
          label="投票时间"
          width="200"
        ></el-table-column>
      </el-table>
      <div class="mt20">
        <el-pagination
          background
          @current-change="handleCurrentChange1"
          :current-page="userVoteListPage"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="userVoteListTotal"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "@/api/qiyouCommunity.js";
export default {
  data() {
    return {
      categoryList: [],
      category_id: 0, // 品种id
      page: 1,
      pageSize: 15,
      list: [],
      total: null,
      formLabelWidth1: "100px",
      modifyFormShow: false,
      modifyForm: {
        id: null,
        title: "",
        category_id: "",
        start_time: null,
        end_time: null,
        interval_time: 0, // 间隔时间(分钟)
        option: [
          {
            name: "看多",
            value: "A",
            is_show: true,
            color: "#E63434",
          },
          {
            name: "看空",
            value: "B",
            is_show: true,
            color: "#03A418",
          },
          {
            name: "震荡",
            value: "C",
            is_show: true,
            color: "#F6C932",
          },
        ],
        answer: "",
      },
      colorArr: [
        {
          name: "红色",
          value: "#E63434",
        },
        {
          name: "绿色",
          value: "#03A418",
        },
        {
          name: "黄色",
          value: "#F6C932",
        },
      ],
      tagList: [],
      userVoteListShow: false,
      voteId: null,
      userVoteList: [], // 用户投票列表
      userVoteListPage: 1,
      userVoteListTotal: null,
    };
  },
  mounted() {
    this.getBaseInfo();
  },
  methods: {
    async getBaseInfo() {
      const res = await Service.getBaseInfo();
      this.categoryList = res.data.categoryList;
    },
    async getVoteList(page) {
      const res = await Service.getVoteList({
        page,
        category_id: this.category_id,
      });
      this.page = page;
      this.list = res.data.data;
      this.total = res.data.total;
    },
    handleCurrentChange(val) {
      this.getVoteList(val);
      console.log(`当前页: ${val}`);
    },
    handleCurrentChange1(val) {
      this.getUserVoteList(val);
      console.log(`当前页: ${val}`);
    },
    handleShowList(index, row) {
      this.userVoteListShow = true;
      this.voteId = row.id;
      this.getUserVoteList(1);
    },
    // 用户投票列表
    async getUserVoteList(page) {
      const res = await Service.getUserVoteList({
        page,
        id: this.voteId,
        nickname: "",
      });
      this.userVoteListPage = page;
      this.userVoteList = res.data.data;
      this.userVoteListTotal = res.data.total;
    },
    handleShowEdit(index, row) {
      var option = [
        {
          name: "",
          value: "A",
          is_show: false,
          color: "",
        },
        {
          name: "",
          value: "B",
          is_show: false,
          color: "",
        },
        {
          name: "",
          value: "C",
          is_show: false,
          color: "",
        },
      ];
      for (const item of option) {
        for (const items of row.option) {
          if (item.value == items.value) {
            item.name = items.name;
            item.color = items.color;
            item.is_show = true;
          }
        }
      }
      this.modifyFormShow = true;
      this.modifyForm = {
        id: row.id,
        title: row.title,
        category_id: row.category_id,
        start_time: row.start_time * 1000,
        end_time: row.end_time * 1000,
        interval_time: row.interval_time, // 间隔时间(分钟)
        option,
        answer: row.answer,
      };
    },
    handleDelete(index, row) {
      this.$confirm("确认删除该数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.handleDeleteApi(index, row);
      });
      // console.log(index, row);
    },
    async handleDeleteApi(index, row) {
      const res = await Service.delVote({
        id: row.id,
      });
      this.$message.success(res.msg);
      this.getVoteList(this.page);
    },
    closeForm() {
      this.modifyForm = {
        id: null,
        title: "",
        category_id: "",
        start_time: null,
        end_time: null,
        interval_time: 0, // 间隔时间(分钟)
        option: [
          {
            name: "看多",
            value: "A",
            is_show: true,
            color: "#E63434",
          },
          {
            name: "看空",
            value: "B",
            is_show: true,
            color: "#03A418",
          },
          {
            name: "震荡",
            value: "C",
            is_show: true,
            color: "#F6C932",
          },
        ],
      };
    },
    async submitForm() {
      var obj = { ...this.modifyForm };
      let res = null;
      if (!obj.title) {
        this.$message.error("标题不可为空");
        return false;
      }
      if (!obj.category_id) {
        this.$message.error("关联品种不可为空");
        return false;
      }
      if (!obj.start_time) {
        this.$message.error("开始时间不可为空");
        return false;
      }
      if (!obj.end_time) {
        this.$message.error("结束时间不可为空");
        return false;
      }
      obj.start_time = obj.start_time / 1000;
      obj.end_time = obj.end_time / 1000;
      if (obj.id) {
        res = await Service.editVote(obj);
      } else {
        res = await Service.addVote(obj);
      }
      this.$message.success(res.msg);
      this.modifyFormShow = false;
      this.getVoteList(1);
      this.modifyForm = {
        id: null,
        title: "",
        category_id: "",
        start_time: null,
        end_time: null,
        interval_time: null, // 间隔时间(分钟)
        option: [
          {
            name: "看多",
            value: "A",
            is_show: true,
          },
          {
            name: "看空",
            value: "B",
            is_show: true,
          },
          {
            name: "震荡",
            value: "C",
            is_show: true,
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.progress_cell {
  width: 190px;
  height: 14px;
  border-radius: 17px;
  /* background-color: rgba(0, 0, 0, 0.3); */
}

.current_progress {
  height: 100%;
  position: relative;
  border-radius: 17px;
  display: flex;
  align-items: center;
}

.current_progress span {
  position: absolute;
  right: -130px;
  font-size: 14px;
  font-weight: bold;
}

.el-table ::v-deep th.el-table__cell,
.el-table ::v-deep td.el-table__cell {
  position: static;
}

.label_zhiding,
.label_new,
.label_hot {
  box-sizing: border-box;
  padding: 4px 6px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 12px;
  border-radius: 2px;
  display: inline-block;
}

.label_zhiding {
  background: #e63434;
}

.label_new {
  background: #4571e7;
}

.label_hot {
  background: #ff6600;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.avatar-uploader_div {
  width: 178px;
  height: 178px;
  display: block;
  position: relative;
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.zhezhao {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zhezhao:hover {
  opacity: 1;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

/deep/.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}

.vote_avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 10px;
  display: block;
}
</style>
