<template>
  <div class="wrapper">
    <v-sidebar></v-sidebar>
    <div class="cori-content-box overflow_y">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import vSidebar from "./Sidebar.vue";
export default {
  data() {
    return {};
  },
  components: {
    vSidebar,
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.cori-content-box {
  position: fixed;
  left: 200px;
  top: 0;
  bottom: 0;
  right: 0;
}
</style>
