import request from "../utils/request";
// 获取圈子数据统计
export const getCircleStatistics = (query) => {
  return request({
    url: "/teacher/statistics/getCircleStatistics",
    method: "POST",
    data: query,
  });
};
// 获取socket连接人数峰值
export const getSocketStatistics = (query) => {
  return request({
    url: "/teacher/statistics/getSocketStatistics",
    method: "POST",
    data: query,
  });
};
// 获取会员分布数据
export const getUserStatistics = (query) => {
  return request({
    url: "/teacher/statistics/getUserStatistics",
    method: "POST",
    data: query,
  });
};
// 获取老师圈子数据统计
export const getCircleDataStatistics = (query) => {
  return request({
    url: "/teacher/statistics/getCircleDataStatistics",
    method: "POST",
    data: query,
  });
};
