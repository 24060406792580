<template>
  <div>
    <pageTitle :title="title"></pageTitle>
    <div class="bb pt20 pl30" v-if="showMode == 1">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="圈子列表" name="first">
          <circleList ref="circleList" />
        </el-tab-pane>
        <el-tab-pane label="假人列表" name="second">
          <dummyList ref="dummyList" />
        </el-tab-pane>
        <el-tab-pane label="话题列表" name="three">
          <topicList ref="topicList" />
        </el-tab-pane>
        <el-tab-pane label="成员列表" name="fourth">
          <userList ref="userList" />
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="bb pt20 pl30" v-if="showMode == 2">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="话题列表" name="three">
          <topicList ref="topicList" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import pageTitle from "../components/pageTitle.vue";
import circleList from "../components/circleList.vue";
import dummyList from "../components/dummyList.vue";
import topicList from "../components/topicList.vue";
import userList from "../components/userList.vue";
export default {
  components: {
    pageTitle,
    circleList,
    dummyList,
    topicList,
    userList,
  },
  data() {
    return {
      title: "圈子管理",
      activeName: "first",
      showMode: 0, // 1V策财经2正信
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.activeName =
      localStorage.getItem("is_company") == 1 ? "three" : "first";
    this.showMode = localStorage.getItem("is_company") == 1 ? 2 : 1;
    setTimeout(() => {
      if (this.activeName == "first") {
        this.$refs.circleList.getCircleList();
      } else if (this.activeName == "second") {
        this.$refs.dummyList.getUserList(1);
      } else if (this.activeName == "three") {
        this.$refs.topicList.getTopicList(1);
      } else if (this.activeName == "fourth") {
        this.$refs.userList.getUserList(1);
      }
    }, 500);
  },
  methods: {
    // tab切换
    handleClick(tab, event) {
      this.page = 1;
      this.total = 0;
      if (this.activeName == "first") {
        this.$refs.circleList.getCircleList();
      } else if (this.activeName == "second") {
        this.$refs.dummyList.getUserList(1);
      } else if (this.activeName == "three") {
        this.$refs.topicList.getTopicList(1);
      } else if (this.activeName == "fourth") {
        this.$refs.userList.getUserList(1);
      }
    },
    onmessage(e) {
      // console.log('myclient-onmessage',JSON.parse(e.data))
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding: 15px 30px 0;
}
</style>
