import request from "../utils/request";
// 给客户发应用图片
export const sendAppImage = (query) => {
  return request({
    url: "/teacher/work_weixin/sendAppImage",
    method: "POST",
    data: query,
  });
};
// 上传base64
export const uploadBase64 = (query) => {
  return request({
    url: "/teacher/common/uploadBase64",
    method: "POST",
    data: query,
  });
};
// 获取品种信息
export const BaseInfo = (query) => {
  return request({
    url: "/teacher/bbas/getBaseInfo",
    method: "POST",
    data: query,
  });
};
