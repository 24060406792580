<template>
  <div>
    <pageTitle title="策略列表"></pageTitle>
    <div class="bb p30">
      <div class="head flex fw_w">
        <div>
          <div>策略ID</div>
          <div class="w200">
            <el-input
              clearable
              size="large"
              v-model="celue_id"
              placeholder="请输入策略ID"
            ></el-input>
          </div>
        </div>
        <div>
          <div>研究员</div>
          <div>
            <el-select
              v-model="teacher_name"
              class="m-2"
              placeholder="请选择老师"
              size="large"
            >
              <el-option label="全部" value="" />
              <el-option
                v-for="(item, index) in teacherList"
                :key="index"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </div>
        </div>
        <div>
          <div>发布时间</div>
          <div>
            <el-date-picker
              format="YYYY-MM-DD"
              value-format="x"
              size="large"
              v-model="time_range"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div>
          <div>是否平仓</div>
          <div>
            <el-select v-model="type" class="m-2" placeholder="" size="large">
              <el-option label="全部" :value="0" />
              <el-option label="未平仓" :value="1" />
              <el-option label="已平仓" :value="2" />
            </el-select>
          </div>
        </div>
        <div>
          <div>状态</div>
          <div>
            <el-select v-model="status" class="m-2" placeholder="" size="large">
              <el-option label="全部" :value="0" />
              <el-option label="止盈平仓" :value="1" />
              <el-option label="止损平仓" :value="2" />
            </el-select>
          </div>
        </div>
        <div>
          <el-button @click="getTechnologyCaseList(1)" type="primary"
            >搜 索</el-button
          >
        </div>
      </div>
      <div v-loading="loading">
        <div class="mb20">
          <el-table
            v-loading="loadingTable"
            :data="technologyCaseList"
            height="70vh"
            style="width: 100%"
          >
            <el-table-column
              width="80"
              align="center"
              prop="celue_id"
              label="策略ID"
            />
            <el-table-column width="300" align="center" label="品种合约">
              <template #default="scope">
                {{ scope.row.category.name }}{{ scope.row.contract }}
              </template>
            </el-table-column>
            <el-table-column width="200" align="left" label="均价">
              <template #default="scope">
                <div>
                  开仓：{{ scope.row.kaicang1 }}~{{ scope.row.kaicang2 }}
                </div>
                <div>
                  止盈：{{ scope.row.zhiying1 }}~{{ scope.row.zhiying2 }}
                </div>
                <div>止损：{{ scope.row.zhisun1 }}~{{ scope.row.zhisun2 }}</div>
                <div>
                  平仓：{{ scope.row.pingcang1 }}~{{ scope.row.pingcang2 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              width="300"
              align="center"
              prop="teacher_name"
              label="研究员"
            >
            </el-table-column>
            <el-table-column width="80" align="center" label="参考方向">
              <template #default="scope">
                {{ scope.row.direction_type == 1 ? "买多" : "卖空" }}
              </template>
            </el-table-column>
            <el-table-column width="80" align="center" label="状态">
              <template #default="scope">
                <div>
                  {{ scope.row.type == 2 ? "已平仓" : "未平仓" }}
                </div>
                <div style="color: #ff0022" v-if="scope.row.status == 1">
                  止盈
                </div>
                <div style="color: #1cb13c" v-if="scope.row.status == 2">
                  止损
                </div>
              </template>
            </el-table-column>
            <el-table-column
              width="170"
              align="center"
              prop="createtime_text"
              label="发布时间"
            />
          </el-table>
        </div>
        <div>
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            v-model:page-size="pageSize"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            :page-sizes="[15, 50, 100, 200]"
            @size-change="handleSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageTitle from "@/components/pageTitle.vue";
import * as Service from "@/api/companyCircle";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      celue_id: null,
      teacher_name: "",
      time_range: [], // 发布时间范围
      type: "",
      status: "",
      teacherList: [], //研究员列表
      page: 1,
      pageSize: 15,
      technologyCaseList: [],
      loading: false,
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.getBaseInfo1();
    this.getTechnologyCaseList(1);
  },
  methods: {
    onmessage(e) {},
    // 获取基础信息
    async getBaseInfo1() {
      const res = await Service.getBaseInfo();
      this.teacherList = res.data.teacherList;
    },
    // 获取策略信息列表
    async getTechnologyCaseList(page) {
      this.loading = true;
      var time_range0 = this.time_range ? [...this.time_range] : [];
      if (time_range0.length) {
        time_range0[0] = time_range0[0] / 1000;
        time_range0[1] = time_range0[1] / 1000;
      }
      const res = await Service.getTechnologyCaseList({
        page,
        celue_id: this.celue_id,
        teacher_name: this.teacher_name,
        type: this.type,
        status: this.status,
        time_range: time_range0,
        limit: this.pageSize,
      });
      this.page = page;
      this.total = res.data.total;
      this.technologyCaseList = res.data.data;
      this.loading = false;
    },
    handleCurrentChange(val) {
      this.getTechnologyCaseList(val);
      console.log(`当前页: ${val}`);
    },
    handleSizeChange(val) {
      this.getTechnologyCaseList(1);
    },
  },
};
</script>

<style scoped>
.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.w200 {
  width: 200px;
}

.teacher_remark {
  max-width: 300px;
  display: inherit !important;
}
</style>
