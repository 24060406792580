<template>
  <div class="body">
    <div class="plate_title">
      <div class="plate_title_name">多空情报</div>
    </div>
    <div class="bb p30">
      <div class="head flex fw_w">
        <div>
          <div>标题</div>
          <div class="w300">
            <el-input
              clearable
              size="large"
              v-model="title"
              placeholder="请输入标题"
            ></el-input>
          </div>
        </div>
        <div>
          <div>分类</div>
          <div>
            <el-select v-model="type" class="m-2" placeholder="" size="large">
              <el-option label="全部" :value="0" />
              <el-option
                v-for="(item, index) in typeArr"
                :key="index"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <div>
          <div>品种</div>
          <div>
            <el-select
              v-model="category_id"
              class="m-2"
              placeholder="请选择品种"
              size="large"
              filterable
            >
              <el-option label="全部" :value="0" />
              <el-option
                v-for="(item, index) in categoryList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
        </div>
        <!-- <div>
          <div>类型</div>
          <div>
            <el-select
              v-model="content_type"
              class="m-2"
              placeholder=""
              size="large"
            >
              <el-option label="全部" value="" />
              <el-option label="文字" :value="1" />
              <el-option label="PDF" :value="2" />
            </el-select>
          </div>
        </div> -->
        <div>
          <el-button @click="search" type="primary">搜 索</el-button>
        </div>
      </div>
      <div class="mb20">
        <el-button type="primary" @click="handleFormShow()">添加</el-button>
      </div>
      <!-- 数据列表 -->
      <div v-loading="listLoading">
        <div class="mb20">
          <el-table :data="list" height="70vh" style="width: 100%">
            <el-table-column label="ID" width="120" prop="id">
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              label="标题"
              width="300"
              prop="title"
            >
            </el-table-column>
            <el-table-column label="分类" width="120" prop="type_text">
            </el-table-column>
            <el-table-column
              label="品种"
              width="220"
              prop="category.name"
            ></el-table-column>
            <el-table-column label="创建日期" width="120" prop="createtime">
            </el-table-column>
            <el-table-column label="更多信息" width="300">
              <template #default="scope">
                <!-- 市场速递 -->
                <template v-if="scope.row.type == 1">
                  <div>突出：{{ scope.row.is_tuchu == 1 ? "是" : "否" }}</div>
                  <div>解读：{{ scope.row.is_explain == 1 ? "是" : "否" }}</div>
                  <div v-if="scope.row.is_explain == 1">
                    占比：{{ scope.row.duo }}
                  </div>
                </template>
                <!-- 机构观点 -->
                <template v-if="scope.row.type == 3">
                  <!-- 文字类型 -->
                  <template v-if="scope.row.content_type == 1">
                    <!-- <div>突出：{{ scope.row.is_tuchu == 1 ? "是" : "否" }}</div>
                    <div>
                      解读：{{ scope.row.is_explain == 1 ? "是" : "否" }}
                    </div> -->
                    <div v-if="scope.row.is_explain == 1">
                      看多：{{ scope.row.kanduo }}
                    </div>
                    <div v-if="scope.row.is_explain == 1">
                      看空：{{ scope.row.kankong }}
                    </div>
                    <div v-if="scope.row.is_explain == 1">
                      震荡：{{ scope.row.zhendang }}
                    </div>
                  </template>
                  <!-- PDF类型 -->
                  <template v-if="scope.row.content_type == 2">
                    <div>
                      链接：<a :href="scope.row.pdf_url" target="_blank"
                        >文件链接</a
                      >
                    </div>
                    <div>期货公司：{{ scope.row.broker_name }}</div>
                    <div>
                      方向：
                      <template v-if="scope.row.direction == 0">-</template>
                      <template v-if="scope.row.direction == 1">看多</template>
                      <template v-if="scope.row.direction == 2">看空</template>
                      <template v-if="scope.row.direction == 3">震荡</template>
                    </div>
                  </template>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="300" align="center">
              <template #default="scope">
                <el-button
                  type="primary"
                  size="default"
                  @click="handleFormShow(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  size="default"
                  @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出表单 -->
    <el-dialog
      width="900px"
      v-model="formShow"
      :title="formTitle"
      :before-close="handleClose"
    >
      <div class="flex">
        <el-form ref="form" :model="form" label-width="80px">
          <!-- <el-form-item label="分类">
            <el-select
              v-model="form.type"
              class="m-2"
              :disabled="form.id"
              placeholder="请选择分类"
              size="large"
              filterable
            >
              <el-option
                v-for="(item, index) in typeArr"
                :key="index"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item> -->
          <el-form-item label="分类">
            <el-radio-group v-model="currentTypeName">
              <el-radio-button
                v-for="(item, index) in typeArr"
                :key="index"
                :label="item.name"
              ></el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="品种">
            <el-select
              v-model="form.category_id"
              class="m-2"
              placeholder="请选择品种"
              size="large"
              filterable
            >
              <el-option
                v-for="(item, index) in categoryList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="关联品种" v-if="form.type == 1">
            <el-select
              v-model="form.relation_category_id"
              class=""
              placeholder="请选择品种"
              size="large"
              filterable
              multiple
              clearable
            >
              <el-option
                v-for="(item, index) in categoryList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="标题" v-if="form.type == 1 || form.type == 5">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="图片" v-if="form.type == 5">
            <el-upload
              class="avatar-uploader"
              action="https://api.i-vce.com/teacher/common/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <div class="avatar-uploader_div" v-if="form.image">
                <img :src="form.image" class="avatar" />
                <div class="zhezhao">
                  <el-icon @click.stop="handleRemove" :size="24">
                    <Delete color="#ffffff" />
                  </el-icon>
                </div>
              </div>
              <el-icon v-else class="avatar-uploader-icon">
                <Plus />
              </el-icon>
            </el-upload>
          </el-form-item>
          <el-form-item
            label="是否解读"
            v-show="form.content_type == 1 && form.type == 1"
          >
            <el-radio-group v-model="form.is_explain">
              <el-radio-button label="否" />
              <el-radio-button label="是" />
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="突出显示"
            v-show="form.content_type == 1 && form.type == 1"
          >
            <el-radio-group v-model="form.is_tuchu">
              <el-radio-button label="否" />
              <el-radio-button label="是" />
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="内容"
            v-show="form.content_type == 1 && form.type == 1"
          >
            <div class="mb10">
              <el-button @click="exportTextModel(1)" type="primary"
                >导入内容模板</el-button
              >
            </div>
            <div id="editor1"></div>
          </el-form-item>
          <el-form-item label="机构信息" v-show="form.type == 3">
            <div>
              <div
                class="mb15"
                v-for="(item, index) in form.view_point_json"
                :key="index"
              >
                <div class="flex at_c">
                  <div class="flex at_c">
                    <el-select
                      @change="changeCompanyStatus"
                      v-model="item.company_name"
                      class="m-2"
                      placeholder="请选择期货公司"
                      filterable
                    >
                      <el-option
                        v-for="(item, index) in companyList"
                        :key="index"
                        :disabled="item.disabled"
                        :label="item.name"
                        :value="item.name"
                      />
                    </el-select>
                  </div>
                  <div class="flex at_c ml20">
                    <el-radio-group v-model="item.type">
                      <el-radio-button label="看多"></el-radio-button>
                      <el-radio-button label="看空"></el-radio-button>
                      <el-radio-button label="震荡"></el-radio-button>
                    </el-radio-group>
                  </div>
                </div>
                <div class="mt5 flex at_c">
                  <div style="width: 690px">
                    <el-input
                      placeholder="请输入机构观点"
                      v-model="item.viewpoint"
                    ></el-input>
                  </div>
                  <img
                    @click="delCompany(index)"
                    class="icon_del can"
                    src="@/assets/img/icon_del.png"
                    alt=""
                  />
                </div>
              </div>
              <div>
                <el-button @click="addCompany" type="primary"
                  >添加机构</el-button
                >
              </div>
            </div>
          </el-form-item>
          <!-- <el-form-item
            label="看多机构"
            v-show="form.is_explain == '是' && form.type == 3"
          >
            <el-input-number
              v-model="form.kanduo"
              :min="0"
              label="看多机构数"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            label="看空机构"
            v-show="form.is_explain == '是' && form.type == 3"
          >
            <el-input-number
              v-model="form.kankong"
              :min="0"
              label="看空机构数"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            label="震荡机构"
            v-show="form.is_explain == '是' && form.type == 3"
          >
            <el-input-number
              v-model="form.zhendang"
              :min="0"
              label="震荡机构数"
            ></el-input-number>
          </el-form-item> -->
          <el-form-item
            label="解读"
            v-show="form.is_explain == '是' && form.type != 5"
          >
            <div class="mb10">
              <el-button @click="exportTextModel(2)" type="primary"
                >导入解读模板</el-button
              >
            </div>
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 10 }"
              v-model="form.explain"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="占比"
            v-show="form.is_explain == '是' && form.type == 1"
          >
            <el-slider v-model="form.duo" show-input :min="17" :max="83" />
          </el-form-item>
          <el-form-item label="方向" v-show="form.content_type == 2">
            <el-radio-group v-model="form.direction">
              <el-radio :label="0">不显示</el-radio>
              <el-radio :label="1">看多</el-radio>
              <el-radio :label="2">看空</el-radio>
              <el-radio :label="3">震荡</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否同步到V策快讯" v-if="form.type != 5">
            <el-radio-group v-model="form.relation_viewpoint">
              <el-radio-button label="否" />
              <el-radio-button label="是" />
            </el-radio-group>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "../api/bbas";
import E from "wangeditor";
import companyList from "@/utils/futuresCompanies";
import { Delete, Plus } from "@element-plus/icons-vue";
const OSS = require("ali-oss");
export default {
  components: {
    Plus,
    Delete,
  },
  data() {
    return {
      title: "", // 搜索的标题
      typeArr: [], // 分类列表
      type: 1, // 搜索的分类
      categoryList: [], // 品种列表
      category_id: 0, // 搜索的品种id
      content_type: 1, // 搜索的类型1文字2PDF
      listLoading: false,
      list: [],
      page: 1,
      pageSize: 15,
      total: 0,

      formShow: false,
      formTitle: "",
      form: {
        id: null,
        type: "",
        title: "", // 标题
        image: "",
        category_id: null, // 品种
        relation_category_id: [], // 关联品种
        content: "", // 内容
        content_type: 1, // 类型1文字2PDF
        is_explain: "是", // 是否解读
        explain: "", // 解读信息
        duo: 50, // 看多值

        is_tuchu: "否", // 是否突出
        kanduo: 0, // 看多机构数
        kankong: 0, // 看空机构数
        zhendang: 0, // 震荡机构数

        direction: 0, // 0不显示1看多2看空3震荡

        view_point_json: [
          {
            company_name: "",
          },
        ],
        relation_viewpoint: "是", // 是否同步到V策快讯
      }, // 提交表单信息
      editor: null,

      currentTypeName: "", // 跟随form.type

      explainTextMould2: "", // 解读的文字模板

      companyList: [],
      explainTextMould1:
        "<p>内容来自：</p><p>如有侵权请联系删除。</p><p>以上内容仅作参考，不作为入市依据，投资有风险，入市需谨慎。</p>", // 内容模板
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    for (const item of companyList) {
      item.disabled = false;
    }
    this.companyList = companyList;
    this.getBaseInfo();
    this.getList(1);
    this.initExplainTextMould();
  },
  watch: {
    currentTypeName(e) {
      console.log("watch", e);
      var typeArr = this.typeArr;
      for (const item of typeArr) {
        if (e == item.name) {
          this.form.type = item.value;
          if (e == "机构观点") {
            this.form.is_explain = "是";
            this.form.is_tuchu = "否";
          }
          return false;
        }
      }
    },
  },
  methods: {
    onmessage(e) {},
    handleRemove() {
      console.log("@删除图片");
      this.form.image = "";
    },
    handleAvatarSuccess(res, file) {
      this.form.image = res.data.fullurl;
    },
    changeCompanyStatus() {
      const viewPointJson = this.form.view_point_json;
      const companyList = this.companyList;
      for (const c of companyList) {
        c.disabled = false;
      }
      for (const v of viewPointJson) {
        for (const c of companyList) {
          if (v.company_name == c.name) {
            c.disabled = true;
          }
        }
      }
    },
    addCompany() {
      this.form.view_point_json.push({
        company_name: "",
        type: "",
        viewpoint: "",
      });
    },
    delCompany(index) {
      this.form.view_point_json.splice(index, 1);
    },
    initExplainTextMould() {
      var d = new Date();
      var year = d.getFullYear();
      var month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      var days = new Array("日", "一", "二", "三", "四", "五", "六");
      var day = d.getDay();
      var hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      var min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      var sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
      var cori_date = year + "年" + month + "月" + date + "日";
      var cori_time = hour + ":" + min + ":" + sec;
      this.explainTextMould2 =
        month +
        "月" +
        date +
        "日机构观点：累计发布观点家。\n给出看多机构：家\n给出看空机构：家\n给出震荡观望机构：家"; // 解读的文字模板
    },
    exportTextModel(val) {
      if (val == 1) {
        this.form.content = this.explainTextMould1;
        this.editor.txt.html(this.explainTextMould1);
      } else {
        this.form.explain = this.explainTextMould2;
      }
    },
    search() {
      this.list = [];
      this.page = 1;
      this.getList(1);
    },
    async onSubmit() {
      var form = this.form;
      var obj = { ...form };
      if (!obj.type) {
        this.$message.error("请选择分类");
        return false;
      }
      if (!obj.category_id) {
        this.$message.error("请选择品种");
        return false;
      }
      obj.is_explain = obj.is_explain == "是" ? 1 : 0;
      obj.is_tuchu = obj.is_tuchu == "是" ? 1 : 0;
      obj.relation_viewpoint = obj.relation_viewpoint == "是" ? 1 : 0;
      obj.relation_category_id = obj.relation_category_id.toString();
      var val = 0;
      for (const item of obj.view_point_json) {
        if (!item.company_name || !item.type || !item.viewpoint) {
          val++;
        }
      }
      if (val) {
        this.$message.error("机构信息有未填项，请检查");
        return false;
      }
      if (obj.view_point_json.length) {
        obj.view_point_json.map((e) => {
          switch (e.type) {
            case "看多":
              e.type = 1;
              break;
            case "看空":
              e.type = 2;
              break;
            case "震荡":
              e.type = 3;
              break;
          }
        });
      }

      var res = null;
      if (obj.id) {
        res = await Service.editInfo(obj);
      } else {
        res = await Service.addInfo(obj);
      }
      this.$message.success(res.msg);
      this.formShow = false;
      this.getList(this.page);
    },
    // 获取基础信息
    async getBaseInfo() {
      const res = await Service.BaseInfo();
      this.typeArr = res.data.typeArr;
      this.categoryList = res.data.categoryList;
    },
    // 信息列表
    async getList(page) {
      this.listLoading = true;
      const res = await Service.getInfoList({
        page,
        limit: this.pageSize,
        title: this.title,
        type: this.type,
        category_id: this.category_id,
        content_type: this.content_type,
      });
      console.log("信息列表", res);
      this.page = page;
      this.total = res.data.total;
      this.list = res.data.data;
      this.listLoading = false;
    },
    handleCurrentChange(val) {
      this.list = [];
      this.getList(val);
    },
    // 获取图片名称
    getFileName(file) {
      var d = new Date();
      var year = d.getFullYear();
      var month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      var type = file.type.substr(
        file.type.lastIndexOf("/") + 1,
        file.type.length
      );
      return (
        "push/bbas/" +
        year +
        month +
        date +
        "/" +
        Math.round(new Date()) +
        "." +
        type
      );
    },
    // 创建富文本编辑器
    createEditor() {
      let that = this;
      var height = 350;
      const editor = new E("#editor1");
      // 关闭粘贴样式的过滤
      editor.config.pasteFilterStyle = false;
      // 自定义处理粘贴的文本内容
      editor.config.pasteTextHandle = function (content) {
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
        if (that.content != content) {
          that.content = content;
          var str = content;
          str = str.replace(/<xml>[\s\S]*?<\/xml>/gi, "");
          str = str.replace(/<style>[\s\S]*?<\/style>/gi, "");
          str = str.replace(/<\/?[^>]*>/g, "");
          str = str.replace(/[ | ]*\n/g, "\n");
          str = str.replace(/&nbsp;/gi, "");
          console.log(str);
          return str;
        }
      };

      editor.config.uploadImgServer = true;
      let client = new OSS({
        // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
        region: "oss-cn-shanghai",
        // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
        accessKeyId: "LTAI5tRozUZ3WynSFfTzC72S",
        accessKeySecret: "HGfPDLd6zylQVVU7c9tkQT7uGKpRd7",
        bucket: "i-vce",
      });

      editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        var name = that.getFileName(resultFiles[0]);
        client
          .put(name, resultFiles[0])
          .then(function (res) {
            // 上传图片，返回结果，将图片插入到编辑器中
            insertImgFn(res.url);
          })
          .catch(function (err) {
            console.log(err);
          });
      };

      editor.config.height = height;
      editor.config.zIndex = 500;
      // 配置行高
      editor.config.lineHeights = [
        "0",
        "0.5",
        "1.0",
        "1.5",
        "2.0",
        "2.5",
        "3.0",
      ];
      // 配置菜单栏，设置不需要的菜单
      editor.config.excludeMenus = [
        "link",
        "list",
        "todo",
        "justify",
        "quote",
        "video",
        "table",
        "code",
        "splitLine",
      ];
      // 取消自动 focus
      // editor.config.focus = false;
      // 配置 onchange 回调函数
      editor.config.onchange = function (newHtml) {
        // var reult = newHtml.charAt(newHtml.length-1)
        that.form.content = newHtml;
      };
      console.log("@", editor.config);
      // 配置触发 onchange 的时间频率，默认为 200ms
      editor.config.onchangeTimeout = 500; // 修改为 500ms
      editor.create();
      editor.txt.html("");
      this.editor = editor;
    },
    // 信息详情
    async getInfoDetail(id) {
      const res = await Service.getInfoDetail({
        id,
      });
      console.log("信息详情", res);
      var data = res.data;

      this.currentTypeName = data.type_text;
      this.form.id = data.id;
      this.form.type = data.type;
      this.form.title = data.title;
      this.form.image = data.image;
      this.form.category_id = data.category_id;
      this.form.relation_category_id = data.relation_category_id;
      this.form.content = data.content;
      this.editor.txt.html(data.content);
      this.form.content_type = data.content_type;
      this.form.is_explain = data.is_explain == 1 ? "是" : "否";
      this.form.explain = data.explain;
      this.form.duo = data.duo;

      this.form.is_tuchu = data.is_tuchu == 1 ? "是" : "否";
      this.form.kanduo = data.kanduo;
      this.form.kankong = data.kankong;
      this.form.zhendang = data.zhendang;
      this.form.direction = data.direction;

      var view_point_json = data.view_point_json;
      console.log("view_point_json", view_point_json);
      for (let item of view_point_json) {
        switch (item.type) {
          case "1":
            item.type = "看多";
            break;
          case "2":
            item.type = "看空";
            break;
          case "3":
            item.type = "震荡";
            break;
        }
      }
      this.form.view_point_json = view_point_json;
      this.form.relation_viewpoint = data.relation_viewpoint == 1 ? "是" : "否";
      this.changeCompanyStatus();
    },
    handleFormShow(e) {
      console.log("e", e);
      this.form = {
        id: null,
        type: "",
        title: "", // 标题
        category_id: null, // 品种
        relation_category_id: [], // 关联品种
        content: "", // 内容
        content_type: 1, // 类型1文字2PDF
        is_explain: "是", // 是否解读
        explain: "", // 解读信息
        duo: 50, // 看多值

        is_tuchu: "否", // 是否突出
        kanduo: 0, // 看多机构数
        kankong: 0, // 看空机构数
        zhendang: 0, // 震荡机构数

        direction: 0, // 0不显示1看多2看空3震荡

        view_point_json: [],
        relation_viewpoint: "是", // 是否同步到V策快讯
      }; // 提交表单信息
      this.currentTypeName = "";
      if (e != undefined) {
        // 修改
        this.formTitle = "修改";
        if (this.editor) {
          this.editor.txt.html("");
        }
        this.getInfoDetail(e.id);
      } else {
        // 添加
        this.formTitle = "添加";
        if (this.editor) {
          this.editor.txt.html("");
        }
        this.changeCompanyStatus();
      }
      this.formShow = true;
      setTimeout(() => {
        if (!this.editor) {
          this.createEditor();
        }
      }, 100);
    },
    handleDelete(index, row) {
      this.$confirm("确认删除该数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.handleDeleteApi(index, row);
      });
    },
    async handleDeleteApi(index, row) {
      const res = await Service.delInfo({
        id: row.id,
      });
      this.$message.success(res.msg);
      this.getList(this.page);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
  },
};
</script>

<style scoped>
.body {
  height: 100vh;
}

.plate_title {
  background-color: #f8fcff;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  padding: 18px 0;
  border-top: 1px solid #ebeced;
  border-bottom: 1px solid #ebeced;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plate_title_name {
  position: relative;
  line-height: 16px;
  padding-left: 30px;
}

.plate_title_name::before {
  content: "";
  position: absolute;
  left: 13px;
  width: 4px;
  height: 16px;
  background-color: #5389f5;
  border-radius: 5px;
}

.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.el-form {
  width: 90%;
}

.icon_del {
  display: block;
  width: 26px;
  height: 26px;
  margin-left: 10px;
}

.avatar-uploader_div {
  width: 148px;
  height: 148px;
  display: block;
  position: relative;
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.zhezhao {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zhezhao:hover {
  opacity: 1;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

/deep/.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  text-align: center;
}
</style>
