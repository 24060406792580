<template>
  <div>
    <pageTitle title="期友社区"></pageTitle>
    <div class="bb p20">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="统计数据" name="fifth">
          <dataStatistical ref="dataStatistical" />
        </el-tab-pane>
        <el-tab-pane label="客服统计数据" name="fourth">
          <statisticalData ref="statisticalData" />
        </el-tab-pane>
        <el-tab-pane label="动态列表" name="first">
          <speechList ref="speechList" />
        </el-tab-pane>
        <el-tab-pane label="投票列表" name="second">
          <voteList ref="voteList" />
        </el-tab-pane>
        <el-tab-pane label="话题列表" name="three">
          <topicList ref="topicList" />
        </el-tab-pane>
        <el-tab-pane label="平台账号" name="sixth">
          <platformAccount ref="platformAccount" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import pageTitle from "@/components/pageTitle.vue";
import speechList from "@/components/qiyouCommunity/speechList.vue";
import voteList from "@/components/qiyouCommunity/voteList.vue";
import topicList from "@/components/qiyouCommunity/topicList.vue";
import statisticalData from "@/components/qiyouCommunity/statisticalData.vue";
import dataStatistical from "@/components/qiyouCommunity/dataStatistical.vue";
import platformAccount from "@/components/qiyouCommunity/platformAccount.vue";
export default {
  components: {
    pageTitle,
    speechList,
    voteList,
    topicList,
    statisticalData,
    dataStatistical,
    platformAccount,
  },
  data() {
    return {
      activeName: "fifth",
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    setTimeout(() => {
      if (this.activeName == "first") {
        this.$refs.speechList.getSpeechList();
      } else if (this.activeName == "second") {
        this.$refs.voteList.getVoteList(1);
      } else if (this.activeName == "three") {
        this.$refs.topicList.getTopicList(1);
      } else if (this.activeName == "fourth") {
        this.$refs.statisticalData.getInfoData();
      } else if (this.activeName == "fifth") {
        this.$refs.dataStatistical.getStatisticsInfo(1);
      } else if (this.activeName == "sixth") {
        this.$refs.platformAccount.getPlatformAccount(1);
      }
    }, 500);
  },
  methods: {
    // tab切换
    handleClick(tab, event) {
      this.page = 1;
      this.total = 0;
      if (this.activeName == "first") {
        this.$refs.speechList.getSpeechList();
      } else if (this.activeName == "second") {
        this.$refs.voteList.getVoteList(1);
      } else if (this.activeName == "three") {
        this.$refs.topicList.getTopicList(1);
      } else if (this.activeName == "fourth") {
        this.$refs.statisticalData.getInfoData();
      } else if (this.activeName == "fifth") {
        this.$refs.dataStatistical.getStatisticsInfo(1);
      } else if (this.activeName == "sixth") {
        this.$refs.platformAccount.getPlatformAccount(1);
      }
    },
    onmessage(e) {
      // console.log('myclient-onmessage',JSON.parse(e.data))
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding: 15px 30px 0;
}
</style>
