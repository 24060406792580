<template>
  <div class="">
    <div class="time">报告时间：{{ dateTime }}</div>
    <div class="text1">
      <div class="">博鑫趋势甄选策略</div>
      <!-- <div class="status1" v-if="formType == 1">开仓信息</div> -->
      <!-- <div class="status2" v-if="formType == 2">平仓信息</div> -->
      <div class="status2">策略信息</div>
    </div>
    <div class="text2">策略ID: {{ form.celue_id }}</div>
    <div class="name">品种：{{ categoryName }}{{ form.contract }}</div>
    <div class="data">
      <div class="data_cell">
        <div>{{ formType == 1 ? "开仓" : "平仓" }}</div>
        <div>策略信息</div>
      </div>
      <div class="data_cell">
        <div v-if="form.direction_type == 1">多</div>
        <div v-if="form.direction_type == 2">空</div>
        <div class="no_data" v-if="form.direction_type == ''">--</div>
        <div>方向</div>
      </div>
      <div class="data_cell">
        <div v-if="form.kaicang1 || form.kaicang2">
          {{ form.kaicang1 }}~{{ form.kaicang2 }}
        </div>
        <div class="no_data" v-if="!form.kaicang1 && !form.kaicang2">--</div>
        <div>入场区间</div>
      </div>
      <div class="data_cell">
        <div v-if="form.zhiying1 || form.zhiying2">
          {{ form.zhiying1 }}~{{ form.zhiying2 }}
        </div>
        <div class="no_data" v-if="!form.zhiying1 && !form.zhiying2">--</div>
        <div>止盈区间</div>
      </div>
      <div class="data_cell">
        <div v-if="form.zhisun1 || form.zhisun2">
          {{ form.zhisun1 }}~{{ form.zhisun2 }}
        </div>
        <div class="no_data" v-if="!form.zhisun1 && !form.zhisun2">--</div>
        <div>止损区间</div>
      </div>
      <div class="data_cell">
        <div v-if="form1.pingcang1 || form1.pingcang2">
          {{ form1.pingcang1 }}~{{ form1.pingcang2 }}
        </div>
        <div class="no_data" v-if="!form1.pingcang1 && !form1.pingcang2">
          --
        </div>
        <div>平仓区间</div>
      </div>
      <div class="data_cell">
        <div v-if="form1.status == 1" style="color: #ff0022">止盈</div>
        <div v-if="form1.status == 2" style="color: #1cb13c">止损</div>
        <div class="no_data" v-if="!form1.status">--</div>
        <div>状态</div>
      </div>
      <div class="data_cell1" v-if="formType == 1">
        备注：{{ form.kaicang_beizhu }}
      </div>
      <div class="data_cell1" v-if="formType == 2">
        备注：{{ form1.pingcang_beizhu }}
      </div>
    </div>
    <div class="teacher_info">
      <div class="mr15">金牌投顾：{{ form.teacher_name }}</div>
      <div>投资咨询证号：{{ form.teacher_number }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categoryName: {
      type: String,
      default: "",
    },
    dateTime: {
      type: String,
      default: "",
    },
    formType: {
      type: Number,
      default: 0,
    },
    form: {
      type: Object,
      default: Object,
    },
    form1: {
      type: Object,
      default: Object,
    },
  },
  data() {},
};
</script>

<style scoped>
.time {
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  position: absolute;
  left: 52px;
  top: 113px;
}

.name {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  width: 100%;
  position: absolute;
  left: 0;
  top: 250px;
  text-align: center;
}

.text1 {
  position: absolute;
  top: 175px;
  left: 75px;
  display: flex;
  align-items: center;
}

.text1 > div:first-child {
  font-size: 28px;
  font-weight: 400;
  color: #2952c1;
}

.text1 > .status1,
.text1 > .status2 {
  display: inline-flex;
  box-sizing: border-box;
  padding: 2px 5px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  margin-left: 10px;
}

.text1 > .status1 {
  background-color: #96b0f6;
}

.text1 > .status2 {
  background-color: #4571e7;
}

.text2 {
  width: 100%;
  position: absolute;
  top: 216px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #4571e7;
}

.teacher_info {
  width: 320px;
  height: 35px;
  border-radius: 18px;
  border: 1px solid #4571e7;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  color: #4a87fd;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 61px;
  bottom: 160px;
  text-align: center;
}

.data {
  position: absolute;
  top: 300px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.data_cell {
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 25px;
}

.data_cell > div:first-child {
  font-size: 20px;
  font-weight: bold;
  color: #4571e7;
  margin-bottom: 5px;
}

.data_cell > div:last-child {
  font-size: 18px;
  font-weight: bold;
  color: #101010;
}

.data_cell1 {
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  color: #101010;
  box-sizing: border-box;
  padding: 0 57px;
  word-break: break-all;
}
</style>
