const companyList = [
  {
    id: 1,
    name: "首创期货",
    area: "北京",
  },
  {
    id: 2,
    name: "第一创业期货",
    area: "北京",
  },
  {
    id: 3,
    name: "方正中期期货",
    area: "北京",
  },
  {
    id: 4,
    name: "格林大华期货",
    area: "北京",
  },
  {
    id: 5,
    name: "冠通期货",
    area: "北京",
  },
  {
    id: 6,
    name: "国都期货",
    area: "北京",
  },
  {
    id: 7,
    name: "国元期货",
    area: "北京",
  },
  {
    id: 8,
    name: "宏源期货",
    area: "北京",
  },
  {
    id: 9,
    name: "金鹏期货",
    area: "北京",
  },
  {
    id: 10,
    name: "九州期货",
    area: "北京",
  },
  {
    id: 11,
    name: "民生期货",
    area: "北京",
  },
  {
    id: 12,
    name: "首创京都期货",
    area: "北京",
  },
  {
    id: 13,
    name: "银河期货",
    area: "北京",
  },
  {
    id: 14,
    name: "英大期货",
    area: "北京",
  },
  {
    id: 15,
    name: "中钢期货",
    area: "北京",
  },
  {
    id: 16,
    name: "中国国际期货",
    area: "北京",
  },
  {
    id: 17,
    name: "中粮期货",
    area: "北京",
  },
  {
    id: 18,
    name: "中天期货",
    area: "北京",
  },
  {
    id: 19,
    name: "中衍期货",
    area: "北京",
  },
  {
    id: 20,
    name: "晟鑫期货",
    area: "北京",
  },
  {
    id: 21,
    name: "财达期货",
    area: "天津",
  },
  {
    id: 22,
    name: "和融期货",
    area: "天津",
  },
  {
    id: 23,
    name: "华金期货",
    area: "天津",
  },
  {
    id: 24,
    name: "津投期货",
    area: "天津",
  },
  {
    id: 25,
    name: "山金期货",
    area: "天津",
  },
  {
    id: 26,
    name: "一德期货",
    area: "天津",
  },
  {
    id: 27,
    name: "恒银期货",
    area: "河北",
  },
  {
    id: 28,
    name: "和合期货",
    area: "山西",
  },
  {
    id: 29,
    name: "三立期货",
    area: "山西",
  },
  {
    id: 30,
    name: "东方汇金期货",
    area: "吉林",
  },
  {
    id: 31,
    name: "天富期货",
    area: "吉林",
  },
  {
    id: 32,
    name: "大通期货",
    area: "黑龙江",
  },
  {
    id: 33,
    name: "永商期货",
    area: "黑龙江",
  },
  {
    id: 34,
    name: "渤海期货",
    area: "上海",
  },
  {
    id: 35,
    name: "东航期货",
    area: "上海",
  },
  {
    id: 36,
    name: "东吴期货",
    area: "上海",
  },
  {
    id: 37,
    name: "东兴期货",
    area: "上海",
  },
  {
    id: 38,
    name: "光大期货",
    area: "上海",
  },
  {
    id: 39,
    name: "国富期货",
    area: "上海",
  },
  {
    id: 40,
    name: "国泰君安期货",
    area: "上海",
  },
  {
    id: 41,
    name: "国投安信期货",
    area: "上海",
  },
  {
    id: 42,
    name: "国信期货",
    area: "上海",
  },
  {
    id: 43,
    name: "海通期货",
    area: "上海",
  },
  {
    id: 44,
    name: "海证期货",
    area: "上海",
  },
  {
    id: 45,
    name: "恒泰期货",
    area: "上海",
  },
  {
    id: 46,
    name: "华闻期货",
    area: "上海",
  },
  {
    id: 47,
    name: "华鑫期货",
    area: "上海",
  },
  {
    id: 48,
    name: "建信期货",
    area: "上海",
  },
  {
    id: 49,
    name: "瑞银期货",
    area: "上海",
  },
  {
    id: 50,
    name: "大陆期货",
    area: "上海",
  },
  {
    id: 51,
    name: "东方期货",
    area: "上海",
  },
  {
    id: 52,
    name: "东亚期货",
    area: "上海",
  },
  {
    id: 53,
    name: "东证期货",
    area: "上海",
  },
  {
    id: 54,
    name: "浙石期货",
    area: "上海",
  },
  {
    id: 55,
    name: "中财期货",
    area: "上海",
  },
  {
    id: 56,
    name: "上海中期期货",
    area: "上海",
  },
  {
    id: 57,
    name: "申银万国期货",
    area: "上海",
  },
  {
    id: 58,
    name: "紫金天风期货",
    area: "上海",
  },
  {
    id: 59,
    name: "天鸿期货",
    area: "上海",
  },
  {
    id: 60,
    name: "通惠期货",
    area: "上海",
  },
  {
    id: 61,
    name: "东方财富期货",
    area: "上海",
  },
  {
    id: 62,
    name: "铜冠金源期货",
    area: "上海",
  },
  {
    id: 63,
    name: "新湖期货",
    area: "上海",
  },
  {
    id: 64,
    name: "中辉期货",
    area: "上海",
  },
  {
    id: 65,
    name: "中融汇信期货",
    area: "上海",
  },
  {
    id: 66,
    name: "国盛期货",
    area: "上海",
  },
  {
    id: 67,
    name: "中银国际期货",
    area: "上海",
  },
  {
    id: 68,
    name: "恒力期货",
    area: "上海",
  },
  {
    id: 69,
    name: "金信期货",
    area: "上海",
  },
  {
    id: 70,
    name: "创元期货",
    area: "江苏",
  },
  {
    id: 71,
    name: "道通期货",
    area: "江苏",
  },
  {
    id: 72,
    name: "东海期货",
    area: "江苏",
  },
  {
    id: 73,
    name: "国联期货",
    area: "江苏",
  },
  {
    id: 74,
    name: "弘业期货",
    area: "江苏",
  },
  {
    id: 75,
    name: "东华期货",
    area: "江苏",
  },
  {
    id: 76,
    name: "锦泰期货",
    area: "江苏",
  },
  {
    id: 77,
    name: "宁证期货",
    area: "江苏",
  },
  {
    id: 78,
    name: "新纪元期货",
    area: "江苏",
  },
  {
    id: 79,
    name: "宝城期货",
    area: "浙江",
  },
  {
    id: 80,
    name: "大地期货",
    area: "浙江",
  },
  {
    id: 81,
    name: "大越期货",
    area: "浙江",
  },
  {
    id: 82,
    name: "国海良时期货",
    area: "浙江",
  },
  {
    id: 83,
    name: "南华期货",
    area: "浙江",
  },
  {
    id: 84,
    name: "盛达期货",
    area: "浙江",
  },
  {
    id: 85,
    name: "信达期货",
    area: "浙江",
  },
  {
    id: 86,
    name: "永安期货",
    area: "浙江",
  },
  {
    id: 87,
    name: "新世纪期货",
    area: "浙江",
  },
  {
    id: 88,
    name: "浙商期货",
    area: "浙江",
  },
  {
    id: 89,
    name: "物产中大期货",
    area: "浙江",
  },
  {
    id: 90,
    name: "安粮期货",
    area: "安徽",
  },
  {
    id: 91,
    name: "华安期货",
    area: "安徽",
  },
  {
    id: 92,
    name: "徽商期货",
    area: "安徽",
  },
  {
    id: 93,
    name: "福能期货",
    area: "福建",
  },
  {
    id: 94,
    name: "鑫鼎盛期货",
    area: "福建",
  },
  {
    id: 95,
    name: "兴证期货",
    area: "福建",
  },
  {
    id: 96,
    name: "瑞奇期货",
    area: "江西",
  },
  {
    id: 97,
    name: "中泰期货",
    area: "山东",
  },
  {
    id: 98,
    name: "齐盛期货",
    area: "山东",
  },
  {
    id: 99,
    name: "江海汇鑫期货",
    area: "山东",
  },
  {
    id: 100,
    name: "华融融达期货",
    area: "河南",
  },
  {
    id: 101,
    name: "中原期货",
    area: "河南",
  },
  {
    id: 102,
    name: "长江期货",
    area: "湖北",
  },
  {
    id: 103,
    name: "正信期货",
    area: "湖北",
  },
  {
    id: 104,
    name: "大有期货",
    area: "湖南",
  },
  {
    id: 105,
    name: "财信期货",
    area: "湖南",
  },
  {
    id: 106,
    name: "广发期货",
    area: "广东",
  },
  {
    id: 107,
    name: "金控期货",
    area: "广东",
  },
  {
    id: 108,
    name: "广州期货",
    area: "广东",
  },
  {
    id: 109,
    name: "华联期货",
    area: "广东",
  },
  {
    id: 110,
    name: "华泰期货",
    area: "广东",
  },
  {
    id: 111,
    name: "长城期货",
    area: "广东",
  },
  {
    id: 112,
    name: "摩根大通期货",
    area: "广东",
  },
  {
    id: 113,
    name: "金控期货",
    area: "广东",
  },
  {
    id: 114,
    name: "中州期货",
    area: "广东",
  },
  {
    id: 115,
    name: "国新国证期货",
    area: "海南",
  },
  {
    id: 116,
    name: "金元期货",
    area: "海南",
  },
  {
    id: 117,
    name: "华创期货",
    area: "重庆",
  },
  {
    id: 118,
    name: "西南期货",
    area: "重庆",
  },
  {
    id: 119,
    name: "中电投先融期货",
    area: "重庆",
  },
  {
    id: 120,
    name: "中信建投期货",
    area: "重庆",
  },
  {
    id: 121,
    name: "倍特期货",
    area: "四川",
  },
  {
    id: 122,
    name: "国金期货",
    area: "四川",
  },
  {
    id: 123,
    name: "华西期货",
    area: "四川",
  },
  {
    id: 124,
    name: "红塔期货",
    area: "云南",
  },
  {
    id: 125,
    name: "云晨期货",
    area: "云南",
  },
  {
    id: 126,
    name: "长安期货",
    area: "陕西",
  },
  {
    id: 127,
    name: "迈科期货",
    area: "陕西",
  },
  {
    id: 128,
    name: "西部期货",
    area: "陕西",
  },
  {
    id: 129,
    name: "华龙期货",
    area: "甘肃",
  },
  {
    id: 130,
    name: "中金期货",
    area: "青海",
  },
  {
    id: 131,
    name: "金石期货",
    area: "新疆",
  },
  {
    id: 132,
    name: "云财富期货",
    area: "新疆",
  },
  {
    id: 133,
    name: "海航期货",
    area: "深圳",
  },
  {
    id: 134,
    name: "混沌天成期货",
    area: "深圳",
  },
  {
    id: 135,
    name: "金瑞期货",
    area: "深圳",
  },
  {
    id: 136,
    name: "平安期货",
    area: "深圳",
  },
  {
    id: 137,
    name: "乾坤期货",
    area: "深圳",
  },
  {
    id: 138,
    name: "中金岭南期货",
    area: "深圳",
  },
  {
    id: 139,
    name: "先锋期货",
    area: "深圳",
  },
  {
    id: 140,
    name: "神华期货",
    area: "深圳",
  },
  {
    id: 141,
    name: "五矿期货",
    area: "深圳",
  },
  {
    id: 142,
    name: "招商期货",
    area: "深圳",
  },
  {
    id: 143,
    name: "中航期货",
    area: "深圳",
  },
  {
    id: 144,
    name: "中金财富期货",
    area: "深圳",
  },
  {
    id: 145,
    name: "前海期货",
    area: "深圳",
  },
  {
    id: 146,
    name: "中信期货",
    area: "深圳",
  },
  {
    id: 147,
    name: "兴业期货",
    area: "宁波",
  },
  {
    id: 148,
    name: "国贸期货",
    area: "厦门",
  },
  {
    id: 149,
    name: "瑞达期货",
    area: "厦门",
  },
  {
    id: 150,
    name: "港信期货",
    area: "青岛",
  },
];
export default companyList;
