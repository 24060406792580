<template>
  <div class="bb p30">
    <div class="head flex fw_w">
      <div>
        <div>关键词</div>
        <div class="w300">
          <el-input
            clearable
            size="large"
            v-model="search"
            placeholder="请输入昵称/手机号/真实姓名"
          ></el-input>
        </div>
      </div>
      <div>
        <div>登入时间</div>
        <div>
          <el-date-picker
            format="YYYY-MM-DD"
            value-format="x"
            size="large"
            v-model="time_range"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </div>
      <div>
        <div>所属</div>
        <div>
          <el-radio-group v-model="belong" size="large">
            <el-radio-button label="全部" />
            <el-radio-button label="自己客户" />
            <el-radio-button label="他人客户" />
          </el-radio-group>
        </div>
      </div>
      <div>
        <div>是否实盘</div>
        <div>
          <el-select
            v-model="is_firm_offer"
            class="m-2"
            placeholder=""
            size="large"
          >
            <el-option label="全部" :value="0" />
            <el-option label="实盘" :value="1" />
            <el-option label="非实盘" :value="2" />
          </el-select>
        </div>
      </div>
      <div>
        <div>排序（最后圈子登入时间）</div>
        <div>
          <el-radio-group v-model="order" size="large">
            <el-radio-button label="降序" />
            <el-radio-button label="升序" />
          </el-radio-group>
        </div>
      </div>
      <div>
        <el-button @click="getUserList(1)" type="primary">搜 索</el-button>
        <el-button @click="exportExcel" type="primary">导 出</el-button>
      </div>
    </div>
    <div v-loading="userListLoading">
      <div class="mb20">
        <el-table :data="userList" height="60vh" style="width: 100%">
          <el-table-column
            show-overflow-tooltip
            label="昵称"
            width="120"
            prop="user.nickname"
          >
          </el-table-column>
          <el-table-column label="手机号" width="120" prop="user.mobile">
          </el-table-column>
          <el-table-column label="加入圈子时间" width="170" prop="createtime">
          </el-table-column>
          <el-table-column label="登入时间" width="170" prop="user.logintime">
          </el-table-column>
          <el-table-column
            label="最后圈子登入时间"
            width="170"
            prop="circle_time"
          >
          </el-table-column>
          <el-table-column label="最新一次入圈天数" width="140">
            <template #default="scope">
              {{ scope.row.circle_day }}
            </template>
          </el-table-column>
          <el-table-column label="研究员" width="140" v-if="showMode == 1">
            <template #default="scope">
              {{ scope.row.teacher.id ? scope.row.teacher.teacher_name : "-" }}
            </template>
          </el-table-column>
          <el-table-column label="实盘信息" width="130">
            <template #default="scope">
              {{ scope.row.offerInfo.info.name }}<br />{{
                scope.row.offerInfo.info.phone
              }}
            </template>
          </el-table-column>
          <el-table-column label="期末权益" width="130">
            <template #default="scope">
              <div
                v-for="(item, index) in scope.row.offerInfo.funds"
                :key="index"
              >
                <div>天数：{{ item.dormancy_days }}</div>
                <div>权益：{{ item.last_money }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="备注" width="330">
            <template #default="scope">
              <div>
                <div>
                  <div v-if="!scope.row.isEdit" class="teacher_remark hang1">
                    {{ scope.row.user.teacher_remark }}
                  </div>
                  <div v-if="scope.row.isEdit">
                    <el-input
                      v-model="scope.row.user.teacher_remark"
                      placeholder="请输入备注"
                    ></el-input>
                  </div>
                  <div class="can">
                    <el-button
                      @click="handleShowEdit(scope.$index)"
                      v-if="!scope.row.isEdit"
                      type="primary"
                      size="small"
                      icon="Edit"
                      >编辑</el-button
                    >
                    <el-button
                      @click="handleEdit(scope.$index)"
                      v-if="scope.row.isEdit"
                      type="success"
                      size="small"
                      icon="Check"
                      >保存</el-button
                    >
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          v-model:page-size="pageSize"
          layout="total, prev, pager, next, sizes, jumper"
          :total="total"
          :page-sizes="[15, 50, 100, 200]"
          @size-change="handleSizeChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as Service from "../api/circleManage";
import * as ServiceC from "../api/chat";
export default {
  data() {
    return {
      userListLoading: false,
      userList: [],
      pageSize: 15,
      page: 1,
      total: 0,
      search: "", // 关键词
      belong: "全部", // 0全部1自己客户2他人客户
      time_range: [], // 登入时间范围
      userInfo: [],
      is_firm_offer: 1, // 0全部1实盘2非实盘
      order: "降序",
      showMode: 0, // 1V策财经2正信
    };
  },
  mounted() {
    this.showMode = localStorage.getItem("is_company") == 1 ? 2 : 1;
    this.getTeacherInfo();
  },
  methods: {
    handleShowEdit(index) {
      this.userList[index].isEdit = true;
    },
    handleEdit(index) {
      this.editUserInfo(
        this.userList[index].user_id,
        this.userList[index].user.teacher_remark
      );
      this.userList[index].isEdit = false;
    },
    // 修改会员个人信息
    async editUserInfo(id, remark) {
      const res = await Service.editUserInfo({
        id,
        teacher_remark: remark,
      });
      this.$message.success(res.msg);
      // this.getUserList(this.page);
    },
    // 导出表格
    exportExcel() {
      var userList = this.userList;
      let headerList = [
        "昵称",
        "手机号",
        "加入圈子时间",
        "登入时间",
        "最后圈子登入时间",
        "最新一次入圈天数",
        "研究员",
        "实盘信息",
        "期末权益",
        "备注",
      ];
      // let bodyList = [
      //   ["新狗", "18", "1993"],
      //   ["蜡笔", "22", "2000"],
      // ];
      let bodyList = [];
      for (const item of userList) {
        var str = "";
        for (const items of item.offerInfo.funds) {
          str +=
            "天数：" +
            items.dormancy_days +
            "-" +
            "权益：" +
            items.last_money +
            ";";
        }
        bodyList.push([
          item.user.nickname,
          item.user.mobile,
          item.createtime,
          item.user.logintime,
          item.circle_time,
          item.circle_day,
          item.teacher.id ? item.teacher.teacher_name : "-",
          item.offerInfo.info.name + "-" + item.offerInfo.info.phone,
          str,
        ]);
        // "天数：" + item.dormancy_days + "-" + "权益：" + item.last_money,
      }
      let excelList = [];
      excelList.push(headerList.join("\t,") + "\n");
      bodyList.forEach((item) => {
        excelList.push(item.join("\t,") + "\n");
      });
      var merged = excelList.join("");
      const uri =
        "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(merged);
      let link = document.createElement("a");
      link.href = uri;
      var time = Date.now();
      link.download = `成员列表-${this.formatTime(time / 1000, "Y-M-D")}.csv`;
      link.click();
    },
    // 获取用户信息
    async getTeacherInfo() {
      const res = await ServiceC.getTeacherInfo();
      // console.log("获取研究员信息A", res);
      this.userInfo = res.data.userinfo;
    },
    // 获取圈子人员
    async getUserList(page) {
      this.userListLoading = true;
      var belongVal = null;
      switch (this.belong) {
        case "全部":
          belongVal = 0;
          break;
        case "自己客户":
          belongVal = 1;
          break;
        case "他人客户":
          belongVal = 2;
          break;
      }
      var time_range0 = this.time_range ? [...this.time_range] : [];
      if (time_range0.length) {
        time_range0[0] = time_range0[0] / 1000;
        time_range0[1] = time_range0[1] / 1000;
      }
      console.log("@", time_range0);
      const res = await Service.getCircleUserList({
        page,
        limit: this.pageSize,
        search: this.search,
        is_teacher: 1,
        belong: belongVal,
        login_time_range: time_range0,
        uid: this.userInfo.id,
        search_offer: 1,
        is_firm_offer: this.is_firm_offer,
        order_field: "circle_time",
        order: this.order == "降序" ? "desc" : "asc",
      });
      this.page = page;
      this.total = res.data.total;
      var userList = res.data.data;
      for (const item of userList) {
        item.isEdit = false;
      }
      this.userList = userList;
      console.warn("userList", this.userList);
      this.userListLoading = false;
    },
    handleCurrentChange(val) {
      this.getUserList(val);
    },
    handleSizeChange(val) {
      this.getUserList(1);
    },
    formatNumber(n) {
      n = n.toString();
      return n[1] ? n : "0" + n;
    },
    formatTime(number, format) {
      const formateArr = ["Y", "M", "D", "h", "m", "s"];
      const returnArr = [];

      const date = new Date(number * 1000);
      returnArr.push(date.getFullYear());
      returnArr.push(this.formatNumber(date.getMonth() + 1));
      returnArr.push(this.formatNumber(date.getDate()));

      returnArr.push(this.formatNumber(date.getHours()));
      returnArr.push(this.formatNumber(date.getMinutes()));
      returnArr.push(this.formatNumber(date.getSeconds()));

      for (const i in returnArr) {
        format = format.replace(formateArr[i], returnArr[i]);
      }
      return format;
    },
  },
};
</script>

<style scoped>
.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.teacher_remark {
  max-width: 300px;
  display: inherit !important;
}
</style>
