<template>
  <div class="body">
    <div class="plate_title">
      <div class="plate_title_name">看数据</div>
    </div>
    <div class="flex" style="height: calc(100% - 54px)">
      <div class="left_cell">
        <el-button
          :loading="loading1"
          class="mt10 mb10"
          @click="doCut1"
          type="primary"
          >生成基差一览</el-button
        >
        <div class="poster1 pr flex fd_c at_c" id="capture1">
          <!-- <div class="poster_header">
            <img class="logo_white" src="@/assets/img/logo_white.png" alt="" />
            <div class="name">基差一览</div>
          </div> -->
          <img class="w100 flex" src="@/assets/img/head_jicha.png" alt="" />
          <div class="jicha_tabel">
            <div class="jicha_tabel_title tac">
              {{ jichaTitle }}
            </div>
            <div class="jicha_tabel_head">
              <div class="w1 tac">商品</div>
              <div class="w2 tac">现货价</div>
              <div class="w3 tac">合约</div>
              <div class="w4 tac">期货价</div>
              <div class="w5 tac">基差</div>
              <div class="w6 tac">基差率</div>
            </div>
            <div class="jicha_tabel_body canvas_bg">
              <div
                v-for="(item, index) in list1"
                :key="index"
                class="jicha_tabel_tr"
              >
                <div class="w1 tac">
                  {{ item.name }}
                </div>
                <div class="w2 tac">
                  {{ item.xianhuojia }}
                </div>
                <div class="w3 tac">
                  {{ item.heyue }}
                </div>
                <div class="w4 tac">
                  {{ item.qihuojia }}
                </div>
                <div
                  class="w5 tac"
                  :class="{ green: item.jicha < 0, red: item.jicha > 0 }"
                >
                  {{ item.jicha }}
                </div>
                <div
                  class="w6 tac"
                  :class="{ green: item.jichalv < 0, red: item.jichalv > 0 }"
                >
                  {{ item.jichalv }}%
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="qrcode_bottom">
            <div class="qrcode_cell">
              <img src="@/assets/img/qrcode/xcx.png" alt="" />
              <div>V策财经小程序</div>
            </div>
            <div class="qrcode_cell">
              <img src="@/assets/img/qrcode/android_new.png" alt="" />
              <div>Android下载</div>
            </div>
            <div class="qrcode_cell">
              <img src="@/assets/img/qrcode/ios.png" alt="" />
              <div>IOS下载</div>
            </div>
          </div> -->
          <img class="w100 flex" src="@/assets/img/foot_jicha.png" alt="" />
        </div>
      </div>
      <div class="right_cell">
        <el-button
          :loading="loading2"
          class="mt10 mb10"
          @click="doCut2"
          type="primary"
          >生成投机排行榜</el-button
        >
        <div class="poster2 pr flex fd_c at_c" id="capture2">
          <img class="w100 flex" src="@/assets/img/head_touji.png" alt="" />
          <!-- <div class="poster_header">
            <img class="logo_white" src="@/assets/img/logo_white.png" alt="" />
            <div class="name">投机排行榜</div>
          </div> -->
          <div class="rank_body">
            <div class="time">
              {{ time }}
            </div>
            <div class="plate_title1">
              <span style="color: #191e4e">期货合约投机情绪</span>排行榜
            </div>
            <div class="TOP_cell">
              <img
                class="img_line_left"
                src="@/assets/img/line_left.png"
                alt=""
              />
              <div>TOP 10</div>
              <img
                class="img_line_right"
                src="@/assets/img/line_right.png"
                alt=""
              />
            </div>
            <div class="ranking_block">
              <div class="ranking_cell">
                <div class="text1">TOP2</div>
                <div class="text2">{{ list2[1].name }}</div>
                <div class="text3">
                  {{ list2[1].touji }}
                </div>
                <div class="text4">
                  成交量：<span>{{ list2[1].chengjiao }}</span>
                </div>
                <div class="text4">
                  持仓量：<span>{{ list2[1].chicang }}</span>
                </div>
              </div>
              <div class="ranking_cell ranking_cell_special">
                <img
                  class="icon_crown"
                  src="@/assets/img/icon_crown.png"
                  alt=""
                />
                <div class="text1">TOP1</div>
                <div class="text2">{{ list2[0].name }}</div>
                <div class="text3">
                  {{ list2[0].touji }}
                </div>
                <div class="text4">
                  成交量：<span>{{ list2[0].chengjiao }}</span>
                </div>
                <div class="text4">
                  持仓量：<span>{{ list2[0].chicang }}</span>
                </div>
              </div>
              <div class="ranking_cell">
                <div class="text1">TOP3</div>
                <div class="text2">{{ list2[2].name }}</div>
                <div class="text3">
                  {{ list2[2].touji }}
                </div>
                <div class="text4">
                  成交量：<span>{{ list2[2].chengjiao }}</span>
                </div>
                <div class="text4">
                  持仓量：<span>{{ list2[2].chicang }}</span>
                </div>
              </div>
            </div>
            <div class="ranking_line">
              <div class="table_head">
                <div class="w1 tac">排名</div>
                <div class="w2 tac">品种</div>
                <div class="w3 tac">投机度</div>
                <div class="w4 tac">成交量</div>
                <div class="w5 tac">持仓量</div>
              </div>
              <div class="table_body">
                <div
                  v-for="(item, index) in list2.slice(3, 10)"
                  :key="index"
                  class="table_tr"
                >
                  <div class="w1 tac">
                    {{ index + 4 > 9 ? index + 4 : "0" + (index + 4) }}
                  </div>
                  <div class="w2 tac">{{ item.name }}</div>
                  <div class="w3 flex jc_c">
                    <span class="touji_label">{{ item.touji }}</span>
                  </div>
                  <div class="w4 tac">
                    {{ item.chengjiao }}
                  </div>
                  <div class="w5 tac">
                    {{ item.chicang }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="qrcode_bottom">
            <div class="qrcode_cell">
              <img src="@/assets/img/qrcode/xcx.png" alt="" />
              <div>V策财经小程序</div>
            </div>
            <div class="qrcode_cell">
              <img src="@/assets/img/qrcode/android_new.png" alt="" />
              <div>Android下载</div>
            </div>
            <div class="qrcode_cell">
              <img src="@/assets/img/qrcode/ios.png" alt="" />
              <div>IOS下载</div>
            </div>
          </div> -->
          <img class="w100 flex" src="@/assets/img/foot_touji.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Service from "@/api/viewingData";
import html2canvas from "html2canvas";
export default {
  components: {
    html2canvas,
  },
  data() {
    return {
      loading1: false,
      loading2: false,
      jichaTitle: "",
      list1: [],
      list2: [
        {
          name: "",
          touji: null,
          chengjiao: null,
          chicang: null,
        },
        {
          name: "",
          touji: null,
          chengjiao: null,
          chicang: null,
        },
        {
          name: "",
          touji: null,
          chengjiao: null,
          chicang: null,
        },
      ],
      time: "",
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.getJiCha();
    this.getTouJi();
  },
  methods: {
    onmessage() {},
    // 获取分类列表
    async getJiCha() {
      const res = await Service.jiCha();
      this.jichaTitle = res.data.date;
      this.list1 = res.data.list;
    },
    // 投机排行榜
    async getTouJi() {
      const res = await Service.touJiJava();
      var list = res.data.list;
      this.time = res.data.date;
      for (const item of list) {
        item.name += item.symbol;
        item.chengjiao = (item.chengjiao / 10000).toFixed(2) + "万";
        item.chicang = (item.chicang / 10000).toFixed(2) + "万";
      }
      if (list.length) {
        this.list2 = list;
      }
    },
    doCut1() {
      this.loading1 = true;
      html2canvas(document.querySelector("#capture1")).then((canvas) => {
        var imgUrl = canvas.toDataURL("image/png");
        console.log("imgUrl", imgUrl);
        setTimeout(() => {
          this.loading1 = false;
        }, 1500);
        this.submit1(imgUrl);
      });
    },
    async submit1(imgUrl) {
      const res = await Service.uploadBase64({
        pic: imgUrl,
      });
      this.pushViewPoint(11, this.jichaTitle, res.data.url);
    },
    doCut2() {
      this.loading2 = true;
      html2canvas(document.querySelector("#capture2")).then((canvas) => {
        var imgUrl = canvas.toDataURL("image/png");
        console.log("imgUrl", imgUrl);
        setTimeout(() => {
          this.loading2 = false;
        }, 1500);
        this.submit2(imgUrl);
      });
    },
    async submit2(imgUrl) {
      const res = await Service.uploadBase64({
        pic: imgUrl,
      });
      this.pushViewPoint(13, this.time, res.data.url);
    },
    async pushViewPoint(type, date, images) {
      const res = await Service.pushViewPoint({
        type,
        date,
        images,
      });
      this.$message.success(res.msg);
    },
  },
};
</script>

<style scoped>
.body {
  height: 100vh;
  overflow-y: hidden;
}

.plate_title {
  background-color: #f8fcff;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  padding: 18px 0;
  border-top: 1px solid #ebeced;
  border-bottom: 1px solid #ebeced;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plate_title_name {
  position: relative;
  line-height: 16px;
  padding-left: 30px;
}

.plate_title_name::before {
  content: "";
  position: absolute;
  left: 13px;
  width: 4px;
  height: 16px;
  background-color: #5389f5;
  border-radius: 5px;
}

.left_cell {
  position: relative;
  width: calc(50% - 60px);
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
}

.right_cell {
  position: relative;
  width: calc(50% - 60px);
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background-image: url(../assets/img/bg_img1.png); */
  background-position: 0 0;
  background-size: 750px;
  background-repeat: no-repeat;
  overflow-y: auto;
}

.poster1 {
  width: 650px;
  background: #2c77ec;
}

.poster2 {
  width: 650px;
  background: linear-gradient(to bottom, #e3f3ff, #bceafe);
}

.poster_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 0 50px;
}

.viewingData_bg {
  position: absolute;
  width: 100%;
}

.logo_white {
  width: 143px;
  display: block;
}

.name {
  font-size: 28px;
  color: #fff;
  font-weight: bold;
}

.jicha_tabel {
  width: calc(100% - 98px);
  margin-top: 15px;
  z-index: 1;
  box-sizing: border-box;
  border: 1px solid #9cc5ff;
}

.jicha_tabel .w1 {
  width: 18%;
}

.jicha_tabel .w2 {
  width: 18%;
}

.jicha_tabel .w3 {
  width: 18%;
}

.jicha_tabel .w4 {
  width: 18%;
}

.jicha_tabel .w5 {
  width: 14%;
}

.jicha_tabel .w6 {
  width: 14%;
}

.jicha_tabel_title {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  font-weight: bold;
  color: #0a319a;
  box-sizing: border-box;
  border-bottom: 1px solid #9cc5ff;
  background: #f9fdff;
}

.jicha_tabel_head {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #9cc5ff;
}

.jicha_tabel_head > div {
  font-size: 16px;
  font-weight: bold;
  color: #191919;
  background: #e4edfa;
  height: 40px;
  line-height: 40px;
}

.jicha_tabel_head > div:not(:last-child) {
  box-sizing: border-box;
  border-right: 1px solid #9cc5ff;
}

.jicha_tabel_body {
}

.jicha_tabel_tr {
  display: flex;
  align-items: center;
  color: #191919;
}

.jicha_tabel_tr:not(:last-child) {
  box-sizing: border-box;
  border-bottom: 1px solid #9cc5ff;
}

.jicha_tabel_tr > div {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 400;
  background: #f9fdff;
}

/* .jicha_tabel_tr:hover {
  background: #4571e7;
  color: #fff !important;
} */

.jicha_tabel_tr > div:not(:last-child) {
  box-sizing: border-box;
  border-right: 1px solid #9cc5ff;
}

.jicha_tabel_tr .green {
  color: #2aa44e;
  font-weight: bold;
}

.jicha_tabel_tr .red {
  color: #ff0000;
  font-weight: bold;
}

.canvas_bg {
  background: url("data:image/svg+xml,%3Csvg width='150' height='150' xmlns='http://www.w3.org/2000/svg'%3E%3Ctext x='50%25' y='50%25' font-size='14' fill='%23E2E2E2'  fill-opacity='1' text-anchor='middle' dominant-baseline='middle' transform='rotate(-45, 0 100)'%3EV策财经%3C/text%3E%3C/svg%3E");
}

.rank_body {
  width: calc(100% - 98px);
  margin-top: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
}

.time {
  border-radius: 19px;
  border: 1px solid #4471e7;
  font-size: 16px;
  font-weight: 400;
  color: #4471e7;
  box-sizing: border-box;
  padding: 6px 20px;
  display: inline-block;
  margin: 10px 0 20px;
}

.plate_title1 {
  font-size: 38px;
  font-weight: 400;
  color: #4571e7;
  margin-bottom: 15px;
}

.TOP_cell {
  font-size: 26px;
  font-weight: bold;
  color: #4571e7;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.img_line_left,
.img_line_right {
  width: 200px;
  height: 4px;
  display: block;
}

.img_line_left {
  margin-right: 15px;
}

.img_line_right {
  margin-left: 15px;
}

.ranking_block {
  display: flex;
  align-items: flex-end;
}

.ranking_cell {
  width: 155px;
  height: 180px;
  background: #e9efff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.ranking_cell .text1 {
  font-size: 20px;
  font-weight: bold;
  color: #4471e7;
}

.ranking_cell .text2 {
  font-size: 20px;
  font-weight: bold;
  color: #191e4e;
  margin-top: 10px;
  word-break: break-all;
  text-align: center;
}

.ranking_cell .text3 {
  width: 96px;
  height: 27px;
  background: #4471e7;
  border-radius: 17px 17px 17px 17px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}

.ranking_cell .text4 {
  font-size: 14px;
  color: #2d2d2d;
}

.ranking_cell .text4 span {
  font-size: 16px;
  font-weight: bold;
}

.ranking_cell_special {
  /* zoom: 1.2; */
  width: calc(155px * 1.2);
  height: calc(180px * 1.2);
  margin: 0 15px;
  position: relative;
}

.ranking_cell_special .text1 {
  font-size: 24px;
}

.ranking_cell_special .text2 {
  font-size: 24px;
}

.ranking_cell_special .text3 {
  font-size: 22px;
}

.ranking_cell_special .text4 {
  font-size: 16px;
}

.ranking_cell_special .text4 span {
  font-size: 20px;
}

.icon_crown {
  width: 32px;
  display: block;
  position: absolute;
  top: -26px;
}

.ranking_line {
  width: 95%;
  margin: 40px auto 0;
}

.table_head {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #a8a8a8;
}

.ranking_line .w1 {
  width: 8%;
}

.ranking_line .w2 {
  width: 27%;
}

.ranking_line .w3 {
  width: 25%;
}

.ranking_line .w4 {
  width: 20%;
}

.ranking_line .w5 {
  width: 20%;
}

.table_body {
  margin-top: 15px;
}

.table_body .w1 {
  font-size: 18px;
  font-weight: bold;
  color: #4571e7;
}

.table_body .w2 {
  font-size: 18px;
  font-weight: bold;
  color: #4d4d4d;
}

.table_body .w3 {
}

.table_body .w4 {
  font-size: 18px;
  font-weight: 400;
  color: #585858;
}

.table_body .w5 {
  font-size: 18px;
  font-weight: 400;
  color: #585858;
}

.table_tr {
  display: flex;
  padding: 18px 0;
  border-bottom: 1px solid #dedede;
}

.table_tr:hover > div {
  color: #4471e7 !important;
}

.touji_label {
  width: 96px;
  height: 27px;
  background: #4471e7;
  border-radius: 17px 17px 17px 17px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qrcode_bottom {
  width: calc(100% - 98px);
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 20px 0;
}

.qrcode_cell {
  display: flex;
  align-items: center;
}

.qrcode_cell img {
  width: 90px;
  height: 90px;
  display: block;
  margin-right: 10px;
}

.qrcode_cell div {
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
}
</style>
