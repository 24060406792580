<template>
  <div>
    <div class="flex at_c mb10">
      <div class="flex at_c mr15">
        <div class="mr10 fz14">话题</div>
        <el-input
          style="width: 200px"
          class="m-2"
          v-model="name"
          clearable
          placeholder="请输入话题"
        ></el-input>
      </div>
      <div class="flex at_c mr15">
        <div class="mr10 fz14">用户昵称</div>
        <el-input
          style="width: 200px"
          class="m-2"
          v-model="nickname"
          clearable
          placeholder="请输入用户昵称"
        ></el-input>
      </div>
      <div class="mr10">
        <el-button icon="Search" type="primary" @click="getTopicList(1)"
          >搜索</el-button
        >
      </div>
      <div class="mr10">
        <el-button type="primary" @click="modifyFormShow = true"
          >添加</el-button
        >
      </div>
    </div>
    <el-table :data="list" style="width: 100%" height="70vh">
      <el-table-column prop="id" label="ID" width="100"></el-table-column>
      <el-table-column prop="weigh" label="权重" width="100"></el-table-column>
      <el-table-column label="图片" width="180">
        <template v-slot="scope">
          <el-image
            style="width: 100px; height: 100px"
            fit="cover"
            :src="scope.row.image"
            :preview-src-list="[scope.row.image]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column label="标签" width="100">
        <template v-slot="scope">
          <div
            v-if="scope.row.tag"
            :class="{
              label_new: scope.row.tag == 90,
              label_hot: scope.row.tag == 100,
            }"
          >
            {{ scope.row.tag_text }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="是否置顶" width="100">
        <template v-slot="scope">
          <div class="label_zhiding" v-if="scope.row.is_top == 1">置顶</div>
          <div v-if="scope.row.is_top == 0">不置顶</div>
        </template>
      </el-table-column>
      <el-table-column label="话题创建人" width="180">
        <template v-slot="scope">
          <div class="flex at_c">
            <img class="founder_avatar" :src="scope.row.user.avatar" alt="" />
            {{ scope.row.user.nickname }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template v-slot="scope">
          <el-button
            @click="handleShowEdit(scope.$index, scope.row)"
            type="primary"
            size="small"
            icon="Edit"
            >编辑</el-button
          >
          <el-button
            @click="handleDelete(scope.$index, scope.row)"
            type="danger"
            size="small"
            icon="Delete"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="mt20">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 添加&编辑 -->
    <el-dialog
      width="680px"
      v-model="modifyFormShow"
      @close="closeForm"
      :title="modifyForm.id ? '编辑' : '添加'"
    >
      <el-form :model="modifyForm" ref="modifyForm">
        <div>
          <div class="form_cell">
            <el-form-item
              size="large"
              label="名称"
              :label-width="formLabelWidth1"
            >
              <el-input v-model="modifyForm.name" autocomplete="off" />
            </el-form-item>
          </div>
          <div class="form_cell">
            <el-form-item
              size="large"
              label="图片"
              :label-width="formLabelWidth1"
            >
              <el-upload
                class="avatar-uploader"
                action="https://api.i-vce.com/teacher/common/upload"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
              >
                <div class="avatar-uploader_div" v-if="modifyForm.image">
                  <img :src="modifyForm.image" class="avatar" />
                  <div class="zhezhao">
                    <el-icon @click.stop="handleRemove" :size="24">
                      <Delete color="#ffffff" />
                    </el-icon>
                  </div>
                </div>
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
            </el-form-item>
          </div>
          <div class="form_cell">
            <el-form-item
              label="是否置顶"
              :label-width="formLabelWidth1"
              size="large"
            >
              <el-radio-group v-model="modifyForm.is_top">
                <el-radio :label="0">否</el-radio>
                <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="form_cell" v-if="modifyForm.is_top == 0">
            <el-form-item
              label="标签"
              :label-width="formLabelWidth1"
              size="large"
            >
              <el-select
                v-model="modifyForm.tag"
                class="m-2"
                placeholder="请选择标签"
                filterable
              >
                <el-option label="无" :value="0" />
                <el-option
                  v-for="(item, index) in tagList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </div>
          <div class="form_cell" v-if="modifyForm.id">
            <el-form-item
              size="large"
              label="权重"
              :label-width="formLabelWidth1"
            >
              <el-input
                v-model="modifyForm.weigh"
                autocomplete="off"
                placeholder="数字越大越靠前，可不填"
              />
            </el-form-item>
          </div>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="modifyFormShow = false">取消</el-button>
          <el-button type="primary" @click="submitForm">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "@/api/qiyouCommunity.js";
export default {
  data() {
    return {
      name: "",
      nickname: "",
      page: 1,
      pageSize: 15,
      list: [],
      total: null,
      formLabelWidth1: "100px",
      modifyFormShow: false,
      modifyForm: {
        id: null,
        image: "",
        name: "",
        is_top: 0,
        tag: null,
        weigh: null,
      },
      tagList: [],
    };
  },
  mounted() {
    this.getBaseInfo();
  },
  methods: {
    async getBaseInfo() {
      const res = await Service.getBaseInfo();
      this.tagList = res.data.tags_arr;
    },
    async getTopicList(page) {
      const res = await Service.getTopicList({
        page,
        name: this.name,
        nickname: this.nickname,
        limit: this.pageSize,
      });
      this.page = page;
      this.list = res.data.data;
      this.total = res.data.total;
    },
    handleCurrentChange(val) {
      this.getTopicList(val);
      console.log(`当前页: ${val}`);
    },
    handleShowEdit(index, row) {
      this.modifyFormShow = true;
      this.modifyForm = {
        id: row.id,
        image: row.image,
        name: row.name,
        is_top: row.is_top,
        tag: row.tag,
        weigh: row.weigh,
      };
    },
    handleDelete(index, row) {
      this.$confirm("确认删除该数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.handleDeleteApi(index, row);
      });
      // console.log(index, row);
    },
    async handleDeleteApi(index, row) {
      const res = await Service.delTopic({
        id: row.id,
      });
      this.$message.success(res.msg);
      this.getTopicList(this.page);
    },
    handleRemove() {
      console.log("@删除图片");
      this.modifyForm.image = "";
    },
    handleAvatarSuccess(res, file) {
      this.modifyForm.image = res.data.fullurl;
    },
    closeForm() {
      this.modifyForm = {
        id: null,
        image: "",
        name: "",
        is_top: 0,
        tag: null,
        weigh: null,
      };
    },
    async submitForm() {
      var modifyForm = this.modifyForm;
      let res = null;
      if (!modifyForm.name) {
        this.$message.error("名称不可为空");
        return false;
      }
      if (!modifyForm.image && !modifyForm.id) {
        this.$message.error("图片不可为空");
        return false;
      }
      if (modifyForm.id) {
        res = await Service.editTopic(modifyForm);
      } else {
        res = await Service.addTopic(modifyForm);
      }
      this.$message.success(res.msg);
      this.modifyFormShow = false;
      this.getTopicList(1);
      this.modifyForm = {
        id: null,
        image: "",
        name: "",
        is_top: 0,
        tag: null,
        weigh: null,
      };
    },
  },
};
</script>

<style scoped>
.el-table ::v-deep th.el-table__cell,
.el-table ::v-deep td.el-table__cell {
  position: static;
}

.label_zhiding,
.label_new,
.label_hot {
  box-sizing: border-box;
  padding: 4px 6px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 12px;
  border-radius: 2px;
  display: inline-block;
}

.label_zhiding {
  background: #e63434;
}

.label_new {
  background: #4571e7;
}

.label_hot {
  background: #ff6600;
}

.founder_avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 10px;
  display: block;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.avatar-uploader_div {
  width: 178px;
  height: 178px;
  display: block;
  position: relative;
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.zhezhao {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zhezhao:hover {
  opacity: 1;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

/deep/.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
