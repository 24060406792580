<template>
  <div class="body flex at_c">
    <div class="width1">
      <div class="plate_title">
        <div class="plate_title_name">风险推送编辑</div>
      </div>
      <div class="bb p20">
        <el-form
          ref="form"
          :model="form"
          label-position="top"
          label-width="80px"
        >
          <div class="flex at_c jc_b">
            <el-form-item class="w100" label="文本：" size="large">
              <el-input type="textarea" v-model="aaText"></el-input>
            </el-form-item>
          </div>
          <div class="flex at_c jc_b">
            <el-form-item label="品种：" size="large">
              <el-select
                v-model="topic_id"
                class="m-2"
                placeholder="请选择品种"
                size="large"
                filterable
                @change="changeVarieties"
              >
                <el-option
                  v-for="item in varietiesArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="内容：">
            <div id="editor"></div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="doCut">立即生成</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="width2">
      <div class="plate_title">
        <div class="plate_title_name">宣传图预览</div>
      </div>
      <div class="publicity_map overflow_y">
        <div id="capture">
          <div class="post_info">
            <div class="varieties">{{ varieties }}</div>
            <div class="time">{{ form.date_time }}</div>
            <div class="content" v-html="form.content"></div>
          </div>
          <img
            class="jiaoyifengxian_img"
            src="@/assets/img/jiaoyifengxian.png"
            alt=""
          />
        </div>
        <!-- <div class="title">
          {{ form.type == "盘中策略" ? "【盘中】" : "【盘前】"
          }}{{ form.title }}
        </div>
        <div class="sub flex at_c">
          <div class="mr40">{{ form.teacher }}讲师：</div>
          <div>发布时间：{{ form.date_time }}</div>
        </div>
        <div class="content" v-html="form.content"></div>
        <div class="info">
          <div>个人观点不构成具体投资建议，据此操作风险自担。</div>
          <div>
            投资者依据上述内容进行投资如有亏损，损失自担，本司不承担任何责任。
          </div>
        </div> -->
      </div>
    </div>
    <div class="width3">
      <div class="plate_title">
        <div class="plate_title_name">风险推送列表</div>
      </div>
      <div
        v-loading="listLoading"
        class="quotation_group overflow_y"
        v-infinite-scroll="load"
        :infinite-scroll-immediate="false"
      >
        <div class="quotation" v-for="(item, index) in list" :key="index">
          <div class="info">
            <div class="time">{{ item.createtime }}</div>
          </div>
          <img
            class="quotation_cover"
            v-if="item.images.length"
            :src="item.images[0]"
            alt=""
          />
          <div>
            <div class="title hang1">{{ item.title }}</div>
            <!-- <div class="desc hang1">{{ item.content }}</div> -->
            <div class="desc hang1" v-html="item.content"></div>
            <div class="quotation_bottom">
              <div class="quotation_jiaoyisuo" v-if="item.exchange_id">
                <img src="@/assets/img/icon_jiaoyisuo.png" alt="" />
                {{ item.exchange_name }}
              </div>
              <div class="operation" @click="delConfirm(item.id)">删除</div>
            </div>
          </div>
        </div>
        <div class="is_end" v-if="isEnd">已全部加载</div>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" title="宣传图" width="840px">
      <img class="w100" :src="imgUrl" alt="" />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submit">发送</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "../api/articleTemplate.js";
import * as ServiceM from "../api/marketEditor.js";
import data from "../utils/data";
import E from "wangeditor";
import xss from "xss";
import html2canvas from "html2canvas";
export default {
  components: {
    html2canvas,
  },
  data() {
    return {
      form: {
        teacher: "投研中心",
        title: "棕榈案例",
        date_time: "",
        type: "盘中策略",
        content: "",
      },
      editor: null,
      dialogVisible: false,
      imgUrl: "",
      topicList: [], // 话题列表
      topic_id: null,

      aaText: "",
      varietiesArr: [], // 品种arr
      varieties: "品种", // 品种
      listLoading: false,
      list: [],
      isEnd: false,
      page: 1,
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.varietiesArr = data.varietiesArr;
    this.showRealTime();
    this.createEditor();
    this.getViewpointList(1);
  },
  created() {},
  computed: {},
  methods: {
    onmessage(e) {},
    changeVarieties(e) {
      console.log("e", e);
      this.aaText =
        "【交易风险提示】V策财经" +
        this.month +
        "月" +
        this.date +
        "日讯，一图汇总【" +
        e +
        "】期货的风险提示，投资有风险，入市需谨慎。";
      this.varieties = e;
    },
    //获取风险列表
    async getViewpointList(page) {
      if (!this.isEnd) {
        this.listLoading = true;
        const res = await ServiceM.viewpointList({
          page,
          type: "risk",
          // type: "new",
        });
        var list_new = res.data.list.data,
          list = this.list;
        if (list_new.length) {
          this.list = list.concat(list_new);
          this.page++;
        } else {
          this.isEnd = true;
        }
        this.listLoading = false;
      }
    },
    load() {
      this.getViewpointList(this.page);
    },
    //删除行情-弹框
    delConfirm(id) {
      this.$confirm("确认删除该行情吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.del(id);
        })
        .catch(() => {});
    },
    //删除行情
    async del(id) {
      const res = await ServiceM.delViewpoint({ id });
      this.$message({
        type: "success",
        message: "删除成功!",
      });
      this.list = [];
      this.page = 1;
      this.isEnd = false;
      this.getViewpointList(1);
    },
    // 获取图片名称
    getFileName(file) {
      var d = new Date();
      var year = d.getFullYear();
      var month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      var type = file.type.substr(
        file.type.lastIndexOf("/") + 1,
        file.type.length
      );
      return (
        "push/fengxian/" +
        year +
        month +
        date +
        "/" +
        Math.round(new Date()) +
        "." +
        type
      );
    },
    async submit() {
      const res = await Service.uploadBase64({
        pic: this.imgUrl,
      });
      console.log("res.data.url", res.data.url);
      this.addViewpoint(res.data.url);
      // this.sendAppImage(res.data.url);
    },
    async addViewpoint(url) {
      const res = await ServiceM.addViewpoint({
        content: aaText,
        type: 4,
        images: url,
      });
      that.$message.success("操作成功");
    },
    // async sendAppImage(path) {
    //   const res = await Service.sendAppImage({
    //     agent_id: 1000026,
    //     path,
    //     type: 2,
    //     topic_id: this.topic_id,
    //   });
    //   this.$message.success(res.msg);
    //   this.dialogVisible = false;
    // },
    doCut() {
      let that = this;
      html2canvas(document.querySelector("#capture")).then((canvas) => {
        const img = new Image();
        img.src = canvas.toDataURL("png");
        img.setAttribute("crossOrigin", "anonymous");
        // canvas.height = 500; // 自定义图片宽度
        var context = canvas.getContext("2d");
        img.onload = function () {
          var w = img.width;
          var h = img.height;
          context.drawImage(img, 0, 0, w, h, 0, 0, w, h);
          var imgUrl = canvas.toDataURL("image/png");
          that.imgUrl = imgUrl;
          that.dialogVisible = true;
          // 根据生成的图片地址imgUrl（base64）进行后续保存操作
        };
      });
    },
    createEditor() {
      let that = this;
      var height = 400;
      const editor = new E("#editor");
      // 可使用 base64 格式保存图片
      editor.config.uploadImgShowBase64 = true;
      editor.config.height = height;
      editor.config.zIndex = 500;
      // 取消自动 focus
      editor.config.focus = false;
      // 配置 onchange 回调函数
      editor.config.onchange = function (newHtml) {
        // var reult = newHtml.charAt(newHtml.length-1)
        console.log("newHtml", newHtml);
        that.form.content = newHtml;
      };
      // 配置触发 onchange 的时间频率，默认为 200ms
      editor.config.onchangeTimeout = 500; // 修改为 500ms
      editor.create();
      editor.txt.html("");
      this.editor = editor;
    },
    save() {
      var editor = this.editor;
      const html = editor.txt.html();
      const safeHtml = xss(html);
      console.log("处理过 xss 攻击的 html", safeHtml);
    },
    showRealTime() {
      var d = new Date();
      var year = d.getFullYear();
      var month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      var days = new Array("日", "一", "二", "三", "四", "五", "六");
      var day = d.getDay();
      var hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      var min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      var sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
      var cori_date = year + "年" + month + "月" + date + "日";
      var cori_time = hour + ":" + min;
      this.form.date_time = cori_date + " " + cori_time;

      this.month = month;
      this.date = date;
      this.aaText =
        "【交易风险提示】V策财经" +
        month +
        "月" +
        date +
        "日讯，一图汇总【品种】期货的风险提示，投资有风险，入市需谨慎。";
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "方正勇克体简体";
  src: url("../assets/font/方正勇克体简体.TTF");
}

.body {
  height: 100vh;
}

.width1 {
  width: 660px;
  height: 100%;
  box-sizing: border-box;
  border-right: 1px solid #ebeced;
}

.width2 {
  width: 600px;
  height: 100%;
  box-sizing: border-box;
  border-right: 1px solid #ebeced;
}

.width3 {
  width: calc(100% - 1260px);
  height: 100%;
}

.plate_title {
  background-color: #f8fcff;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  padding: 18px 0;
  border-top: 1px solid #ebeced;
  border-bottom: 1px solid #ebeced;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plate_title_name {
  position: relative;
  line-height: 16px;
  padding-left: 30px;
}

.plate_title_name::before {
  content: "";
  position: absolute;
  left: 13px;
  width: 4px;
  height: 16px;
  background-color: #5389f5;
  border-radius: 5px;
}

.overflow_y {
  overflow-y: auto;
}

.overflow_y::-webkit-scrollbar {
  display: block;
  width: 4px;
}

.overflow_y::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px #aaa;
  background: rgba(0, 0, 0, 0.2);
}

.overflow_y::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #aaa;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.publicity_map {
  /* width: calc(1240px * 0.45);
  height: calc(1858px * 0.45); */
  /* box-sizing: border-box;
  padding: 0 50px; */
  /* background-image: url("../assets/img/jiaoyifengxian.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  overflow-y: auto; */
  height: calc(100% - 54px);
}

.post_info {
  position: absolute;
  width: 600px;
}

.post_info .varieties {
  font-family: "方正勇克体简体";
  color: #fff;
  font-size: 38px;
  margin-top: 165px;
  width: 100%;
  text-align: center;
}

.post_info .time {
  font-size: 16px;
  color: #fff;
  width: 100%;
  text-align: center;
  margin-top: 5px;
}

.post_info .content {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.jiaoyifengxian_img {
  width: 599px;
  height: 898px;
  display: block;
}

.publicity_map .title {
  font-size: 55px;
  font-weight: bold;
  box-sizing: border-box;
  padding: 50px 0;
}

.publicity_map .sub {
  font-size: 26px;
  color: #6277a2;
  font-weight: bold;
  padding-bottom: 40px;
  border-bottom: 3px solid #dbb785;
}

.mr40 {
  margin-right: 40px;
}

.publicity_map .info {
  margin-top: 35px;
  text-align: center;
  padding-bottom: 20px;
}

.publicity_map .content {
  margin-top: 35px;
}

.quotation_group {
  box-sizing: border-box;
  padding: 0 30px;
  height: calc(100% - 60px);
}

.quotation {
  display: flex;
  box-sizing: border-box;
  padding: 10px 0;
  margin-bottom: 10px;
  border-bottom: 1px dashed #dedede;
}

.quotation .info {
  flex-shrink: 0;
  width: 92px;
  margin-right: 15px;
}

.quotation .time {
  font-size: 14px;
  color: #666666;
}

.quotation_cover {
  width: 150px;
  height: 90px;
  object-fit: cover;
  margin-right: 10px;
}

.quotation .title {
}

.quotation .desc {
  margin-top: 10px;
  color: #111;
}

.quotation_bottom {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.quotation_jiaoyisuo {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #8b30ff;
  line-height: 17px;
  box-sizing: border-box;
  padding: 4px 10px;
  background: #f3eaff;
  border-radius: 13px 0px 13px 0px;
}

.quotation_jiaoyisuo img {
  width: 11px;
  height: 11px;
  margin-right: 5px;
}

.quotation .operation {
  color: red;
  cursor: pointer;
  margin-left: 10px;
}

.is_end {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 12px 0;
  font-size: 12px;
  color: #ccc;
}
</style>
