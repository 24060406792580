<template>
  <div class="body flex at_c">
    <div class="w800px0">
      <div class="plate_title">
        <div class="plate_title_name">宣传图生成</div>
      </div>
      <div class="bb p20">
        <el-form
          ref="form"
          :model="form"
          label-position="top"
          label-width="80px"
        >
          <div class="flex at_c jc_b">
            <el-form-item class="w45" label="日期：" size="large">
              <el-date-picker
                v-model="form.date_time"
                type="datetime"
                placeholder="选择日期时间"
                value-format="YYYY-MM-DD hh:mm"
              />
            </el-form-item>
            <el-form-item class="w45" label="品种：" size="large">
              <el-select
                v-model="form.category_name"
                class="m-2"
                placeholder="请选择品种"
                size="large"
                filterable
                clearable
              >
                <el-option
                  v-for="item in categoryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>
          </div>
          <div class="flex at_c jc_b">
            <el-form-item class="w45" label="压力1：" size="large">
              <el-input v-model="form.yali1"></el-input>
            </el-form-item>
            <el-form-item class="w45" label="压力2：" size="large">
              <el-input v-model="form.yali2"></el-input>
            </el-form-item>
          </div>
          <div class="flex at_c jc_b">
            <el-form-item class="w45" label="支撑1：" size="large">
              <el-input v-model="form.zhicheng1"></el-input>
            </el-form-item>
            <el-form-item class="w45" label="支撑2：" size="large">
              <el-input v-model="form.zhicheng2"></el-input>
            </el-form-item>
          </div>
          <div class="flex at_c jc_b">
            <el-form-item class="w45" label="入场1：" size="large">
              <el-input v-model="form.ruchang1"></el-input>
            </el-form-item>
            <el-form-item class="w45" label="入场2：" size="large">
              <el-input v-model="form.ruchang2"></el-input>
            </el-form-item>
          </div>
          <div class="flex jc_b">
            <el-form-item class="w45" label="K线模型：" size="large">
              <el-upload
                class="avatar-uploader"
                action="https://api.i-vce.com/teacher/common/upload"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="handleBeforeUpload"
              >
                <div class="avatar-uploader_div" v-if="form.image">
                  <img :src="form.image" class="avatar" />
                  <div class="zhezhao">
                    <el-icon @click.stop="handleRemove" :size="24">
                      <Delete color="#ffffff" />
                    </el-icon>
                  </div>
                </div>
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
            </el-form-item>

            <el-form-item class="w45" label="研究员：" size="large">
              <el-select
                v-model="form.number"
                class="m-2"
                placeholder="请选择研究员"
                size="large"
                filterable
                clearable
                @change="changeNumber"
              >
                <el-option
                  v-for="item in teacherList"
                  :key="item.number"
                  :label="item.name"
                  :value="item.number"
                />
              </el-select>
            </el-form-item>
          </div>
          <el-form-item>
            <el-button @click="back">返回列表</el-button>
            <el-button type="primary" @click="doCut">发送</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="w800px">
      <div class="plate_title">
        <div class="plate_title_name">宣传图生成</div>
      </div>
      <div class="model_body">
        <div class="publicity_map" id="capture">
          <div class="time">报告时间：{{ form.date_time }}</div>
          <div class="name">品种：{{ form.category_name }}</div>
          <div class="data">
            <div>
              <div>{{ form.yali1 }}~{{ form.yali2 }}</div>
              <div>压力</div>
            </div>
            <div>
              <div>{{ form.zhicheng1 }}~{{ form.zhicheng2 }}</div>
              <div>支撑</div>
            </div>
            <div>
              <div>{{ form.ruchang1 }}~{{ form.ruchang2 }}</div>
              <div>入场</div>
            </div>
          </div>
          <!-- html2canvas不支持object: cover;属性，已从node_modules中修改了源码 路径：/node_modules/html2canvas/dist/html2canvas.js-->
          <img v-if="form.image" class="cover_img" :src="form.image" alt="" />
          <div class="teacher_info">
            <div class="mr15">金牌投顾：{{ form.teacher_name }}</div>
            <div>投资咨询证号：{{ form.number }}</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" title="宣传图" width="840px">
      <img class="w100" :src="imgUrl" alt="" />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="submit">发送</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "../api/technicalModel.js";
import * as ServiceB from "../api/bbas.js";
import html2canvas from "html2canvas";
import { Delete, Plus } from "@element-plus/icons-vue";
export default {
  components: {
    html2canvas,
    Plus,
    Delete,
  },
  data() {
    return {
      form: {
        date_time: "",
        category_name: "",
        yali1: "",
        yali2: "",
        zhicheng1: "",
        zhicheng2: "",
        ruchang1: "",
        ruchang2: "",
        image: "",
        teacher_name: "",
        number: "",
      },
      editor: null,
      dialogVisible: false,
      imgUrl: "",
      teacherList: [], // 老师列表
      categoryList: [], // 品种列表
    };
  },
  mounted() {
    let that = this;
    window.onmessageSocket = this.onmessage;
    this.showRealTime();
    this.getBaseInfo1();
    this.getBaseInfo2();

    // 创建粘贴事件
    document.addEventListener("paste", function (e) {
      if (!(e.clipboardData && e.clipboardData.items)) {
        return;
      }
      for (var i = 0, len = e.clipboardData.items.length; i < len; i++) {
        var item = e.clipboardData.items[i];

        if (item.kind === "string") {
          item.getAsString(function (str) {
            console.log("str", str);
            // str 是获取到的字符串
          });
        } else if (item.kind === "file") {
          var pasteFile = item.getAsFile();
          // console.log("pasteFile", pasteFile);

          const reader = new FileReader();
          reader.readAsDataURL(pasteFile);
          reader.onload = () => {
            // 将 base64 数据保存到上传数据中
            that.form.image = reader.result;
            console.warn("file", reader.result);
          };
          reader.onerror = (error) => reject(error);
        }
      }
    });
  },
  created() {},
  computed: {},
  methods: {
    onmessage(e) {},
    back() {
      this.$router.back(-1);
    },
    changeNumber(e) {
      var teacherList = this.teacherList;
      for (const item of teacherList) {
        if (item.number == e) {
          this.form.teacher_name = item.name;
        }
      }
    },
    handleRemove() {
      console.log("@删除图片");
      this.form.image = "";
    },
    handleAvatarSuccess(res, file) {
      // this.form.image = res.data.fullurl;
    },
    handleBeforeUpload(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          // 将 base64 数据保存到上传数据中
          this.form.image = reader.result;
          console.warn("file", reader.result);
          resolve(file);
        };
        reader.onerror = (error) => reject(error);
      });
    },
    // 获取基础信息
    async getBaseInfo1() {
      const res = await Service.getBaseInfo();
      this.teacherList = res.data.teacherList;
    },
    // 获取基础信息
    async getBaseInfo2() {
      const res = await ServiceB.BaseInfo();
      this.categoryList = res.data.categoryList;
    },
    async submit() {
      const res = await Service.uploadBase64({
        pic: this.imgUrl,
      });
      this.sendAppImage(res.data.url);
    },
    async sendAppImage(path) {
      const res = await Service.addTechnologyCase({
        image: path,
      });
      this.$message.success(res.msg);
      this.dialogVisible = false;
      setTimeout(() => {
        this.back();
      }, 500);
    },
    doCut() {
      let that = this;
      html2canvas(document.querySelector("#capture")).then((canvas) => {
        const img = new Image();
        img.src = canvas.toDataURL("png");
        img.setAttribute("crossOrigin", "anonymous");
        // canvas.height = 500; // 自定义图片宽度
        var context = canvas.getContext("2d");
        img.onload = function () {
          var w = img.width;
          var h = img.height;
          context.drawImage(img, 0, 0, w, h, 0, 0, w, h);
          var imgUrl = canvas.toDataURL("image/png");
          that.imgUrl = imgUrl;
          that.dialogVisible = true;
          // 根据生成的图片地址imgUrl（base64）进行后续保存操作
        };
      });
    },
    showRealTime() {
      var d = new Date();
      var year = d.getFullYear();
      var month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      var days = new Array("日", "一", "二", "三", "四", "五", "六");
      var day = d.getDay();
      var hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      var min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      var sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
      var cori_date = year + "-" + month + "-" + date;
      var cori_time = hour + ":" + min;
      this.form.date_time = cori_date + " " + cori_time;
      // this.currentTime = cori_time;
      // this.currentWeek = "星期" + days[day];
    },
  },
};
</script>

<style scoped>
.body {
  height: 100vh;
}

.w800px0 {
  width: calc(100% - 800px);
  height: 100%;
  border-right: 1px solid #ebeced;
}

.w800px {
  width: 800px;
  height: 100%;
}

.plate_title {
  background-color: #f8fcff;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  padding: 18px 0;
  border-top: 1px solid #ebeced;
  border-bottom: 1px solid #ebeced;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plate_title_name {
  position: relative;
  line-height: 16px;
  padding-left: 30px;
}

.plate_title_name::before {
  content: "";
  position: absolute;
  left: 13px;
  width: 4px;
  height: 16px;
  background-color: #5389f5;
  border-radius: 5px;
}

.overflow_y {
  overflow-y: auto;
}

.overflow_y::-webkit-scrollbar {
  display: block;
  width: 4px;
}

.overflow_y::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px #aaa;
  background: rgba(0, 0, 0, 0.2);
}

.overflow_y::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #aaa;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.publicity_map {
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 442px;
  height: 740px;
  background-image: url(../assets/img/jishuanlimoxing.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.publicity_map .time {
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  position: absolute;
  left: 52px;
  top: 113px;
}

.publicity_map .name {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  width: 100%;
  position: absolute;
  left: 0;
  top: 226px;
  text-align: center;
}

.publicity_map .data {
  box-sizing: border-box;
  padding: 0 15px;
  font-size: 18px;
  font-weight: 400;
  color: #4571e7;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  position: absolute;
  left: 0;
  top: 280px;
}

.publicity_map .data > div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.publicity_map .data > div > div:last-child {
  font-size: 14px;
  font-weight: bold;
  color: #101010;
}

.publicity_map .cover_img {
  width: 360px;
  height: 207px;
  display: block;
  position: absolute;
  left: 41px;
  top: 378px;
  object-fit: cover;
}

.publicity_map .teacher_info {
  font-size: 14px;
  font-weight: 400;
  color: #363636;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  left: 0;
  top: 600px;
  text-align: center;
}

.publicity_map .teacher_info > div {
}

.mr40 {
  margin-right: 40px;
}

.publicity_map .info {
  margin-top: 35px;
  text-align: center;
  padding-bottom: 20px;
}

.publicity_map .content {
  margin-top: 35px;
}

.model_body {
  width: 100%;
  height: calc(100% - 54px);
  position: relative;
}

.jishuanlimoxing_img {
  height: 740px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: -1;
}

.avatar-uploader_div {
  width: 148px;
  height: 148px;
  display: block;
  position: relative;
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.zhezhao {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zhezhao:hover {
  opacity: 1;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

/deep/.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  text-align: center;
}
</style>
