import request from "../utils/request";
//上传base64
export const uploadBase64 = (query) => {
  return request({
    url: "/teacher/common/uploadBase64",
    method: "POST",
    data: query,
  });
};
// 获取基础信息
export const getBaseInfo = (query) => {
  return request({
    url: "/teacher/technology_case/getBaseInfo",
    method: "POST",
    data: query,
  });
};
// 添加波段机会
export const addBand = (query) => {
  return request({
    url: "/teacher/technology_case/addBand",
    method: "POST",
    data: query,
  });
};
// 波段机会列表
export const getBandList = (query) => {
  return request({
    url: "/teacher/technology_case/bandList",
    method: "POST",
    data: query,
  });
};
// 波段机会详情详情
export const getBandInfo = (query) => {
  return request({
    url: "/teacher/technology_case/bandInfo",
    method: "POST",
    data: query,
  });
};
// 修改波段机会详情
export const bandEdit = (query) => {
  return request({
    url: "/teacher/technology_case/bandEdit",
    method: "POST",
    data: query,
  });
};
// 设置置顶
export const setHotInfo = (query) => {
  return request({
    url: "/teacher/technology_case/setHotInfo",
    method: "POST",
    data: query,
  });
};
// 波段机会平仓
export const bandPingCang = (query) => {
  return request({
    url: "/teacher/technology_case/bandPingCang",
    method: "POST",
    data: query,
  });
};
