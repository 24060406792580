<template>
  <div class="cover_body">
    <img
      class="pinzhongzhuizong_head"
      src="@/assets/img/pinzhongzhuizong_head.png"
      alt=""
    />
    <div class="time">{{ dateTime }}</div>
    <div class="info_body1">
      <div class="info_body1_head">
        <img src="@/assets/img/head_left.png" alt="" />
        <div class="fb">{{ form.title || "请输入标题" }}</div>
        <img src="@/assets/img/head_right.png" alt="" />
      </div>
      <div>
        <div class="label_titles">
          <div class="label_title">品种</div>
          <div class="label_title">品种追踪</div>
          <div class="label_title">{{ form.qujian_type || "请选择区间" }}</div>
        </div>
        <div class="info_cells">
          <div>{{ categoryName || "请选择品种" }}{{ form.contract }}</div>
          <div>{{ form.zhuizong || "请输入品种追踪" }}</div>
          <div>{{ form.qujian1 || 0 }}~{{ form.qujian2 || 0 }}</div>
        </div>
      </div>
      <div>
        <div class="label_title mb5">黄金三角（基本、技术、资金）</div>
        <div class="jiedu">【基本面】：{{ form.jiben }}</div>
        <div class="jiedu">【技术面】：{{ form.jishu }}</div>
        <div class="jiedu">【资金面】：{{ form.zijin }}</div>
      </div>
      <img
        class="pinzhongzhuizong_extra_line"
        src="@/assets/img/pinzhongzhuizong_extra_line.png"
        alt=""
      />
    </div>

    <div class="info_body1" style="padding: 35px 10px 5px; margin-bottom: 12px">
      <div class="zhibiao_title">{{ form.zhibiao || "指标名称" }}</div>
      <img class="w100 flex mb5" :src="images_base64" alt="" />
      <div class="laiyuan">(来源：{{ form.laiyuan }})</div>
    </div>

    <div class="teacher_info">
      高级投顾：{{ form.teacher_name }}&nbsp;&nbsp;从业资格号：{{
        form.teacher_number2
      }}&nbsp;&nbsp;投资咨询号：{{ form.teacher_number }}
    </div>

    <div class="mianze" v-html="disclaimer"></div>
    <!-- <div class="mianze">
      <div>免责声明：</div>
      本点评非期货投资咨询业务服务。点评中的信息或所表述的意见并不构成对任何人的投资建议，仅供学习交流。新湖期货不会因为关注、接收或阅读本内容而视相关人员为客户。在任何情况下，本公司不对信息的准确性、完整性或可靠性作任何保证，亦不对任何人因使用本点评中的任何内容所引致的任何损失负任何责任，投资者需自行承担风险，投资有风险，入市需谨慎。投资者不应单纯依靠本内容而取代个人的独立判断。投资者务必注意，据此做出的任何投资决策与本公司本员工或者关联机构无关。
    </div> -->

    <div class="tishi">市场有风险&nbsp;&nbsp;&nbsp;&nbsp;入市需谨慎</div>
  </div>
</template>

<script>
export default {
  props: {
    categoryName: {
      type: String,
      default: "",
    },
    dateTime: {
      type: String,
      default: "",
    },
    formType: {
      type: Number,
      default: 0,
    },
    form: {
      type: Object,
      default: Object,
    },
    form1: {
      type: Object,
      default: Object,
    },
    disclaimer: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      images_base64: "",
    };
  },
  methods: {
    changeImagesBase64(e) {
      this.images_base64 = e;
    },
  },
};
</script>

<style scoped>
.cover_body {
  width: 442px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 163px 0 15px;
  background-color: #d11a16;
  position: relative;
}

.pinzhongzhuizong_head {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.time {
  font-weight: normal;
  font-size: 24px;
  color: #fbe6cf;
  margin-bottom: 59px;
  position: relative;
  z-index: 1;
}

.info_body1 {
  background-color: #f9f1e4;
  width: 406px;
  border-radius: 13px;
  box-sizing: border-box;
  padding: 11px 22px 45px;
  margin-bottom: 21px;
  position: relative;
}

.info_body1_head {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 21px;
  color: #4a2918;
  margin-bottom: 18px;
  word-break: break-all;
  text-align: center;
}

.info_body1_head img {
  height: 2px;
  display: block;
}

.info_body1_head div {
  margin: 0 3px;
}

.label_titles {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.label_titles > .label_title {
  width: calc((100% - 2px) / 3);
}

.label_title {
  background-color: #c32325;
  font-weight: bold;
  font-size: 13px;
  color: #fde1ba;
  text-align: center;
  box-sizing: border-box;
  padding: 5px 0;
}

.info_cells {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 11px;
}

.info_cells > div {
  width: calc((100% - 2px) / 3);
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  color: #4e2612;
}

.jiedu {
  font-weight: bold;
  font-size: 13px;
  color: #4e2612;
  text-align: justify;
  line-height: 20px;
  position: relative;
  z-index: 1;
  word-break: break-all;
}

.pinzhongzhuizong_extra_line {
  position: absolute;
  left: 83px;
  bottom: -18px;
  width: 239px;
}

.zhibiao_title {
  width: 180px;
  height: 44px;
  position: absolute;
  top: -11px;
  left: 113px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 19px;
  color: #aa3b34;
  background-image: url(../assets/img/zhibiao_title_bg.png);
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding-top: 8px;
}

.laiyuan {
  font-weight: 400;
  font-size: 12px;
  color: #111010;
  text-align: right;
  width: 100%;
}

.teacher_info {
  width: 100%;
  font-weight: 400;
  box-sizing: border-box;
  padding: 0 27px;
  text-align: justify;
  font-size: 12px;
  color: #ffffff;
  margin-bottom: 12px;
}

.mianze {
  width: 100%;
  box-sizing: border-box;
  padding: 0 27px;
  text-align: justify;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  margin-bottom: 27px;
}

.tishi {
  font-weight: 400;
  font-size: 12px;
  color: #ff9e83;
}
</style>
