import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import jquery from "jquery";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import locale from "element-plus/lib/locale/lang/zh-cn";
import "./assets/css/BaoUi.css";
import "./assets/css/main.css";
import VueClipboard from "vue-clipboard2";
import store from "./store/index";
// import * as ElIconList from "../n/element-plus/icons";
import * as ElIconList from "../node_modules/@element-plus/icons-vue";
const app = createApp(App);
for (const name in ElIconList) {
  app.component(name, ElIconList[name]);
}
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  const role1 = localStorage.getItem("vce-token-push");
  const isCompany = localStorage.getItem("is_company") * 1; // 判断是否为期货公司
  const companyId = localStorage.getItem("company_id") * 1; // 期货公司id
  const authorityUrls1 = [
    "/chat",
    "/statistics",
    "/businessDynamic",
    "/articleTemplate",
    "/marketEditor",
    "/pushMessage",
    "/bbas",
    "/headlineReport",
    "/viewingData",
    "/qiyouCommunity",
    "/blackResearchReport",
    "/qihuoshangpin",
  ]; // V策财经专属路由
  const authorityUrls2 = ["/bandOpportunity"]; // 正信专属路由
  if (!role1 && to.path !== "/login") {
    // 未登录访问非登录页
    next("/login");
  } else {
    if (isCompany == 1 && authorityUrls1.includes(to.path)) {
    // 期货公司访问V策专属路由
      next("/404");
    }
    if (companyId == 4 && authorityUrls2.includes(to.path)) {
    // 新湖访问正信专属路由
      next("/404");
    }
    next();
  }
});

app
  .use(router)
  .use(jquery)
  .use(store)
  .use(ElementPlus, { locale })
  .use(VueClipboard)
  .mount("#app");
