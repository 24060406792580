<template>
  <div>
    <pageTitle :title="title"></pageTitle>
    <div class="bb p30">
      <div class="flex at_c">
        <div class="cell" v-for="(item, index) in circleArr" :key="index">
          <div class="flex at_c cell_top pr">
            <img class="data_circle1" :src="item.avatar" alt="" />
            <div>
              <div class="data_text1 mb15">今日已读人数</div>
              <div class="data_text2">
                {{ item.read_num }}
                <span class="fz16"> / {{ item.user_total }}</span>
              </div>
            </div>
            <div class="read_rate">≈ {{ item.read_rate }}%</div>
            <!-- <div class="read_rate">33%</div> -->
          </div>
          <div class="flex at_c jc_c cell_bottom">
            <img
              class="data_circle2"
              src="@/assets/img/data_circle2.png"
              alt=""
            />
            <div class="data_text3">{{ item.title }}</div>
          </div>
        </div>
      </div>

      <div class="data_bottom">
        <div v-loading="leftChangeLoading">
          <div class="data_bottom_left pr">
            <div class="mr20">
              <el-radio-group
                v-model="lineType"
                size="large"
                @change="changeLineType"
              >
                <el-radio-button
                  label="每时"
                  :disabled="disabled"
                  v-if="dataType == 1"
                />
                <el-radio-button label="每日" :disabled="disabled" />
                <el-radio-button label="每月" :disabled="disabled" />
                <el-radio-button label="每年" :disabled="disabled" />
              </el-radio-group>
            </div>
            <div class="mr20 w170" v-if="lineType === '每时' && dataType == 1">
              <el-date-picker
                v-model="date"
                size="large"
                type="date"
                placeholder="请选择日期"
                value-format="YYYY-MM-DD"
              />
            </div>
            <div class="mr20 w170" v-if="lineType === '每日'">
              <el-date-picker
                v-model="date"
                size="large"
                type="month"
                placeholder="请选择月份"
                value-format="YYYY-MM-DD"
              />
            </div>
            <div class="mr20 w170" v-if="lineType === '每月'">
              <el-date-picker
                v-model="date"
                size="large"
                type="year"
                placeholder="请选择年份"
                value-format="YYYY-MM-DD"
              />
            </div>
            <div class="mr20 w130" v-if="dataType == 2">
              <el-select
                @change="getPeakEchart"
                v-model="teacher_id"
                class="m-2"
                placeholder="请选择老师"
                size="large"
              >
                <el-option
                  v-for="item in teacherList"
                  :key="item.id"
                  :label="item.nickname"
                  :value="item.id"
                />
              </el-select>
            </div>
            <el-button
              @click="getPeakEchart"
              size="large"
              v-if="lineType !== '每年'"
              type="primary"
              >查询</el-button
            >
            <div
              v-if="dataType == 1"
              class="switch_btn"
              @click="changeDateType(2)"
            >
              <img src="@/assets/img/icon_gz.png" alt="" />
              切换到圈子数据
            </div>
            <div
              v-if="dataType == 2"
              class="switch_btn"
              @click="changeDateType(1)"
            >
              <img src="@/assets/img/icon_gz.png" alt="" />
              切换到在线人数峰值
            </div>
          </div>
          <div id="brokenLine"></div>
        </div>
        <div>
          <div class="data_bottom_right">
            <el-radio-group
              v-model="mapType"
              size="large"
              @change="changeMapType"
            >
              <el-radio-button label="游客" :disabled="disabled1" />
              <el-radio-button label="会员" :disabled="disabled1" />
            </el-radio-group>
          </div>
          <div id="map" ref="myChart"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Service from "../api/statistics";
import * as ServiceIndex from "../api/index";
import * as echarts from "echarts";
import pageTitle from "../components/pageTitle.vue";
import "@/utils/china.js";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      title: "统计数据",
      circleArr: [],
      lineType: "每时",
      mapType: "游客",
      disabled: false,
      disabled1: false,
      date: "",
      leftChangeLoading: false,
      dataType: 1, // 1在线人数峰值2圈子数据
      teacher_id: null, // 当前选中的老师id
      teacherList: [], // 老师列表
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.lineType = this.dataType == 1 ? "每时" : "每日";
    this.getCircleStatistics();
    this.getBaseInfo();
    this.$nextTick(() => {
      this.getPeakEchart();
      this.getEchartMap();
    });
  },
  methods: {
    onmessage() {},
    // 切换数据模式
    changeDateType(e) {
      this.lineType = e == 1 ? "每时" : "每日";
      this.teacher_id = null;
      this.dataType = e;
      this.getPeakEchart();
    },
    async getBaseInfo() {
      const res = await ServiceIndex.baseInfo();
      this.teacherList = res.data.teacherList;
    },
    async getCircleStatistics() {
      const res = await Service.getCircleStatistics();
      this.circleArr = res.data;
    },
    async getEchartMap() {
      const res = await Service.getUserStatistics({
        type: this.mapType === "游客" ? 2 : 1,
      });
      console.log("getEchartMap", res);
      // let dataList = [
      //   {
      //     name: "北京",
      //     value: 54,
      //   },
      //   {
      //     name: "南海诸岛",
      //     value: 0,
      //   },
      //   {
      //     name: "天津",
      //     value: 13,
      //   },
      //   {
      //     name: "上海",
      //     value: 40,
      //   },
      //   {
      //     name: "重庆",
      //     value: 75,
      //   },
      //   {
      //     name: "河北",
      //     value: 13,
      //   },
      //   {
      //     name: "河南",
      //     value: 83,
      //   },
      //   {
      //     name: "云南",
      //     value: 11,
      //   },
      //   {
      //     name: "辽宁",
      //     value: 19,
      //   },
      //   {
      //     name: "黑龙江",
      //     value: 15,
      //   },
      //   {
      //     name: "湖南",
      //     value: 69,
      //   },
      //   {
      //     name: "安徽",
      //     value: 60,
      //   },
      //   {
      //     name: "山东",
      //     value: 39,
      //   },
      //   {
      //     name: "新疆",
      //     value: 4,
      //   },
      //   {
      //     name: "江苏",
      //     value: 31,
      //   },
      //   {
      //     name: "浙江",
      //     value: 104,
      //   },
      //   {
      //     name: "江西",
      //     value: 36,
      //   },
      //   {
      //     name: "湖北",
      //     value: 1052,
      //   },
      //   {
      //     name: "广西",
      //     value: 33,
      //   },
      //   {
      //     name: "甘肃",
      //     value: 7,
      //   },
      //   {
      //     name: "山西",
      //     value: 9,
      //   },
      //   {
      //     name: "内蒙古",
      //     value: 7,
      //   },
      //   {
      //     name: "陕西",
      //     value: 22,
      //   },
      //   {
      //     name: "吉林",
      //     value: 4,
      //   },
      //   {
      //     name: "福建",
      //     value: 18,
      //   },
      //   {
      //     name: "贵州",
      //     value: 5,
      //   },
      //   {
      //     name: "广东",
      //     value: 98,
      //   },
      //   {
      //     name: "青海",
      //     value: 1,
      //   },
      //   {
      //     name: "西藏",
      //     value: 0,
      //   },
      //   {
      //     name: "四川",
      //     value: 44,
      //   },
      //   {
      //     name: "宁夏",
      //     value: 4,
      //   },
      //   {
      //     name: "海南",
      //     value: 22,
      //   },
      //   {
      //     name: "台湾",
      //     value: 3,
      //   },
      //   {
      //     name: "香港",
      //     value: 5,
      //   },
      //   {
      //     name: "澳门",
      //     value: 5,
      //   },
      // ];
      var dataList = res.data;
      // 对返回数据进行排序，由大到小
      var cha = dataList.sort((a, b) => {
        return b.value - a.value;
      });
      // maxInteger最大整数：将返回数据中最大值的第一位加1再乘以10的最大值位数的次方
      var maxValue = cha[0].value,
        maxInteger =
          (maxValue.toString()[0] * 1 + 1) *
          Math.pow(10, maxValue.toString().length - 1),
        pieces = [
          {
            gt: (maxInteger / 5) * 4,
            label: "> " + (maxInteger / 5) * 4 + " 人",
            color: "#7f1100",
          },
          {
            gte: (maxInteger / 5) * 3,
            lte: (maxInteger / 5) * 4,
            label: (maxInteger / 5) * 3 + " - " + (maxInteger / 5) * 4 + " 人",
            color: "#ff5428",
          },
          {
            gte: (maxInteger / 5) * 2,
            lte: (maxInteger / 5) * 3,
            label: (maxInteger / 5) * 2 + " - " + (maxInteger / 5) * 3 + " 人",
            color: "#ff5428",
          },
          {
            gte: (maxInteger / 5) * 1,
            lt: (maxInteger / 5) * 2,
            label: (maxInteger / 5) * 1 + " - " + (maxInteger / 5) * 2 + " 人",
            color: "#ff8c71",
          },
          {
            gte: 0,
            lt: (maxInteger / 5) * 1,
            label: "0 - " + (maxInteger / 5) * 1 + " 人",
            color: "#ffd768",
          },
        ];
      let myChart = echarts.init(this.$refs.myChart);

      myChart.setOption({
        title: {
          text: "全国" + this.mapType + "分布图",
          subtext: "",
          left: "left",
          textStyle: {
            color: "#000",
            fontSize: 24,
          },
          subtextStyle: {
            fontSize: 16,
          },
        },
        tooltip: {
          triggerOn: "click",
          formatter: function (e, t, n) {
            console.log("@", e, t, n);
            return e.seriesName + "<br />" + e.name + "：" + e.value;
          },
        },
        // 热力地图
        visualMap: {
          min: 0,
          max: 1000,
          left: 40,
          textStyle: {
            color: "#000",
          },
          pieces,
        },

        series: [
          {
            name: this.mapType + "人数",
            data: dataList,
            type: "map",
            map: "china",
            zoom: 1.2,
            aspectScale: 0.75,
            label: {
              // 默认文本标签样式
              normal: {
                color: "black",
                show: true,
              },
              // 高亮文本标签样式
              emphasis: {
                color: "yellow",
                fontSize: 22,
                fontWeight: "bold",
              },
            },
            itemStyle: {
              // 默认区域样式
              normal: {
                // 区域背景透明
                areaColor: "transparent",
                borderColor: "rgba(39,211,233, 1)",
                borderWidth: 1,
              },
              // 高亮区域样式
              emphasis: {
                // 高亮区域背景色
                areaColor: "#01ADF2",
              },
            },
          },
        ],
      });

      // 添加窗口大小改变监听事件，当窗口大小改变时，图表会重新绘制，自适应窗口大小
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    // 在线人数峰值图表
    async getPeakEchart() {
      // 避免切换页面图表不显示
      const command_edom = document.getElementById("brokenLine");
      command_edom.removeAttribute("_echarts_instance_");
      var type = "",
        lineType = this.lineType,
        date = this.date,
        teacher_id = this.teacher_id;
      switch (lineType) {
        case "每时":
          type = "hour";
          break;
        case "每日":
          type = "day";
          break;
        case "每月":
          type = "month";
          break;
        case "每年":
          type = "year";
          break;
      }
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("brokenLine"));
      if (this.dataType == 1) {
        const res = await Service.getSocketStatistics({
          type,
          date,
        });
        const dateList = res.data.xList;
        const valueList = res.data.yList;
        const max = Math.max.apply(null, valueList);
        // 绘制图表
        myChart.setOption({
          // Make gradient line here
          visualMap: [
            {
              show: false,
              type: "continuous",
              seriesIndex: 0,
              min: 0,
              max,
            },
          ],
          title: [
            {
              left: "left",
              text: "在线人数峰值",
              textStyle: {
                color: "#000",
                fontSize: 24,
              },
              subtextStyle: {
                fontSize: 20,
              },
            },
          ],
          tooltip: {
            trigger: "axis",
          },
          xAxis: [
            {
              data: dateList,
              gridIndex: 1,
            },
          ],
          yAxis: [
            {
              gridIndex: 1,
            },
          ],
          grid: [
            {
              bottom: "10%",
            },
            {
              top: "20%",
              bottom: "10%",
            },
          ],
          series: [
            {
              type: "line",
              showSymbol: true,
              data: valueList,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                  },
                },
              },
            },
          ],
        });
      } else {
        const res = await Service.getCircleDataStatistics({
          teacher_id,
          type,
          date,
        });
        const valueList1 = res.data.userTotalList;
        const valueList2 = res.data.readUserList;
        const valueList3 = res.data.chatNumList;
        const dateList = res.data.yList;
        const max = Math.max.apply(null, valueList3);
        // 绘制图表
        myChart.setOption({
          // Make gradient line here
          // visualMap: [
          //   {
          //     show: false,
          //     type: "continuous",
          //     seriesIndex: 0,
          //     min: 0,
          //     max,
          //   },
          // ],
          title: [
            {
              left: "left",
              text: "圈子数据",
              textStyle: {
                color: "#000",
                fontSize: 24,
              },
              subtextStyle: {
                fontSize: 20,
              },
            },
          ],
          legend: {
            data: ["总用户数", "已读人数", "聊天数量"],
          },
          tooltip: {
            trigger: "axis",
          },
          xAxis: [
            {
              data: dateList,
              gridIndex: 1,
            },
          ],
          yAxis: [
            {
              gridIndex: 1,
            },
          ],
          grid: [
            {
              bottom: "10%",
            },
            {
              top: "20%",
              bottom: "10%",
            },
          ],
          series: [
            {
              type: "bar",
              name: "总用户数",
              barWidth: lineType == "每年" ? 50 : null,
              showSymbol: true,
              data: valueList1,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                  },
                },
              },
            },
            {
              type: "line",
              name: "已读人数",
              showSymbol: true,
              data: valueList2,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                  },
                },
              },
            },
            {
              type: "line",
              name: "聊天数量",
              showSymbol: true,
              data: valueList3,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                  },
                },
              },
            },
          ],
        });
      }

      // 添加窗口大小改变监听事件，当窗口大小改变时，图表会重新绘制，自适应窗口大小
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    changeLineType() {
      this.date = "";
      this.disabled = true;
      setTimeout(() => {
        this.disabled = false;
      }, 300);
      this.getPeakEchart();
    },
    changeMapType() {
      this.disabled1 = true;
      setTimeout(() => {
        this.disabled1 = false;
      }, 300);
      this.getEchartMap();
    },
  },
};
</script>

<style scoped>
.data_bottom {
  width: 100%;
  margin-top: 30px;
  height: calc(100vh - 300px);
  display: flex;
  justify-content: space-between;
}

.data_bottom > div {
  width: calc(50% - 15px);
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  box-shadow: #ececec 0px 0px 20px 0px;
  border-radius: 20px;
}

.data_bottom_left {
  margin-bottom: 20px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

.data_bottom_right {
  margin-bottom: 20px;
  width: 100%;
  height: 40px;
}

.cell {
  width: 300px;
  margin-right: 20px;
  border-radius: 20px;
  box-shadow: #d8d8d8 0px 0px 7px 0px;
}

.cell_top {
  box-sizing: border-box;
  padding: 15px;
  border-radius: 20px 20px 0 0;
  background-color: #e8f1ff;
  overflow: hidden;
}

.read_rate {
  position: absolute;
  /* font-weight: bolder; */
  font-size: 16px;
  color: #618cff;
  /* opacity: 0.75; */
  bottom: 22px;
  right: 10px;
}

.cell_bottom {
  box-sizing: border-box;
  padding: 15px;
  border-radius: 0 0 20px 20px;
  background-color: #f4f7fe;
}

.data_circle1 {
  width: 70px;
  height: 70px;
  margin-right: 10px;
}

.data_circle2 {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}

.data_text1 {
  color: #618cff;
  /* background-image: -webkit-linear-gradient(right, #ff0000, green);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.data_text2 {
  color: #618cff;
  font-size: 32px;
  line-height: 32px;
}

.data_text3 {
  color: #aeafb0;
}

#brokenLine {
  width: 100%;
  height: calc(100% - 60px);
}

#map {
  width: 100%;
  height: calc(100% - 60px);
}

.plate_title {
  background-color: #f8fcff;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  /* padding: 18px 0; */
  border-top: 1px solid #ebeced;
  border-bottom: 1px solid #ebeced;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  padding-right: 25px;
}

.plate_title_name {
  position: relative;
  line-height: 16px;
  padding-left: 30px;
}

.plate_title_name::before {
  content: "";
  position: absolute;
  left: 13px;
  width: 4px;
  height: 16px;
  background-color: #5389f5;
  border-radius: 5px;
}

.switch_btn {
  display: flex;
  align-items: center;
  color: #878a91;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.switch_btn img {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  flex-shrink: 0;
}

.w170 {
  width: 170px;
}

.w170 /deep/.el-input--large {
  width: 100% !important;
}

.w130 {
  width: 130px;
}

.w130 /deep/.el-input--large {
  width: 100% !important;
}
</style>
