<template>
  <div class="body">
    <div class="plate_title">
      <div class="plate_title_name">头条报告</div>
    </div>
    <div class="bb p30">
      <div class="head flex fw_w">
        <!-- <div>
          <div>标题</div>
          <div class="w300">
            <el-input
              clearable
              size="large"
              v-model="title"
              placeholder="请输入标题"
            ></el-input>
          </div>
        </div> -->
        <div>
          <div>分类</div>
          <div>
            <el-select
              v-model="category_id"
              class="m-2"
              placeholder=""
              size="large"
            >
              <el-option label="全部" :value="0" />
              <el-option
                v-for="(item, index) in categoryList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
        </div>
        <div>
          <div>发布时间</div>
          <div>
            <el-date-picker
              format="YYYY-MM-DD"
              value-format="x"
              size="large"
              v-model="time_range"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div>
          <el-button @click="search" type="primary">搜 索</el-button>
        </div>
      </div>
      <div class="mb20">
        <el-button type="primary" @click="handleFormShow()">添加报告</el-button>
        <el-button @click="toCategory">分类管理</el-button>
      </div>
      <!-- 数据列表 -->
      <div v-loading="listLoading">
        <div class="mb20">
          <el-table :data="list" height="70vh" style="width: 100%">
            <el-table-column label="ID" width="120" prop="id">
            </el-table-column>
            <el-table-column label="封面图" width="220">
              <template #default="scope">
                <img
                  :src="scope.row.image"
                  style="width: 160px; height: 100px; object-fit: cover"
                  alt=""
                />
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              label="标题"
              width="300"
              prop="title"
            >
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              label="分类"
              width="200"
              prop="category.name"
            >
            </el-table-column>
            <el-table-column show-overflow-tooltip label="浏览量" width="200">
              <template #default="scope">
                <div>
                  <div
                    v-for="(item, index) in scope.row.read_info"
                    :key="index"
                  >
                    {{ item.platform_text }}:{{ item.count }}次
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="创建时间"
              width="180"
              prop="createtime_text"
            >
            </el-table-column>
            <el-table-column label="操作" width="300" align="center">
              <template #default="scope">
                <el-button
                  type="primary"
                  size="default"
                  @click="handleFormShow(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  size="default"
                  @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹出表单 -->
    <el-dialog width="900px" v-model="formShow" :title="formTitle">
      <div class="flex">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="分类">
            <el-select
              v-model="form.category_id"
              class="m-2"
              placeholder="请选择分类"
              size="large"
              filterable
            >
              <el-option
                v-for="(item, index) in categoryList"
                :key="index"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="标题">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="摘要">
            <el-input
              type="textarea"
              :autosize="{ minRows: 4, maxRows: 10 }"
              v-model="form.desc"
            ></el-input>
          </el-form-item>
          <el-form-item label="封面图">
            <el-upload
              class="avatar-uploader"
              action="https://api.i-vce.com/teacher/common/upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <div class="avatar-uploader_div" v-if="form.image">
                <img :src="form.image" class="avatar" />
                <div class="zhezhao">
                  <el-icon @click.stop="handleRemove" :size="24">
                    <Delete color="#ffffff" />
                  </el-icon>
                </div>
              </div>
              <el-icon v-else class="avatar-uploader-icon">
                <Plus />
              </el-icon>
            </el-upload>
          </el-form-item>
          <el-form-item label="内容">
            <div id="editor1"></div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "@/api/headlineReport";
import E from "wangeditor";
import companyList from "@/utils/futuresCompanies";
import { Delete, Plus } from "@element-plus/icons-vue";
const OSS = require("ali-oss");
export default {
  components: {
    Plus,
    Delete,
  },
  data() {
    return {
      title: "", // 搜索的标题
      categoryList: [], // 分类列表
      category_id: 0, // 分类id
      time_range: [], // 发布时间范围
      listLoading: false,
      list: [],
      page: 1,
      pageSize: 15,
      total: 0,

      formShow: false,
      formTitle: "",
      form: {
        id: null,
        category_id: null, // 分类
        title: "", // 标题
        desc: "", // 摘要
        image: "", // 封面图
        content: "", // 内容
      }, // 提交表单信息
      editor: null,
    };
  },
  mounted() {
    let that = this;
    window.onmessageSocket = this.onmessage;
    // 创建粘贴事件
    document.addEventListener("paste", function (e) {
      console.log("e", e);
      if (!(e.clipboardData && e.clipboardData.items)) {
        return;
      }
      for (var i = 0, len = e.clipboardData.items.length; i < len; i++) {
        var item = e.clipboardData.items[i];

        if (item.kind === "string") {
          item.getAsString(function (str) {
            console.log("str", str);
            // str 是获取到的字符串
          });
        } else if (item.kind === "file") {
          var pasteFile = item.getAsFile();
          // console.log("pasteFile", pasteFile);

          let client = new OSS({
            // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
            region: "oss-cn-shanghai",
            // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
            accessKeyId: "LTAI5tRozUZ3WynSFfTzC72S",
            accessKeySecret: "HGfPDLd6zylQVVU7c9tkQT7uGKpRd7",
            bucket: "i-vce",
          });
          var name = that.getFileName(pasteFile);
          client
            .put(name, pasteFile)
            .then(function (res) {
              // 上传图片，返回结果，将图片插入到编辑器中
              console.log("res", res);
              that.form.image = res.url;
            })
            .catch(function (err) {
              console.log(err);
            });
          // that.uploadImg(pasteFile);
          // pasteFile就是获取到的文件
        }
      }
    });

    this.companyList = companyList;
    this.getCategoryList();
    this.getHeadLineList(1);
  },
  methods: {
    onmessage(e) {},
    toCategory() {
      this.$router.push("/headlineReportCategory");
    },
    // 获取分类列表
    async getCategoryList() {
      const res = await Service.getCategoryList();
      this.categoryList = res.data;
    },
    // 获取头条报告列表
    async getHeadLineList(page) {
      this.listLoading = true;
      var time_range0 = this.time_range ? [...this.time_range] : [];
      if (time_range0.length) {
        time_range0[0] = time_range0[0] / 1000;
        time_range0[1] = time_range0[1] / 1000;
      }
      const res = await Service.getHeadLineList({
        page,
        category_id: this.category_id,
        time_range: time_range0,
      });
      this.list = res.data.data;
      this.total = res.data.total;
      this.listLoading = false;
    },
    handleRemove() {
      console.log("@删除图片");
      this.form.image = "";
    },
    handleAvatarSuccess(res, file) {
      this.form.image = res.data.fullurl;
    },
    search() {
      this.list = [];
      this.page = 1;
      this.getHeadLineList(1);
    },
    async onSubmit() {
      var form = this.form;
      var obj = { ...form };
      if (!obj.category_id) {
        this.$message.error("请选择分类");
        return false;
      }
      if (!obj.title) {
        this.$message.error("请输入标题");
        return false;
      }
      if (!obj.desc) {
        this.$message.error("请输入摘要");
        return false;
      }
      if (!obj.image) {
        this.$message.error("封面图不可为空");
        return false;
      }
      if (!obj.content) {
        this.$message.error("请输入内容");
        return false;
      }

      var res = null;
      if (obj.id) {
        res = await Service.editHeadLine(obj);
      } else {
        res = await Service.addHeadLine(obj);
      }
      this.$message.success(res.msg);
      this.formShow = false;
      this.getHeadLineList(this.page);
    },
    handleCurrentChange(val) {
      this.list = [];
      this.getHeadLineList(val);
    },
    // 获取图片名称
    getFileName(file) {
      var d = new Date();
      var year = d.getFullYear();
      var month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      var type = file.type.substr(
        file.type.lastIndexOf("/") + 1,
        file.type.length
      );
      return (
        "push/toutiao/" +
        year +
        month +
        date +
        "/" +
        Math.round(new Date()) +
        "." +
        type
      );
    },
    // 创建富文本编辑器
    createEditor() {
      let that = this;
      var height = 350;
      const editor = new E("#editor1");
      // 关闭粘贴样式的过滤
      editor.config.pasteFilterStyle = false;
      // 自定义处理粘贴的文本内容
      editor.config.pasteTextHandle = function (content) {
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
        if (that.content != content) {
          that.form.content = content;
          var str = content;
          str = str.replace(/<xml>[\s\S]*?<\/xml>/gi, "");
          str = str.replace(/<style>[\s\S]*?<\/style>/gi, "");
          str = str.replace(/<\/?[^>]*>/g, "");
          str = str.replace(/[ | ]*\n/g, "\n");
          str = str.replace(/&nbsp;/gi, "");
          console.log(str);
          return str;
        }
      };

      editor.config.uploadImgServer = true;
      let client = new OSS({
        // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
        region: "oss-cn-shanghai",
        // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
        accessKeyId: "LTAI5tRozUZ3WynSFfTzC72S",
        accessKeySecret: "HGfPDLd6zylQVVU7c9tkQT7uGKpRd7",
        bucket: "i-vce",
      });

      editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        var name = that.getFileName(resultFiles[0]);
        client
          .put(name, resultFiles[0])
          .then(function (res) {
            // 上传图片，返回结果，将图片插入到编辑器中
            insertImgFn(res.url);
          })
          .catch(function (err) {
            console.log(err);
          });
      };

      editor.config.height = height;
      editor.config.zIndex = 500;
      // 配置行高
      editor.config.lineHeights = [
        "0",
        "0.5",
        "1.0",
        "1.5",
        "2.0",
        "2.5",
        "3.0",
      ];
      // 配置菜单栏，设置不需要的菜单
      editor.config.excludeMenus = [
        "link",
        "list",
        "todo",
        "justify",
        "quote",
        "video",
        "table",
        "code",
        "splitLine",
      ];
      // 取消自动 focus
      // editor.config.focus = false;
      // 配置 onchange 回调函数
      editor.config.onchange = function (newHtml) {
        // var reult = newHtml.charAt(newHtml.length-1)
        that.form.content = newHtml;
      };
      console.log("@", editor.config);
      // 配置触发 onchange 的时间频率，默认为 200ms
      editor.config.onchangeTimeout = 500; // 修改为 500ms
      editor.create();
      editor.txt.html("");
      this.editor = editor;
    },
    // 信息详情
    async getInfoDetail(id) {
      const res = await Service.headLineInfo({
        id,
      });
      console.log("信息详情", res);
      var data = res.data;

      this.form.id = data.id;
      this.form.category_id = data.category_id;
      this.form.title = data.title;
      this.form.desc = data.desc;
      this.form.image = data.image;
      this.form.content = data.content;
      this.editor.txt.html(data.content);
    },
    handleFormShow(e) {
      console.log("e", e);
      this.form = {
        id: null,
        category_id: null, // 分类
        title: "", // 标题
        desc: "",
        image: "", // 关联品种
        content: "", // 内容
      }; // 提交表单信息
      if (e != undefined) {
        // 修改
        this.formTitle = "修改";
        if (this.editor) {
          this.editor.txt.html("");
        }
        this.getInfoDetail(e.id);
      } else {
        // 添加
        this.formTitle = "添加";
        if (this.editor) {
          this.editor.txt.html("");
        }
      }
      this.formShow = true;
      setTimeout(() => {
        if (!this.editor) {
          this.createEditor();
        }
      }, 100);
    },
    handleDelete(index, row) {
      this.$confirm("确认删除该数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.handleDeleteApi(index, row);
      });
    },
    async handleDeleteApi(index, row) {
      const res = await Service.delHeadLine({
        id: row.id,
      });
      this.$message.success(res.msg);
      this.getHeadLineList(this.page);
    },
  },
};
</script>

<style scoped>
.body {
  height: 100vh;
}

.plate_title {
  background-color: #f8fcff;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  padding: 18px 0;
  border-top: 1px solid #ebeced;
  border-bottom: 1px solid #ebeced;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plate_title_name {
  position: relative;
  line-height: 16px;
  padding-left: 30px;
}

.plate_title_name::before {
  content: "";
  position: absolute;
  left: 13px;
  width: 4px;
  height: 16px;
  background-color: #5389f5;
  border-radius: 5px;
}

.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.el-form {
  width: 90%;
}

.icon_del {
  display: block;
  width: 26px;
  height: 26px;
  margin-left: 10px;
}

.avatar-uploader_div {
  width: 148px;
  height: 148px;
  display: block;
  position: relative;
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.zhezhao {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zhezhao:hover {
  opacity: 1;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

/deep/.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  text-align: center;
}
</style>
