<template>
  <div>
    <pageTitle :title="title"></pageTitle>
    <div class="bb p30">
      <div class="mb20">当前直播间状态：</div>
      <div class="flex at_c mb30">
        <div
          class="plate_push_btn"
          :class="{ plate_push_btn_active: live_status == 1 }"
          @click="setLiveStatus(1)"
        >
          <img
            v-if="live_status == 1"
            class="icon_push_speak"
            src="@/assets/img/icon_push_speaks.png"
            alt=""
          />
          <img
            v-else
            class="icon_push_speak"
            src="@/assets/img/icon_push_speak.png"
            alt=""
          />
          开播
        </div>
        <div
          class="plate_push_btn"
          :class="{ plate_push_btn_active: live_status == 0 }"
          @click="setLiveStatus(0)"
        >
          <img
            v-if="live_status == 0"
            class="icon_push_stop"
            src="@/assets/img/icon_push_stops.png"
            alt=""
          />
          <img
            v-else
            class="icon_push_stop"
            src="@/assets/img/icon_push_stop.png"
            alt=""
          />
          停播
        </div>
      </div>
      <div class="mb20">当前圈子直播在麦研究员：</div>
      <div class="mb30">
        <el-select
          v-model="teacher_id"
          placeholder="当前在麦"
          @change="changeTeacherId"
        >
          <el-option label="无" :value="0"></el-option>
          <el-option
            v-for="(item, index) in teacherList"
            :key="index"
            :label="item.nickname"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div>
        <el-input
          size="large"
          v-model="content"
          placeholder="请输入文案"
          class="input-with-select"
        >
          <template #prepend>
            <el-select
              v-model="title1"
              placeholder="请选择类别"
              style="width: 115px"
            >
              <el-option label="开盘提醒" value="开盘提醒" />
              <el-option label="夜盘提醒" value="夜盘提醒" />
              <el-option label="课程提醒" value="课程提醒" />
            </el-select>
          </template>
        </el-input>
        <div class="mt20">
          <el-button type="primary" @click="send">推送</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageTitle from "../components/pageTitle.vue";
import * as Service from "../api/pushMessage";
import * as ServiceI from "../api/index";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      title: "推送消息",
      content: "",
      title1: "",
      live_status: null,
      teacher_id: null, //研究员id
      teacherList: [], //研究员列表
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.getRoomInfo();
    this.getBaseInfo();
  },
  methods: {
    onmessage() {
      const redata = JSON.parse(e.data);
      // console.log("index-redata", redata);
      switch (redata.type) {
        //直播间状态变更
        case "live_status":
          this.$notify({
            title: "提示",
            message: "直播间状态已更改",
            duration: 3000,
          });
          this.getRoomInfo();
          break;
      }
    },
    //获取基础信息
    async getBaseInfo() {
      const res = await ServiceI.baseInfo();
      const { code, data } = res;
      if (code == 1) {
        this.teacherList = data.teacherList;
        // this.varietiesList = data.varietiesList;
      }
      console.log("getBaseInfo", res);
    },
    // 设置直播间在麦老师
    async changeTeacherId() {
      const res = await ServiceI.setRoomTeacher({
        teacher_id: this.teacher_id,
      });
      console.log("设置直播间在麦老师", res);
      this.$message.success(res.msg);
    },
    //更改研究员
    changeTeacher(e) {
      var teacherList = this.teacherList,
        teacher_index = null;
      for (const [index, item] of teacherList.entries()) {
        if (item.id == e) {
          teacher_index = index;
        }
      }
      this.teacher_index = teacher_index;
    },
    // 获取直播间信息
    async getRoomInfo() {
      const res = await ServiceI.getRoomInfo();
      console.log("获取直播间信息", res);
      this.live_status = res.data.roomInfo.live_status;
      this.teacher_id = res.data.roomInfo.teacher_id;
    },
    // 设置直播间状态
    setLiveStatus(live_status) {
      let that = this;
      var text = live_status == 1 ? "开播" : "停播";
      that
        .$confirm("确认" + text + "吗?", "信息", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.setLiveStatusApi(live_status);
        })
        .catch(() => {});
    },
    async setLiveStatusApi(live_status) {
      const res = await ServiceI.setLiveStatus({
        live_status,
      });
      console.log("设置直播间播放状态", res);
      this.$message.success(res.msg);
      this.getRoomInfo();
    },
    async send() {
      if (!this.title1) {
        this.$message.error("请选择类别");
        return false;
      }
      if (!this.content) {
        this.$message.error("请填写内容");
        return false;
      }
      const res = await Service.jPush({
        title: this.title1,
        content: this.content,
      });
      this.$message.success(res.msg);
    },
  },
};
</script>

<style scoped>
.plate_push_btn {
  width: 70px;
  height: 30px;
  background-color: #e2e8f3;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #a3adbf;
  border-radius: 3px;
}

.plate_push_btn_active {
  background-color: #5389f5;
  color: #fff;
}

.icon_push_video {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.icon_push_speak {
  width: 9px;
  height: 16px;
  margin-right: 5px;
}

.icon_push_stop {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
</style>
