import emojiList from "@/utils/emoji";
// 替换内容中的emoji字符
export function emojiReplace (val, isDeal) {
  for (const item of emojiList) {
    val = val.replace(
      new RegExp(item.regexp, "g"),
      '<img style="width: 18px !important;height: 18px;vertical-align: sub;" src="' +
      item.url +
      '" />'
    );
  }
  // 是否处理图片，用于聊天列表最新消息的图片展示
  if (isDeal) {
    if (val.includes("<img") && !val.includes("emoji_")) {
      val = "[图片]";
    }
  }
  // if (val.includes('<img') && !val.includes('emoji_')) {
  //   // 处理图片，用于提示可以点击
  //   val = '<div style="cursor: pointer;">' + val + '</div>'
  // }
  return val;
}

// 获取上一个工作日
export function getNowFormatDate (day) {
  let currentdate = "";
  const date = new Date();

  if (day) {
    date.setTime(date.getTime() + 24 * day * 60 * 60 * 1000);
  }
  const seperator1 = "-";
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }

  currentdate = year + seperator1 + month + seperator1 + strDate;

  if (
    new Date(currentdate).getDay() % 7 === 6 ||
    new Date(currentdate).getDay() % 7 === 0
  ) {
    const newDay = Number(day) - 1;
    return getNowFormatDate(newDay);
  } else {
    return currentdate;
  }
}

function formatNumber (n) {
  n = n.toString();
  return n[1] ? n : "0" + n;
}
/**
 * 格式化时间函数
 * @param number 时间戳（非毫秒级）
 * @param format 想要的格式，例：'YMD'
 * @returns 返回格式化完成的时间字符串
 */
export function formatTime (number, format) {
  const formateArr = ["Y", "M", "D", "h", "m", "s"];
  const returnArr = [];

  const date = new Date(number * 1000);
  returnArr.push(date.getFullYear());
  returnArr.push(formatNumber(date.getMonth() + 1));
  returnArr.push(formatNumber(date.getDate()));

  returnArr.push(formatNumber(date.getHours()));
  returnArr.push(formatNumber(date.getMinutes()));
  returnArr.push(formatNumber(date.getSeconds()));

  for (const i in returnArr) {
    format = format.replace(formateArr[i], returnArr[i]);
  }
  return format;
}

export function getWeek () {
  const weekStr = "天一二三四五六";
  return weekStr.charAt(new Date().getDay());
}
