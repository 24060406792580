<template>
  <router-view />
</template>

<script>
import socket from "./utils/socket";
import * as Service from "./api/index";
export default {
  created() {
    this.getSocketUrl();
  },
  mounted() {
    const role = localStorage.getItem("vce-token-push");
    if (role) {
      socket.initWebpack();
    }
  },
  methods: {
    async getSocketUrl() {
      const res = await Service.getSocketUrl();
      localStorage.setItem("socketUrl", res.data.socketUrl);
    },
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
</style>
