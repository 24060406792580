<template>
  <div>
    <div class="flex fw_w at_c mb10">
      <div class="flex at_c mr15 fs_0 mb10">
        <div class="mr10 fz14">时间</div>
        <div>
          <el-date-picker
            format="YYYY-MM-DD"
            value-format="x"
            v-model="time_range"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="mr10 mb10">
        <el-button icon="Search" type="primary" @click="getInfoData()"
          >搜索</el-button
        >
      </div>
    </div>

    <el-table
      stripe
      show-summary
      :summary-method="getSummaries"
      :data="list"
      style="width: 100%"
      max-height="72vh"
    >
      <el-table-column
        fixed
        prop="date"
        label="日期"
        width="80"
      ></el-table-column>
      <el-table-column
        v-for="(item, index) in users"
        :key="index"
        :prop="index"
        :label="item.qiyou_nickname + '\n(' + item.nickname + ')'"
      ></el-table-column>
    </el-table>

    <div class="mt10 flex at_c fz14 fb">
      <div class="mr30">客服中心：{{ count.service_count }}</div>
      <div>投研中心：{{ count.teacher_count }}</div>
    </div>
  </div>
</template>

<script>
import * as Service from "@/api/qiyouCommunity.js";
export default {
  data() {
    return {
      time_range: [], // 发布时间范围
      page: 1,
      list: [],
      users: [],
      total: null,
      count: {},
    };
  },
  mounted() {},
  methods: {
    async getInfoData() {
      var time_range0 = this.time_range ? [...this.time_range] : [];
      if (time_range0.length) {
        time_range0[0] = time_range0[0] / 1000;
        time_range0[1] = time_range0[1] / 1000;
      }
      const res = await Service.getInfoData({
        time_range: time_range0,
      });
      this.count = res.data.count;
      this.list = res.data.list;
      this.users = res.data.users;
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += " 次";
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
  },
};
</script>
