import request from "../utils/request";
//上传base64
export const uploadBase64 = (query) => {
  return request({
    url: "/teacher/common/uploadBase64",
    method: "POST",
    data: query,
  });
};
// 获取基础信息
export const getBaseInfo = (query) => {
  return request({
    url: "/teacher/technology_case/getBaseInfo",
    method: "POST",
    data: query,
  });
};
// 添加技术案例
export const addTechnologyCase = (query) => {
  return request({
    url: "/teacher/technology_case/addTechnologyCase",
    method: "POST",
    data: query,
  });
};
// 技术案例列表
export const getTechnologyCaseList = (query) => {
  return request({
    url: "/teacher/technology_case/technologyCaseList",
    method: "POST",
    data: query,
  });
};
