<template>
  <div>
    <div class="flex at_c">
      <div class="flex at_c mr15">
        <div class="mr10 fz14 fs_0">手机号</div>
        <el-input
          clearable
          v-model="search"
          placeholder="请输入手机号"
        ></el-input>
      </div>
      <div class="mr10">
        <el-button icon="Search" type="primary" @click="getPlatformAccount(1)"
          >搜索</el-button
        >
      </div>
      <div class="mr10">
        <el-button type="primary" @click="modifyFormShow = true"
          >添加</el-button
        >
      </div>
    </div>

    <el-table :data="list" style="width: 100%" height="70vh">
      <el-table-column prop="id" label="ID" width="100"></el-table-column>
      <el-table-column
        prop="nickname"
        label="昵称"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="mobile"
        label="手机号"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" width="180">
        <template v-slot="scope">
          <!-- <el-button
            @click="handleShowEdit(scope.$index, scope.row)"
            type="primary"
            size="small"
            icon="Edit"
            >编辑</el-button
          > -->
          <el-button
            @click="handleDelete(scope.$index, scope.row)"
            type="danger"
            size="small"
            icon="Delete"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="mt20">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 添加&编辑 -->
    <el-dialog
      width="500px"
      v-model="modifyFormShow"
      @close="closeForm"
      :title="modifyForm.id ? '编辑' : '添加'"
    >
      <el-form :model="modifyForm" ref="modifyForm">
        <div>
          <div class="form_cell">
            <el-form-item
              size="large"
              label="手机号"
              :label-width="formLabelWidth1"
            >
              <el-input v-model="modifyForm.mobile" autocomplete="off" />
            </el-form-item>
          </div>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="modifyFormShow = false">取消</el-button>
          <el-button type="primary" @click="submitForm">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "@/api/qiyouCommunity.js";
export default {
  data() {
    return {
      list: [],
      page: 1,
      search: "",
      formLabelWidth1: "100px",
      modifyFormShow: false,
      modifyForm: {
        mobile: "",
      },
    };
  },
  mounted() {},
  methods: {
    async getPlatformAccount(page) {
      const res = await Service.getPlatformAccount({
        page,
        search: this.search,
      });
      console.log("list", res);
      this.list = res.data.data;
      this.page = page;
      this.total = res.data.total;
    },
    handleCurrentChange(val) {
      this.getPlatformAccount(val);
      console.log(`当前页: ${val}`);
    },
    handleDelete(index, row) {
      this.$confirm("确认删除该数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.handleDeleteApi(index, row);
      });
      // console.log(index, row);
    },
    async handleDeleteApi(index, row) {
      const res = await Service.delPlatformAccount({
        id: row.id,
      });
      this.$message.success(res.msg);
      this.getPlatformAccount(this.page);
    },
    closeForm() {
      this.modifyForm = {
        id: null,
        image: "",
        name: "",
        is_top: 0,
        tag: null,
        weigh: null,
      };
    },
    async submitForm() {
      var modifyForm = this.modifyForm;
      let res = null;
      if (!modifyForm.mobile) {
        this.$message.error("手机号不可为空");
        return false;
      }
      // if (modifyForm.id) {
      //   res = await Service.editTopic(modifyForm);
      // } else {
      res = await Service.addPlatformAccount(modifyForm);
      // }
      this.$message.success(res.msg);
      this.modifyFormShow = false;
      this.getPlatformAccount(1);
      this.modifyForm = {
        mobile: "",
      };
    },
  },
};
</script>
