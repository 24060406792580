import request from "@/utils/request";
// 获取各个合约的涨跌幅及最新价
export const getCategoryDataList = (query) => {
  return request({
    url: "/javactp/categoryDataList",
    method: "get",
    params: query,
  });
};
// 发布V观点海报
export const addViewpointPoster = (query) => {
  return request({
    url: "/teacher/view_point_teacher/addViewpointPoster",
    method: "POST",
    data: query,
  });
};
//上传base64
export const uploadBase64 = (query) => {
  return request({
    url: "/teacher/common/uploadBase64",
    method: "POST",
    data: query,
  });
};
