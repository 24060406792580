<template>
  <div>
    <pageTitle :title="title"></pageTitle>
    <div class="bb p30">
      <div class="head flex fw_w">
        <!-- <div>
          <div>关键词</div>
          <div class="w300">
            <el-input
              clearable
              size="large"
              v-model="search"
              placeholder="请输入昵称/手机号/真实姓名"
            ></el-input>
          </div>
        </div>
        <div>
          <div>登入时间</div>
          <div>
            <el-date-picker
              format="YYYY-MM-DD"
              value-format="x"
              size="large"
              v-model="time_range"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div>
          <div>所属</div>
          <div>
            <el-radio-group v-model="belong" size="large">
              <el-radio-button label="全部" />
              <el-radio-button label="自己客户" />
              <el-radio-button label="他人客户" />
            </el-radio-group>
          </div>
        </div>
        <div>
          <div>是否实盘</div>
          <div>
            <el-select
              v-model="is_firm_offer"
              class="m-2"
              placeholder=""
              size="large"
            >
              <el-option label="全部" :value="0" />
              <el-option label="实盘" :value="1" />
              <el-option label="非实盘" :value="2" />
            </el-select>
          </div>
        </div>
        <div>
          <div>排序（最后圈子登入时间）</div>
          <div>
            <el-radio-group v-model="order" size="large">
              <el-radio-button label="降序" />
              <el-radio-button label="升序" />
            </el-radio-group>
          </div>
        </div> -->
        <div>
          <el-button @click="toModify" type="primary">添加</el-button>
        </div>
      </div>
      <div v-loading="loading">
        <div class="mb20">
          <el-table :data="list" height="60vh" style="width: 100%">
            <el-table-column label="ID" width="120" prop="id">
            </el-table-column>
            <el-table-column
              label="创建时间"
              width="240"
              prop="createtime_text"
            >
            </el-table-column>
            <el-table-column label="图片" width="240">
              <template #default="scope">
                <!-- <el-image
                  style="width: 100px; height: 167px"
                  :src="scope.row.image"
                  :preview-src-list="[scope.row.image]"
                ></el-image> -->
                <el-image
                  @click="viewImg(scope.row.image)"
                  style="width: 100px; height: 167px"
                  :src="scope.row.image"
                ></el-image>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            v-model:page-size="pageSize"
            layout="total, prev, pager, next, sizes, jumper"
            :total="total"
            :page-sizes="[15, 50, 100, 200]"
            @size-change="handleSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageTitle from "../components/pageTitle.vue";
import * as Service from "../api/technicalModel";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      title: "技术案例模型",
      loading: false,
      list: [],
      pageSize: 15,
      page: 1,
      total: 0,
    };
  },
  mounted() {
    this.getTechnologyCaseList();
  },
  methods: {
    onmessage(e) {
      // console.log('myclient-onmessage',JSON.parse(e.data))
    },
    viewImg(e) {
      window.open(e);
    },
    toModify() {
      this.$router.push("/technicalModelModify");
    },
    async getTechnologyCaseList(page) {
      this.loading = true;
      const res = await Service.getTechnologyCaseList({
        page,
        limit: this.pageSize,
      });
      this.page = page;
      this.total = res.data.total;
      var list = res.data.data;
      this.list = list;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding: 15px 30px 0;
}

.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.teacher_remark {
  max-width: 300px;
  display: inherit !important;
}
</style>
