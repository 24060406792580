<template>
  <div class="body">
    <div class="plate_title">
      <div class="plate_title_name">黑色系研报</div>
    </div>
    <div class="flex" style="height: calc(100% - 54px)">
      <div class="left_cell">
        <div class="flex at_c mt20 w100">
          <div class="mr20">日期</div>
          <div>
            <el-date-picker
              v-model="date_time"
              type="date"
              placeholder="选择日期时间"
              value-format="MM月DD日"
            />
          </div>
        </div>
        <div class="jicha_tabel mt20">
          <div class="jicha_tabel_head">
            <div class="w1 tac">期货品种</div>
            <div class="w2 tac">期货研报</div>
            <div class="w3 tac">观点逻辑</div>
          </div>
          <div class="jicha_tabel_body">
            <div
              v-for="(item, index) in form"
              :key="index"
              class="jicha_tabel_tr"
            >
              <div class="w1 tac pr">
                <div class="btn_delete_category">
                  <el-button @click="reduceFormCategory(index)" type="danger"
                    >删除品种</el-button
                  >
                </div>
                <el-select
                  filterable
                  v-model="form[index].name"
                  placeholder="请选择品种"
                  @change="changeCategory"
                >
                  <el-option
                    v-for="(item, index) in categoryList"
                    :key="index"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </div>
              <div class="tabel_td_child">
                <div
                  class="tabel_td_child_div"
                  v-for="(items, indexs) in form[index].companyInfo"
                  :key="indexs"
                >
                  <div class="w2 tac">
                    <el-select
                      filterable
                      v-model="form[index].companyInfo[indexs].name"
                      placeholder="请选择期货研报"
                      @change="changeCategory"
                    >
                      <el-option
                        v-for="(item, index) in companyList"
                        :key="index"
                        :label="item.name"
                        :value="item.name"
                      ></el-option>
                    </el-select>
                  </div>
                  <div class="w3 tac pr">
                    <el-input
                      v-model="form[index].companyInfo[indexs].desc"
                      placeholder="请输入观点逻辑"
                      maxlength="17"
                    ></el-input>
                    <div class="btn_delete_company">
                      <el-button @click="addFormCompany(index)" type="primary"
                        >增加</el-button
                      >
                      <el-button
                        v-if="indexs"
                        @click="reduceFormCompany(index, indexs)"
                        type="danger"
                        >删除</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt10">
          <el-button @click="addFormCategory" type="primary"
            >增加品种</el-button
          >
          <el-button @click="save" type="success">暂存</el-button>
          <el-button
            :loading="loadingBtn"
            @click="doCut('capture1')"
            type="primary"
            >发布</el-button
          >
        </div>

        <!-- <img class="w100" :src="ceshiUrl" alt="" /> -->
      </div>
      <div class="right_cell">
        <!-- <el-button
          :loading="loading1"
          class="mt10 mb10"
          @click="doCut1"
          type="primary"
          >生成基差一览</el-button
        > -->
        <div class="poster1 pr flex fd_c at_c" id="capture1">
          <div class="pr">
            <img
              class="w100 flex"
              src="@/assets/img/yanbao_heisexi_head.png"
              alt=""
            />
            <div class="time">{{ date_time }} {{ time_text }}更新</div>
          </div>
          <div class="jicha_tabel">
            <div class="jicha_tabel_head">
              <div class="w1 tac">期货品种</div>
              <div class="w2 tac">期货研报</div>
              <div class="w3 tac">观点逻辑</div>
            </div>
            <div class="jicha_tabel_body">
              <div
                v-for="(item, index) in form"
                :key="index"
                class="jicha_tabel_tr"
              >
                <div class="w1 tac">{{ item.name }}</div>
                <div class="tabel_td_child">
                  <div
                    class="tabel_td_child_div"
                    v-for="(items, indexs) in form[index].companyInfo"
                    :key="indexs"
                  >
                    <div class="w2 tac">{{ items.name }}</div>
                    <div class="w3 tac">{{ items.desc }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            class="w100 flex"
            src="@/assets/img/yanbao_heisexi_foot.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as ServiceB from "@/api/bbas";
import * as ServiceV from "@/api/viewingData";
import companyList from "@/utils/futuresCompanies";
import html2canvas from "html2canvas";
export default {
  components: {
    html2canvas,
  },
  data() {
    return {
      categoryList: [], // 品种列表
      year: "",
      date_time: "",
      time_text: "",
      form: [
        {
          name: "",
          companyInfo: [
            {
              name: "",
              desc: "",
            },
          ],
        },
      ],
      companyList: [],
      loadingBtn: false,

      // ceshiUrl: "",
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.getBaseInfo2();
    if (localStorage.getItem("black_year")) {
      this.year = localStorage.getItem("black_year");
    }
    if (localStorage.getItem("black_date")) {
      this.date_time = localStorage.getItem("black_date");
    }
    if (localStorage.getItem("black_time")) {
      this.time_text = localStorage.getItem("black_time");
    }

    if (
      !localStorage.getItem("black_year") ||
      !localStorage.getItem("black_date") ||
      !localStorage.getItem("black_time")
    ) {
      this.showRealTime();
    }

    this.companyList = companyList;
    if (localStorage.getItem("black_form")) {
      this.form = JSON.parse(localStorage.getItem("black_form"));
    }
  },
  methods: {
    onmessage() {},
    // 获取基础信息
    async getBaseInfo2() {
      const res = await ServiceB.BaseInfo();
      this.categoryList = res.data.categoryList;
    },
    addFormCategory() {
      this.form.push({
        name: "",
        companyInfo: [
          {
            name: "",
            desc: "",
          },
        ],
      });
    },
    reduceFormCategory(index) {
      this.form.splice(index, 1);
    },
    addFormCompany(index) {
      this.form[index].companyInfo.push({
        name: "",
        desc: "",
      });
    },
    reduceFormCompany(index, indexs) {
      this.form[index].companyInfo.splice(indexs, 1);
    },
    save() {
      localStorage.setItem("black_year", this.year);
      localStorage.setItem("black_date", this.date_time);
      localStorage.setItem("black_time", this.time_text);
      localStorage.setItem("black_form", JSON.stringify(this.form));
      this.$message.success("暂存成功");
    },
    doCut(id) {
      const that = this;
      var form = that.form;
      var val1 = 0;
      var val2 = 0;
      var val3 = 0;
      for (const item of form) {
        if (!item.name) {
          val1++;
        }
        for (const items of item.companyInfo) {
          if (!items.name) {
            val2++;
          }
          if (!items.desc) {
            val3++;
          }
        }
      }
      if (val1) {
        this.$message.error("期货品种不可为空，请检查");
        return false;
      }
      if (val2) {
        this.$message.error("期货研报不可为空，请检查");
        return false;
      }
      if (val3) {
        this.$message.error("观点逻辑不可为空，请检查");
        return false;
      }
      this.loadingBtn = true;
      setTimeout(() => {
        this.loadingBtn = false;
      }, 3000);
      html2canvas(document.querySelector("#" + id), {
        scale: 2, // 或者更高的值，根据你的屏幕和需求来定
      }).then((canvas) => {
        const imgUrl = canvas.toDataURL("image/png");
        that.uploadBase64(imgUrl);
      });
    },
    async uploadBase64(pic) {
      const res = await ServiceV.uploadBase64({
        pic,
      });
      console.log("res.data.url", res.data.url);
      this.onSubmit(res.data.url);
    },
    async onSubmit(imgUrl) {
      const res = await ServiceB.addViewpointPoster({
        images: imgUrl,
        type: 44,
        title:
          "截至" +
          this.year +
          this.date_time +
          this.time_text +
          "，黑色系期货研报观点汇总",
      });
      localStorage.removeItem("black_year");
      localStorage.removeItem("black_date");
      localStorage.removeItem("black_form");
      localStorage.removeItem("black_time");
      this.$message.success(res.msg);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    },
    showRealTime() {
      var d = new Date();
      var year = d.getFullYear();
      var month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      var days = new Array("日", "一", "二", "三", "四", "五", "六");
      var day = d.getDay();
      var hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      var min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      var sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
      var cori_date = year + "-" + month + "-" + date;
      var cori_time = hour + ":" + min + ":" + sec;
      this.year = year + "年";
      this.date_time = month + "月" + date + "日";
      this.time_text = hour + ":" + min;
      // this.currentTime = cori_time;
      // this.currentWeek = "星期" + days[day];
    },
  },
};
</script>

<style scoped>
.body {
  height: 100vh;
  overflow-y: hidden;
}

.plate_title {
  background-color: #f8fcff;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  padding: 18px 0;
  border-top: 1px solid #ebeced;
  border-bottom: 1px solid #ebeced;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plate_title_name {
  position: relative;
  line-height: 16px;
  padding-left: 30px;
}

.plate_title_name::before {
  content: "";
  position: absolute;
  left: 13px;
  width: 4px;
  height: 16px;
  background-color: #5389f5;
  border-radius: 5px;
}

.left_cell {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 150px 0 110px;
}

.right_cell {
  position: relative;
  width: calc(50% - 60px);
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background-image: url(../assets/img/bg_img1.png); */
  background-position: 0 0;
  background-size: 750px;
  background-repeat: no-repeat;
  overflow-y: auto;
}

.btn_delete_category {
  position: absolute;
  left: -100px;
}

.btn_delete_company {
  position: absolute;
  top: 14px;
  /* right: -145px; */
  left: 310px;
  display: flex;
}

.poster1 {
  width: 650px;
  background: #1a1a1a;
}

.poster1 .time {
  width: 100%;
  bottom: 23px;
  text-align: center;
  position: absolute;
  font-weight: normal;
  font-size: 24px;
  color: #faccb1;
  line-height: 24px;
}

.poster_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 0 50px;
}

.viewingData_bg {
  position: absolute;
  width: 100%;
}

.logo_white {
  width: 143px;
  display: block;
}

.name {
  font-size: 28px;
  color: #fff;
  font-weight: bold;
}

.jicha_tabel {
  width: 602px;
  z-index: 1;
  box-sizing: border-box;
  background: #fff5ec;
  box-shadow: 0px 7 46px 1px #4b3d32;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #ffffff;
}

.jicha_tabel .w1 {
  width: 130px;
}

.jicha_tabel .w2 {
  width: 170px;
}

.jicha_tabel .w3 {
  width: 300px;
}

.jicha_tabel_title {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  font-weight: bold;
  color: #0a319a;
  box-sizing: border-box;
  border-bottom: 1px solid #ffd5c4;
  background: #f9fdff;
}

.jicha_tabel_head {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #ffd5c4;
}

.jicha_tabel_head > div {
  font-weight: normal;
  font-size: 24px;
  color: #ffffff;
  background: #ff6d40;
  height: 60px;
  line-height: 60px;
}

.jicha_tabel_head > div:first-child {
  border-radius: 10px 0 0 0;
}

.jicha_tabel_head > div:last-child {
  border-radius: 0 10px 0 0;
}

.jicha_tabel_head > div:not(:last-child) {
  box-sizing: border-box;
  border-right: 1px solid #ff6d40;
}

.jicha_tabel_body {
}

.jicha_tabel_tr {
  display: flex;
  align-items: center;
  color: #191919;
}

.jicha_tabel_tr:not(:last-child) {
  box-sizing: border-box;
  border-bottom: 1px solid #ffd5c4;
}

.jicha_tabel_tr:last-child {
  border-radius: 0 0 10px 10px;
}

.jicha_tabel_tr > div:first-child {
  border-radius: 0 0 0 10px;
}

.jicha_tabel_tr > div:last-child,
.jicha_tabel_tr > div:last-child .tabel_td_child_div:last-child {
  border-radius: 0 0 10px 0;
}

.jicha_tabel_tr > div {
  /* height: 40px; */
  line-height: 60px;
  font-size: 16px;
  font-weight: 400;
  background: #fff5ec;
}

/* .jicha_tabel_tr:hover {
  background: #4571e7;
  color: #fff !important;
} */

.tabel_td_child > div > div {
  box-sizing: border-box;
  border-left: 1px solid #ffd5c4;
  word-break: break-all;
  box-sizing: border-box;
  padding: 0 10px;
}

.tabel_td_child_div {
  display: flex;
  align-items: center;
}

.tabel_td_child_div:nth-child(even) {
  background-color: #ffe7da;
}

.tabel_td_child_div:not(:last-child) {
  box-sizing: border-box;
  border-bottom: 1px solid #ffd5c4;
}

.jicha_tabel_tr > div:not(:last-child) {
  /* box-sizing: border-box;
  border-right: 1px solid #ffd5c4; */
}

.jicha_tabel_tr .green {
  color: #2aa44e;
  font-weight: bold;
}

.jicha_tabel_tr .red {
  color: #ff0000;
  font-weight: bold;
}

.canvas_bg {
  background: url("data:image/svg+xml,%3Csvg width='150' height='150' xmlns='http://www.w3.org/2000/svg'%3E%3Ctext x='50%25' y='50%25' font-size='14' fill='%23E2E2E2'  fill-opacity='1' text-anchor='middle' dominant-baseline='middle' transform='rotate(-45, 0 100)'%3EV策财经%3C/text%3E%3C/svg%3E");
}

.rank_body {
  width: calc(100% - 98px);
  margin-top: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
}

.plate_title1 {
  font-size: 38px;
  font-weight: 400;
  color: #4571e7;
  margin-bottom: 15px;
}

.TOP_cell {
  font-size: 26px;
  font-weight: bold;
  color: #4571e7;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.img_line_left,
.img_line_right {
  width: 200px;
  height: 4px;
  display: block;
}

.img_line_left {
  margin-right: 15px;
}

.img_line_right {
  margin-left: 15px;
}

.ranking_block {
  display: flex;
  align-items: flex-end;
}

.ranking_cell {
  width: 155px;
  height: 180px;
  background: #e9efff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.ranking_cell .text1 {
  font-size: 20px;
  font-weight: bold;
  color: #4471e7;
}

.ranking_cell .text2 {
  font-size: 20px;
  font-weight: bold;
  color: #191e4e;
  margin-top: 10px;
  word-break: break-all;
  text-align: center;
}

.ranking_cell .text3 {
  width: 96px;
  height: 27px;
  background: #4471e7;
  border-radius: 17px 17px 17px 17px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}

.ranking_cell .text4 {
  font-size: 14px;
  color: #2d2d2d;
}

.ranking_cell .text4 span {
  font-size: 16px;
  font-weight: bold;
}

.ranking_cell_special {
  /* zoom: 1.2; */
  width: calc(155px * 1.2);
  height: calc(180px * 1.2);
  margin: 0 15px;
  position: relative;
}

.ranking_cell_special .text1 {
  font-size: 24px;
}

.ranking_cell_special .text2 {
  font-size: 24px;
}

.ranking_cell_special .text3 {
  font-size: 22px;
}

.ranking_cell_special .text4 {
  font-size: 16px;
}

.ranking_cell_special .text4 span {
  font-size: 20px;
}

.icon_crown {
  width: 32px;
  display: block;
  position: absolute;
  top: -26px;
}

.ranking_line {
  width: 95%;
  margin: 40px auto 0;
}

.table_head {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #a8a8a8;
}

.ranking_line .w1 {
  width: 8%;
}

.ranking_line .w2 {
  width: 27%;
}

.ranking_line .w3 {
  width: 25%;
}

.ranking_line .w4 {
  width: 20%;
}

.ranking_line .w5 {
  width: 20%;
}

.table_body {
  margin-top: 15px;
}

.table_body .w1 {
  font-size: 18px;
  font-weight: bold;
  color: #4571e7;
}

.table_body .w2 {
  font-size: 18px;
  font-weight: bold;
  color: #4d4d4d;
}

.table_body .w3 {
}

.table_body .w4 {
  font-size: 18px;
  font-weight: 400;
  color: #585858;
}

.table_body .w5 {
  font-size: 18px;
  font-weight: 400;
  color: #585858;
}

.table_tr {
  display: flex;
  padding: 18px 0;
  border-bottom: 1px solid #dedede;
}

.table_tr:hover > div {
  color: #4471e7 !important;
}

.touji_label {
  width: 96px;
  height: 27px;
  background: #4471e7;
  border-radius: 17px 17px 17px 17px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qrcode_bottom {
  width: calc(100% - 98px);
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 20px 0;
}

.qrcode_cell {
  display: flex;
  align-items: center;
}

.qrcode_cell img {
  width: 90px;
  height: 90px;
  display: block;
  margin-right: 10px;
}

.qrcode_cell div {
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
}
</style>
