import request from "../utils/request";
//获取分类列表
export const getCategoryList = (query) => {
  return request({
    url: "/teacher/head_line/getCategoryList",
    method: "POST",
    data: query,
  });
};
//添加分类
export const addCategory = (query) => {
  return request({
    url: "/teacher/head_line/addCategory",
    method: "POST",
    data: query,
  });
};
//编辑分类
export const editCategory = (query) => {
  return request({
    url: "/teacher/head_line/editCategory",
    method: "POST",
    data: query,
  });
};
//分类详情
export const categoryInfo = (query) => {
  return request({
    url: "/teacher/head_line/categoryInfo",
    method: "POST",
    data: query,
  });
};
//删除分类
export const delCategory = (query) => {
  return request({
    url: "/teacher/head_line/delCategory",
    method: "POST",
    data: query,
  });
};
//获取头条报告列表
export const getHeadLineList = (query) => {
  return request({
    url: "/teacher/head_line/getHeadLineList",
    method: "POST",
    data: query,
  });
};
//添加头条报告
export const addHeadLine = (query) => {
  return request({
    url: "/teacher/head_line/addHeadLine",
    method: "POST",
    data: query,
  });
};
//编辑头条报告
export const editHeadLine = (query) => {
  return request({
    url: "/teacher/head_line/editHeadLine",
    method: "POST",
    data: query,
  });
};
//头条报告详情
export const headLineInfo = (query) => {
  return request({
    url: "/teacher/head_line/headLineInfo",
    method: "POST",
    data: query,
  });
};
//删除头条报告
export const delHeadLine = (query) => {
  return request({
    url: "/teacher/head_line/delHeadLine",
    method: "POST",
    data: query,
  });
};
