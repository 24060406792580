import request from "../utils/request";
// 获取基础信息
export const getBaseInfo = (query) => {
  return request({
    url: "/teacher/qi_you/getBaseInfo",
    method: "POST",
    data: query,
  });
};
// 获取话题列表
export const getTopicList = (query) => {
  return request({
    url: "/teacher/qi_you/getTopicList",
    method: "POST",
    data: query,
  });
};
// 话题详情
export const getTopicInfo = (query) => {
  return request({
    url: "/teacher/qi_you/getTopicInfo",
    method: "POST",
    data: query,
  });
};
// 添加话题
export const addTopic = (query) => {
  return request({
    url: "/teacher/qi_you/addTopic",
    method: "POST",
    data: query,
  });
};
// 编辑话题
export const editTopic = (query) => {
  return request({
    url: "/teacher/qi_you/editTopic",
    method: "POST",
    data: query,
  });
};
// 删除话题
export const delTopic = (query) => {
  return request({
    url: "/teacher/qi_you/delTopic",
    method: "POST",
    data: query,
  });
};
// 获取投票列表
export const getVoteList = (query) => {
  return request({
    url: "/teacher/qi_you/getVoteList",
    method: "POST",
    data: query,
  });
};
// 投票详情
export const getVoteInfo = (query) => {
  return request({
    url: "/teacher/qi_you/voteInfo",
    method: "POST",
    data: query,
  });
};
// 添加投票
export const addVote = (query) => {
  return request({
    url: "/teacher/qi_you/addVote",
    method: "POST",
    data: query,
  });
};
// 编辑投票
export const editVote = (query) => {
  return request({
    url: "/teacher/qi_you/editVote",
    method: "POST",
    data: query,
  });
};
// 删除投票
export const delVote = (query) => {
  return request({
    url: "/teacher/qi_you/delVote",
    method: "POST",
    data: query,
  });
};
// 获取动态列表
export const getInfoList = (query) => {
  return request({
    url: "/teacher/qi_you/infoList",
    method: "POST",
    data: query,
  });
};
// 获取动态评论列表
export const getCommentList = (query) => {
  return request({
    url: "/teacher/qi_you/commentList",
    method: "POST",
    data: query,
  });
};
// 删除动态
export const delInfo = (query) => {
  return request({
    url: "/teacher/qi_you/delInfo",
    method: "POST",
    data: query,
  });
};
// 删除动态的评论
export const delComment = (query) => {
  return request({
    url: "/teacher/qi_you/delComment",
    method: "POST",
    data: query,
  });
};
// 设置热门信息
export const setHotInfo = (query) => {
  return request({
    url: "/teacher/qi_you/setHotInfo",
    method: "POST",
    data: query,
  });
};
// 用户投票列表
export const getUserVoteList = (query) => {
  return request({
    url: "/teacher/qi_you/userVoteList",
    method: "POST",
    data: query,
  });
};
// 动态数据
export const getInfoData = (query) => {
  return request({
    url: "/teacher/qi_you/infoData",
    method: "POST",
    data: query,
  });
};
// 获取数据统计
export const getStatisticsInfo = (query) => {
  return request({
    url: "/teacher/qi_you/statisticsInfo",
    method: "POST",
    data: query,
  });
};
// 添加平台账号
export const addPlatformAccount = (query) => {
  return request({
    url: "/teacher/qi_you/addPlatformAccount",
    method: "POST",
    data: query,
  });
};
// 获取平台账号列表
export const getPlatformAccount = (query) => {
  return request({
    url: "/teacher/qi_you/getPlatformAccount",
    method: "POST",
    data: query,
  });
};
// 删除平台账号
export const delPlatformAccount = (query) => {
  return request({
    url: "/teacher/qi_you/delPlatformAccount",
    method: "POST",
    data: query,
  });
};
