<template>
  <div>
    <div class="flex fw_w at_c mb10">
      <div class="flex at_c mr15 fs_0 mb10">
        <div class="mr10 fz14">发布时间</div>
        <div>
          <el-date-picker
            format="YYYY-MM-DD"
            value-format="x"
            v-model="time_range"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="flex at_c mr15 fs_0 mb10">
        <div class="mr10 fz14">关键词</div>
        <el-input
          style="width: 200px"
          class="m-2"
          v-model="search"
          clearable
          placeholder="请输入关键词"
        ></el-input>
      </div>
      <div class="flex at_c mr15 fs_0 mb10">
        <div class="mr10 fz14">用户昵称</div>
        <el-input
          style="width: 200px"
          class="m-2"
          v-model="nickname"
          clearable
          placeholder="请输入用户昵称"
        ></el-input>
      </div>
      <div class="flex at_c mr15 fs_0 mb10">
        <div class="mr10 fz14">类型</div>
        <el-select v-model="is_hot" placeholder="请选择类型">
          <el-option label="全部" :value="0"> </el-option>
          <el-option label="热门" :value="1"> </el-option>
        </el-select>
      </div>
      <div class="mr10 mb10">
        <el-button icon="Search" type="primary" @click="getSpeechList(1)"
          >搜索</el-button
        >
      </div>
    </div>

    <div class="speech_body overflow_y">
      <div id="target"></div>
      <div class="speech_cell" v-for="(item, index) in list" :key="index">
        <img
          v-if="item.is_hot"
          class="icon_hot"
          src="@/assets/img/qiyouCommunity/icon_hot.png"
          alt=""
        />
        <div class="btn1 btn_handle" @click.stop="delInfo(item.id)">删除</div>
        <div
          v-if="item.is_hot == 0"
          class="btn2 btn_handle"
          @click.stop="setHotInfo(item.id, 1)"
        >
          设为热门
        </div>
        <div
          v-if="item.is_hot == 1"
          class="btn3 btn_handle"
          @click.stop="setHotInfo(item.id, 0)"
        >
          取消热门
        </div>
        <img class="avatar1" :src="item.user.avatar" alt="" />
        <div class="nickname1 hang1">
          {{ item.user.nickname }}
        </div>
        <div class="time1">
          {{ item.createtime_text }} 粉丝：{{ item.user.fans_num }}
        </div>

        <div
          class="content1"
          @click.stop="jumpToTopicDetails"
          v-html="item.content"
        />
        <!-- 图片 -->
        <div class="album_imgs" v-if="item.images.length">
          <template v-if="item.images.length == 1">
            <el-image
              v-for="(items, indexs) in item.images"
              :key="indexs"
              class="album_img1"
              :src="items"
              :preview-src-list="item.images"
              :initial-index="indexs"
              fit="cover"
            />
          </template>
          <template v-if="item.images.length == 2 || item.images.length == 4">
            <el-image
              v-for="(items, indexs) in item.images"
              :key="indexs"
              class="album_img2"
              :src="items"
              :preview-src-list="item.images"
              :initial-index="indexs"
              fit="cover"
            />
          </template>
          <template v-if="item.images.length == 3 || item.images.length > 4">
            <el-image
              v-for="(items, indexs) in item.images"
              :key="indexs"
              class="album_img3"
              :src="items"
              :preview-src-list="item.images"
              :initial-index="indexs"
              fit="cover"
            />
          </template>
        </div>
        <div class="flex at_c">
          <div
            class="speech_bottom_cell"
            :class="{ active_text: item.is_accept }"
            @click.stop="zanHandle(item.id, item.is_accept, index)"
          >
            <img
              v-if="!item.is_accept"
              class="icon_zan"
              src="@/assets/img/qiyouCommunity/icon_zan.png"
              alt=""
            />
            <img
              v-else
              class="icon_zan"
              src="@/assets/img/qiyouCommunity/icon_zan_active.png"
              alt=""
            />
            {{ item.accept_num }}
          </div>
          <div
            class="speech_bottom_cell can"
            @click.stop="changeCommentStatus(item.id, index)"
          >
            <img
              class="icon_message"
              src="@/assets/img/qiyouCommunity/icon_message.png"
              alt=""
            />
            {{ item.comment_num }}
          </div>
        </div>
        <!-- 评论列表 -->
        <template v-if="item.showCommentList">
          <div v-if="item.commentList" class="comment_list">
            <div
              v-for="(items, indexs) in item.commentList"
              :key="indexs"
              class="comment_cell"
            >
              <img class="avatar2" :src="items.user.avatar" alt="" />
              <div class="info">
                <span class="name">{{ items.user.nickname }}</span>
                <span class="content" v-html="': ' + items.content" />
              </div>
              <div class="time mt5">
                {{ items.createtime }}
                <span
                  class="can ml10"
                  style="color: #ff0000"
                  @click="delComment(items.id, item.id, index)"
                  >删除</span
                >
              </div>
              <div class="handle_cell">
                <div
                  :class="{ active_text: items.is_accept }"
                  @click.stop="
                    zanCommentHandle(items.id, items.is_accept, indexs)
                  "
                >
                  <img
                    v-if="!items.is_accept"
                    class="icon_zan"
                    src="@/assets/img/qiyouCommunity/icon_zan.png"
                    alt=""
                  />
                  <img
                    v-else
                    class="icon_zan"
                    src="@/assets/img/qiyouCommunity/icon_zan_active.png"
                    alt=""
                  />
                  {{ items.accept_num }}
                </div>
                <div
                  @click.stop="showInfoComment(items.user.nickname, items.id)"
                >
                  <img
                    class="icon_message"
                    src="@/assets/img/qiyouCommunity/icon_message.png"
                    alt=""
                  />
                  {{ items.son_count }}
                </div>
              </div>
              <!-- 评论的评论列表 -->
              <div class="comment_cell_cells">
                <div
                  v-for="(itemss, indexss) in items.son_comment"
                  :key="indexss"
                >
                  <div>
                    <span class="name">{{ itemss.user.nickname }}</span>
                    <span class="content" v-html="': ' + itemss.content" />
                  </div>
                  <div class="time mt5">
                    {{ itemss.createtime }}
                    <span
                      class="can ml10"
                      style="color: #ff0000"
                      @click="delComment(itemss.id, item.id, index)"
                      >删除</span
                    >
                  </div>
                </div>
                <div
                  v-if="items.son_count > items.son_comment.length"
                  class="see_more2"
                  @click="getCommentList(item.id, index, items.id, indexs)"
                >
                  查看更多评论
                  <i class="el-icon-arrow-down" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="mt20">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="15"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as Service from "@/api/qiyouCommunity.js";
import { emojiReplace } from "@/utils/common";
export default {
  data() {
    return {
      search: "",
      page: 1,
      list: [],
      total: null,
      nickname: "",
      is_hot: null,
      time_range: [], // 发布时间范围
    };
  },
  mounted() {},
  methods: {
    async getSpeechList(page) {
      var time_range0 = this.time_range ? [...this.time_range] : [];
      if (time_range0.length) {
        time_range0[0] = time_range0[0] / 1000;
        time_range0[1] = time_range0[1] / 1000;
      }

      const reg = /#([^#|.]+)#/g;
      const res = await Service.getInfoList({
        page,
        search: this.search,
        nickname: this.nickname,
        is_hot: this.is_hot,
        time_range: time_range0,
      });
      this.page = page;
      const list = res.data.data;
      for (const item of list) {
        item.showCommentList = false;
        item.commentList = [];
        item.content = emojiReplace(item.content).replace(/\n/g, "<br/>");
        // 剥离出内容中的话题
        const topicArr = item.content.match(reg);
        if (topicArr) {
          for (const items of topicArr) {
            // eslint-disable-next-line prefer-regex-literals
            item.content = item.content.replace(
              new RegExp(items, "gm"),
              '<a class="can" data-name="' +
                items.replace(new RegExp("#", "g"), "") +
                '" style="color: #4571e7;">' +
                items +
                "</a>"
            );
          }
        }
      }
      this.list = res.data.data;
      this.total = res.data.total;
      this.returnTop("target");
    },
    returnTop(id) {
      document.querySelector("#" + id).scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start", // 上边框与视窗顶部平齐
      });
    },
    handleCurrentChange(val) {
      this.getSpeechList(val);
      console.log(`当前页: ${val}`);
    },
    changeCommentStatus(id, index) {
      this.list[index].showCommentList = true;
      this.getCommentList(id, index, 0);
    },
    // 获取动态评论
    async getCommentList(id, index, pid, indexs) {
      const res = await Service.getCommentList({
        page: 1,
        limit: 99,
        id,
        pid,
      });
      const commentList = res.data.list.data;
      if (pid) {
        // 查询评论的评论
        for (const item of commentList) {
          item.content = emojiReplace(item.content).replace(/\n/g, "<br/>");
        }
        this.list[index].commentList[indexs].son_comment = commentList;
      } else {
        // 查询评论
        for (const item of commentList) {
          item.content = emojiReplace(item.content).replace(/\n/g, "<br/>");
          for (const items of item.son_comment) {
            items.content = emojiReplace(items.content).replace(/\n/g, "<br/>");
          }
        }
        this.list[index].commentList = commentList;
      }
    },
    delInfo(id) {
      this.$confirm("确认删除该动态吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delInfoApi(id);
        })
        .catch(() => {});
    },
    // 删除动态
    async delInfoApi(id) {
      const res = await Service.delInfo({
        id,
      });
      this.$message.success(res.msg);
      this.getSpeechList(this.page);
    },
    delComment(id, speechId, index) {
      this.$confirm("确认删除该评论吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delCommentApi(id, speechId, index);
        })
        .catch(() => {});
    },
    // 删除动态的评论
    async delCommentApi(id, speechId, index) {
      const res = await Service.delComment({
        id,
      });
      this.$message.success(res.msg);
      this.getCommentList(speechId, index);
    },
    // 设置&取消热门
    async setHotInfo(id, is_hot) {
      const res = await Service.setHotInfo({
        id,
        is_hot,
      });
      this.$message.success(res.msg);
      this.getSpeechList(this.page);
    },
  },
};
</script>

<style scoped>
.speech_body {
  height: calc(100vh - 300px);
  overflow-y: auto;
  padding-top: 10px;
}

.speech_cell {
  margin-left: 10px;
  width: 860px;
  box-sizing: border-box;
  padding: 15px 25px 20px 80px;
  background: #ffffff;
  border-radius: 6px;
  position: relative;
  margin-bottom: 10px;
  border: 1px solid #e4e7ed;
}

.icon_hot {
  position: absolute;
  left: -8px;
  top: -4px;
  width: 26px;
  height: 26px;
  display: block;
}

.avatar1 {
  width: 42px;
  height: 42px;
  object-fit: cover;
  display: block;
  border-radius: 50%;
  margin-right: 10px;
  position: absolute;
  left: 25px;
}

.nickname1 {
  font-size: 16px;
  font-weight: bold;
  color: #4a4a4a;
  max-width: 220px;
  margin-bottom: 5px;
}

.time1 {
  font-size: 13px;
  font-weight: 400;
  color: #c4c4c4;
  margin-bottom: 5px;
}

.topic_title {
  font-size: 14px;
  font-weight: 400;
  color: #4571e7;
  margin-bottom: 5px;
}

.content1 {
  max-width: calc(100% - 120px);
  font-size: 14px;
  font-weight: 400;
  color: #4a4a4a;
  word-break: break-all;
}

.album_imgs {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  margin-top: 10px;
}

.album_img1 {
  width: 400px;
  height: 400px;
  border-radius: 3px;
  display: block;
  object-fit: cover;
  margin-bottom: 5px;
}

.album_img2 {
  width: 197.5px;
  height: 197.5px;
  border-radius: 3px;
  display: block;
  object-fit: cover;
  margin-bottom: 5px;
}

.album_img2:not(:nth-child(2n)) {
  margin-right: 5px;
}

.album_img3 {
  width: 130px;
  height: 130px;
  border-radius: 3px;
  display: block;
  object-fit: cover;
  margin-bottom: 5px;
}

.album_img3:not(:nth-child(3n)) {
  margin-right: 5px;
}

.icon_zan,
.icon_message {
  width: 20px;
  height: 20px;
  display: block;
  margin-right: 5px;
}

.btn1,
.btn2,
.btn3 {
  width: 80px;
  height: 28px;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box;
  cursor: pointer;
}

.btn1 {
  color: #ffffff;
  background-color: #e74545;
  top: 20px;
}

.btn2 {
  color: #ffffff;
  background-color: orange;
  top: 60px;
}

.btn3 {
  color: orange;
  background-color: #fff;
  border: 1px solid orange;
  top: 60px;
}

.btn_handle {
  position: absolute;
  right: 25px;
}

.speech_bottom_cell {
  width: 110px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #b1bad2;
  margin-top: 10px;
}

.active_text {
  color: #4571e7;
}

.comment_list {
  margin-top: 10px;
}

.comment_list .comment_cell {
  box-sizing: border-box;
  padding: 0 25px 0 50px;
  position: relative;
  margin-bottom: 20px;
}

.avatar2 {
  width: 40px;
  height: 40px;
  object-fit: cover;
  display: block;
  border-radius: 50%;
  margin-right: 10px;
  position: absolute;
  left: 0;
}

.comment_list .info {
  width: 400px;
}

.comment_list .name {
  font-size: 16px;
  font-weight: 400;
  color: #4571e7;
}

.comment_list .content {
  font-size: 16px;
  font-weight: 400;
  color: #292929;
  word-break: break-all;
}

.comment_list .time {
  font-size: 13px;
  font-weight: 400;
  color: #c4c4c4;
}

.comment_list .handle_cell {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #b1bad2;
}

.comment_list .handle_cell > div {
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.comment_cell_cells {
  margin-top: 10px;
}

.comment_cell_cells > div {
  max-width: 350px;
  box-sizing: border-box;
  padding: 0 0 0 20px;
  margin-bottom: 10px;
  border-left: 1px solid #dedede;
}

.see_more2 {
  font-size: 14px;
  font-weight: 400;
  color: #4571e7;
  border-left: 0 !important;
  cursor: pointer;
}
</style>
